import React from 'react'

export const Other = (props: { className?: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g id="Icon-/-Outline-/-Other" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        id="Shape"
        fill="#288BA0"
        points="20.286 29.403 20.286 31.203 18.486 31.203 18.486 29.403"
      ></polygon>
      <path
        d="M27.3063749,15.6131032 L27.308936,15.3542077 L27.3133717,15.2940947 C27.3297769,15.0219541 27.3183655,14.668933 27.2571063,14.2676702 C27.1677681,13.6824832 26.9868143,13.1087439 26.6949009,12.5757537 C25.7308707,10.8155784 23.7361352,9.747 20.2966,9.747 C16.8352632,9.747 14.7569854,10.7313182 13.6907159,12.340202 C13.1451161,13.1634522 12.9423349,14.0083349 12.9310586,14.6542884 L12.9322763,14.8169713 L11.5349237,14.9030287 L11.53286,14.85635 C11.5232145,14.5553844 11.551743,14.1516166 11.6489328,13.6792682 C11.7978076,12.955728 12.0785792,12.238488 12.5237341,11.566798 C13.8652771,9.54255683 16.3895618,8.347 20.2966,8.347 C24.2263148,8.347 26.7012043,9.67279655 27.9227991,11.9032463 C28.5326295,13.0167057 28.7409033,14.1593285 28.7205785,15.1373677 L28.705,15.45 L28.7061728,15.5791994 C28.7024154,15.7909696 28.6769932,16.0439477 28.6197976,16.328418 L28.5707854,16.5475236 C28.4016904,17.2348052 28.08547,17.9147583 27.5883877,18.5473327 C26.849268,19.4879178 25.771865,20.2430971 24.3215089,20.7544859 L23.5492611,21.0090956 C20.6333964,21.993092 20.132468,22.860435 20.0915328,26.0497687 L20.0886,26.603 L18.6886,26.603 L18.6928295,26.0100549 C18.7580481,22.1070657 19.6111558,20.7836596 23.582253,19.5256677 C24.9312411,19.0984374 25.8734962,18.4638018 26.4875923,17.6823185 C27.0749771,16.9348273 27.2918553,16.1794798 27.3063749,15.6131032 Z"
        id="Shape"
        fill="#fff"
      ></path>
    </g>
  </svg>
)
