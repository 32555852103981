// .core
import React, { useMemo } from 'react'
// assets
import { Other } from 'assets/images/icons/Other'
// content
import { CASES } from 'content/Cases/Cases'
// libs
import cx from 'classnames'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import css from './ServiceSelect.module.scss'
// translate
import { translate } from 'i18n'

interface IServiceSelectProps {
  selected: (string | undefined)[]
  /**
   * Fires on selection change.
   * @param selected The name of the selected service.
   */
  onChange?: (selected: string) => void
}

export const ServiceSelect = ({ selected: selectedValues, onChange }: IServiceSelectProps) => {
  const services = useMemo(
    () => [
      ...CASES.filter((caseItem) => caseItem.formTitle).map((caseItem) => ({
        icon: caseItem.icon || '',
        name: caseItem.formTitle,
      })),
      {
        displayInContactForm: true,
        icon: Other,
        name: translate.i18n.OTHER_SOLUTIONS,
      },
    ],
    []
  )

  return (
    <span className={css.serviceSelect}>
      <span className={css.title}>
        {translate.i18n.SOLUTIONS}
        {selectedValues.length ? (
          ` (${selectedValues.length})`
        ) : (
          <i className="ml-1">({translate.i18n.SOLUTIONS_CLICK_TO_SELECT})</i>
        )}
      </span>
      <span className={css.services}>
        {services.map((service) => (
          <span
            key={service.name}
            className={cx(css.service, selectedValues.includes(service.name) && css.active)}
            onClick={() => onChange?.(service.name)}
          >
            <service.icon className={css.icon} />
            <span className={css.name}>{service.name}</span>
          </span>
        ))}
      </span>
    </span>
  )
}
