// .core
import React from 'react'
// contents
import { generateCounters, ICounter } from 'content/AboutUs/AboutUs'
// components
import Button from 'components/Button/Button'
import { Logo } from './Logo'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { IButtonProps } from 'components/Button/Button'
// libs
import CountUp from 'react-countup'
import cx from 'classnames'
// styles
import css from './AboutUs.module.scss'
// translate
import { translate } from 'i18n'

export interface IAboutUsProps {
  /**
   * Whether it is visible or not (can be observed by callbacks of other components).
   */
  animateFadeIn: boolean
  /**
   * Whether to show hide animation or not.
   */
  animateFadeOut?: boolean
  /**
   * ClassName for custom styling of the wrapper.
   */
  className?: string
  /**
   * Footer button props.
   */
  button?: IButtonProps
  /**
   * Hides the "show more" button.
   */
  hideButton?: boolean
  /**
   * Custom threshold for onUnload callback.
   * NOTE: default is 10.
   */
  onUnloadThreshold?: number
  /**
   * Whether the countes section is visible
   * @param visible
   * @returns
   */
  onSetVisibleCounters?: (visible: boolean) => void
  /**
   * Callback for onLoad (by default when the scrollbar reaches 90% or more).
   */
  onLoad?: () => void
  /**
   * Callback for the onUnload (by default when the scrollbar reaches 10% or less).
   */
  onUnload?: () => void
}

interface IAnimation {
  counters?: boolean
  companyStats?: boolean
}

export function AboutUs({
  animateFadeIn,
  animateFadeOut,
  className,
  hideButton,
  onUnloadThreshold,
  onLoad,
  onUnload,
  button,
  onSetVisibleCounters,
}: IAboutUsProps) {
  const [animate, __setAnimate] = React.useState<IAnimation>({
    counters: undefined,
    companyStats: undefined,
  })

  const setAnimate = (key: keyof IAnimation, animate?: boolean) => {
    if (key === 'counters' && onSetVisibleCounters) {
      onSetVisibleCounters(!!animate)
    }

    __setAnimate((p) => ({ ...p, [key]: !!animate }))
  }

  return (
    <div
      className={cx(
        css.aboutUs,
        className,
        animateFadeIn && css.visible,
        animateFadeOut && css.hidden,
        hideButton && css.customAnimations
      )}
    >
      {hideButton ? <Logo /> : <h2 className={css.title}>{translate.i18n.ABOUT_US}</h2>}
      {translate.i18n.OUR_DESCRIPTION.map((par, parIndex) => (
        <React.Fragment key={`aboutUsPar_${parIndex}_s`}>
          {parIndex === translate.i18n.OUR_DESCRIPTION.length - 1 && hideButton ? (
            <>
              <ScrollBar
                initRecalc
                forceEnableRecalc
                className="position-relative z-1"
                key={`scrollBar_1_${parIndex}`}
                onLoad={() => setAnimate('companyStats', true)}
                onUnload={() => setAnimate('companyStats', false)}
                onLoadThreshold={90}
                onUnloadThreshold={10}
              />
              <p
                className={cx(
                  'position-relative z-1',
                  css.text,
                  css.sAnimated,
                  animate.companyStats
                    ? css.animateIn
                    : animate.companyStats === false
                    ? css.animateOut
                    : undefined
                )}
                dangerouslySetInnerHTML={{ __html: par }}
              />
              <ScrollBar
                key={`scrollBar_2_${parIndex}`}
                onLoad={() => setAnimate('counters', true)}
                onUnload={() => setAnimate('counters', false)}
                onLoadThreshold={90}
                onUnloadThreshold={10}
              />
            </>
          ) : parIndex !== translate.i18n.OUR_DESCRIPTION.length - 1 ? (
            <p className={css.text}>{par}</p>
          ) : null}
        </React.Fragment>
      ))}
      <div
        className={cx(
          css.counters,
          hideButton && css.sAnimated,
          animate.counters ? css.animateIn : animate.counters === false ? css.animateOut : ''
        )}
      >
        {generateCounters().map((counter: ICounter, counterIndex) => (
          <div key={`counter_${counterIndex}`} className={`${css.counter}`}>
            <h3 className={`${css.title}`}>
              <CountUp
                start={
                  !!animateFadeOut && !hideButton
                    ? counter.end
                    : hideButton && animate.counters === false
                    ? counter.end
                    : 0
                }
                // change end to force redraw
                end={
                  (animateFadeIn && !hideButton
                    ? counter.end
                    : animate.counters === true && hideButton
                    ? counter.end
                    : 0) || 0
                }
                duration={counter.duration + 1}
                suffix={counter.suffix}
              />
            </h3>
            <h4 className={css.subtitle}>{counter.title}</h4>
          </div>
        ))}
      </div>
      {!hideButton && (
        <div className="d-flex flex-column">
          <Button className={css.button} text={translate.i18n.MORE_ABOUT_US} {...button} />
        </div>
      )}
      {!!(onLoad || onUnload) && (
        <ScrollBar
          onLoadThreshold={90}
          onUnloadThreshold={onUnloadThreshold || 10}
          onLoad={onLoad}
          onUnload={onUnload}
        />
      )}
    </div>
  )
}
