import React from 'react'

export const Process = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g id="Icon-/-Outline-/-Process" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M17.295,5.721 L17.294,9.209 L29.086,9.2096 C30.3246848,9.2096 31.3382862,10.1724 31.4206025,11.3903484 L31.426,11.5506 L31.426,18.4846 C31.426,19.7240316 30.464321,20.7369485 29.2462719,20.8192064 L29.086,20.8246 L26.06,20.824 L26.06,24.315 L14.19,24.315 L14.19,20.824 L11.1692,20.8246 C10.6876636,20.8246 10.2907611,21.1874126 10.2365176,21.6557628 L10.2302,21.7656 L10.2302,28.6996 C10.2302,29.1818183 10.5927833,29.5789985 11.0597124,29.6332783 L11.1692,29.6396 L23.6612,29.6396 L23.6612,31.0396 L11.1692,31.0396 C9.93120588,31.0396 8.91788973,30.0774617 8.83559601,28.8598145 L8.8302,28.6996 L8.8302,21.7656 C8.8302,20.5263235 9.79173056,19.5123461 11.0090294,19.4299995 L11.1692,19.4246 L14.19,19.424 L14.19,15.936 L26.06,15.936 L26.06,19.424 L29.086,19.4246 C29.5686828,19.4246 29.9654649,19.0624479 30.0196854,18.5943794 L30.026,18.4846 L30.026,11.5506 C30.026,11.0679997 29.6630622,10.6702847 29.195611,10.6159305 L29.086,10.6096 L17.294,10.609 L17.295,14.1 L5.424,14.1 L5.424,5.721 L17.295,5.721 Z M24.66,17.336 L15.59,17.336 L15.59,22.915 L24.66,22.915 L24.66,17.336 Z M15.895,7.121 L6.823,7.121 L6.823,12.7 L15.895,12.7 L15.895,7.121 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <path
        d="M34.825,26.151 L22.955,26.151 L22.955,34.53 L34.825,34.53 L34.825,26.151 Z M33.425,27.551 L33.425,33.129 L24.355,33.129 L24.355,27.551 L33.425,27.551 Z"
        id="Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
