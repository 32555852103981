// .core
import React, { useCallback } from 'react'
// components
import Button from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { Link } from 'components/Link/Link'
// contents
import { ICounter } from 'content/AboutUs/AboutUs'
// interfaces
import { IHerosectionItem } from 'content/Herosection/Herosection'
// libs
import cx from 'classnames'
import CountUp from 'react-countup'
// styles
import css from '../CaseStudy.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { scrollToFooter } from 'utils/functions'

export interface IResultsProps extends IHerosectionItem {
  /**
   * Whether it is visible or not (can be observed by callbacks of other components).
   */
  animateFadeIn: boolean
  /**
   * Whether to show hide animation or not.
   */
  animateFadeOut?: boolean
  /**
   * Callback for onLoad (by default when the scrollbar reaches 90% or more).
   */
  onLoad?: () => void
  /**
   * Callback for the onUnload (by default when the scrollbar reaches 10% or less).
   */
  onUnload?: () => void
  /**
   * button click
   */
  onClick: () => void
}

export const Results = ({
  logo,
  results,
  reference,
  testimonial,
  details,
  animateFadeIn,
  animateFadeOut,
  onClick,
}: IResultsProps) => {
  const onScrollToFooter = useCallback(() => {
    scrollToFooter(true)
  }, [])

  return (
    <div className={cx(css.results, animateFadeIn && css.visible, animateFadeOut && css.hidden)}>
      <h2 className={css.title}>{translate.i18n.RESULTS}</h2>

      {details && (
        <div className="d-flex align-items-center justify-content-center">
          <div className={css.infoBox}>
            <div>{translate.i18n.DURATION}</div>
            <div>{details.duration}</div>
          </div>
          <div className={css.infoBox}>
            <div>{translate.i18n.TEAM}</div>
            <div>{details.team}</div>
          </div>
        </div>
      )}

      {logo ? (
        <div className={css.companyLogo}>
          <img src={logo} alt="Company logo" />
        </div>
      ) : null}

      {results ? (
        <div
          className={cx(
            css.counters,
            animateFadeIn ? css.animateIn : animateFadeOut ? css.animateOut : ''
          )}
        >
          {results.map((counter: ICounter, counterIndex) => (
            <div key={`counter_${counterIndex}`} className={`${css.counter}`}>
              <h3 className={cx(css.title, Boolean(!counter.end && counter.suffix) && css.small)}>
                {counter.end ? (
                  <CountUp
                    start={!!animateFadeOut ? counter.end : 0}
                    // change end to force redraw
                    end={animateFadeIn ? counter.end : 0}
                    duration={counter.duration + 1}
                    suffix={counter.suffix}
                    prefix={counter.prefix}
                  />
                ) : (
                  counter.suffix || counter.prefix
                )}

                {!!counter.description && (
                  <>
                    <Icon className={css.infoIcon} name="info" />
                    <span className={css.infoPopover}>{counter.description}</span>
                  </>
                )}
              </h3>
              <h4 className={`${css.subtitle}`}>{counter.title}</h4>
            </div>
          ))}
        </div>
      ) : null}

      <div className="d-flex flex-column align-items-center">
        {reference || testimonial ? (
          <Link href="#" onClick={onScrollToFooter}>
            {translate.i18n.INTERESTED_IN_MORE}
          </Link>
        ) : null}

        <Button
          text={
            reference
              ? translate.i18n.MORE_INSIGHTS
              : testimonial
              ? translate.i18n.REFERENCES
              : translate.i18n.NAVBAR_TITLE_ICON
          }
          onClick={onClick}
        />
      </div>
    </div>
  )
}
