import React from 'react'

export const Effectivity = ({ className }: { className: SVGElement['className'] }) => (
  <svg
    width="1080"
    zoomAndPan="magnify"
    viewBox="0 0 810 809.999993"
    height="1080"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    className={className}
  >
    <defs>
      <clipPath id="e91593fe4d">
        <path d="M 91 80 L 712 80 L 712 692 L 91 692 Z M 91 80 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="0dda5caacb">
        <path
          d="M 0 225.957031 L 699.890625 80.972656 L 797.867188 553.933594 L 97.976562 698.917969 Z M 0 225.957031 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="a2452fa887">
        <path
          d="M 0 225.957031 L 699.890625 80.972656 L 797.867188 553.933594 L 97.976562 698.917969 Z M 0 225.957031 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="fd494d4ca6">
        <path d="M 594 79 L 714 79 L 714 209 L 594 209 Z M 594 79 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="b927dd561f">
        <path
          d="M 640.582031 52.65625 L 759.878906 106.875 L 713.609375 208.671875 L 594.316406 154.453125 Z M 640.582031 52.65625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="e552348a25">
        <path
          d="M 700.054688 79.683594 L 713.261719 208.515625 L 594.316406 154.453125 Z M 700.054688 79.683594 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#e91593fe4d)">
      <g clipPath="url(#0dda5caacb)">
        <g clipPath="url(#a2452fa887)">
          <path
            fill="#ffffff"
            d="M 648.144531 117.726562 L 596.640625 154.511719 L 630.828125 170.074219 L 498.277344 435.011719 L 384.332031 377.871094 L 368.113281 410.207031 L 326.183594 491.609375 L 210.488281 433.59375 L 210.21875 434.140625 L 210.15625 434.113281 L 91.980469 670.3125 L 134.382812 691.527344 L 231.601562 497.226562 L 321.054688 542.089844 L 346.714844 555.304688 L 405.359375 441.460938 L 519.234375 498.570312 L 519.339844 498.363281 L 519.554688 498.46875 L 674.023438 189.726562 L 711.847656 206.9375 L 705.75 143.945312 L 699.644531 80.953125 L 648.144531 117.726562 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#fd494d4ca6)">
      <g clipPath="url(#b927dd561f)">
        <g clipPath="url(#e552348a25)">
          <path
            fill="#27849c"
            d="M 640.582031 52.65625 L 759.480469 106.695312 L 713.210938 208.492188 L 594.316406 154.453125 Z M 640.582031 52.65625 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)
