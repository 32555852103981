// core
import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
// assets
import arrowDown from 'assets/images/arrow-down.svg'
// import arrowDownDark from 'assets/images/arrow-down-dark.svg'
import { Process } from 'assets/images/icons/Process'
import { Integration } from 'assets/images/icons/Integration'
import { Clients } from 'assets/images/icons/Clients'
import { CSD } from 'assets/images/icons/Custom software'
import { CS } from 'assets/images/icons/IOT/CS'
import { IoT } from 'assets/images/icons/IoT'
// libs
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './WorkflowChart.module.scss'
// translate
import { translate } from 'i18n'

const icons = [Process, Integration, CSD, IoT, CS, Clients]

interface IWorkflowChartProps {
  id: string
}

export const WorkflowChart = ({ id }: IWorkflowChartProps) => {
  const containerRef = useRef<HTMLElement>(null)
  const loadersRefs = useRef<(HTMLImageElement | null)[]>(
    new Array(translate.i18n.WORKFLOW_STEPS.length - 1).fill(null)
  )
  const parallelLinesRefs = useRef<(HTMLDivElement | null)[]>([null, null])

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const getPerc = (pos: number) =>
        containerRef.current
          ? (pos - containerRef.current.offsetTop + (window.innerHeight * 2) / 3) /
            containerRef.current.offsetHeight
          : 0

      const top = Math.max(0, Math.min(1, getPerc(window.scrollY)))

      loadersRefs.current.forEach((r) => {
        if (r && containerRef.current) {
          const startingPercentage = getPerc(
            (r.offsetParent as HTMLDivElement).offsetTop +
              containerRef.current.offsetTop -
              window.innerHeight
          )

          const endingPercentage = getPerc(
            r.offsetHeight +
              (r.offsetParent as HTMLDivElement).offsetTop +
              containerRef.current.offsetTop -
              window.innerHeight
          )

          r.style.height = `${
            100 *
            Math.min(
              1,
              Math.max(0, (top - startingPercentage) / (endingPercentage - startingPercentage))
            )
          }%`
        }
      })
    }
  }, [])

  useEffect(() => {
    setTimeout(handleScroll, 0)
    document.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  return (
    <>
      <section ref={containerRef} className={css.root} id={id}>
        {translate.i18n.WORKFLOW_STEPS.map((step, index) => {
          const Icon = icons[index]

          return (
            <React.Fragment key={step.title}>
              <div
                className={css.step}
                style={{
                  maxWidth: 'min(600px, 90vw)',
                }}
              >
                {index === 2 || index === 3 ? (
                  <ParallelLines
                    ref={(r) => (parallelLinesRefs.current[index - 2] = r)}
                    index={index}
                  />
                ) : undefined}

                <Icon className={css.icon} />
                <h3 className={css.title}>{step.title}</h3>
                <p className={css.text}>{step.text}</p>
              </div>
              {index !== translate.i18n.WORKFLOW_STEPS.length - 1 && loadersRefs.current && (
                <LoadingArrow
                  ref={(r) => (loadersRefs.current[index] = r)}
                  coverFrom={index === 2 ? 55 : undefined}
                  coverHeight={index === 2 ? 65 : undefined}
                />
              )}
            </React.Fragment>
          )
        })}
      </section>
    </>
  )
}

interface IParallelLinesProps {
  index: number
}

const ParallelLines = forwardRef<HTMLDivElement, IParallelLinesProps>(({ index }, ref) => (
  <div
    ref={ref}
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: index === 3 ? 'scaleX(-1)' : undefined,
      top: index === 3 ? -35 : undefined,
      pointerEvents: 'none',
      zIndex: 2,
    }}
  >
    <div
      style={{
        width: '5px',
        background: '#288ba0',
        height: '200px',
        position: 'absolute',
        top: '-188px',
        transform: 'rotate(50deg)',
        left: '59px',
      }}
    />
    <div
      style={{
        width: '5px',
        background: '#288ba0',
        height: '200px',
        position: 'absolute',
        bottom: '-238px',
        transform: 'rotate(-50deg)',
        left: '59px',
      }}
    />
    <div
      style={{
        width: '5px',
        background: '#288ba0',
        height: 'calc(100% + 100px)',
        position: 'absolute',
        top: -25,
        left: -16,
      }}
    />
    <div
      style={{
        height: '5px',
        background: '#288ba0',
        width: 'calc(50% - 136.4px)',
        position: 'absolute',
        top: -154,
        left: 137,
      }}
    />
    <div
      style={{
        height: '5px',
        background: '#288ba0',
        width: 'calc(50% - 136.4px)',
        position: 'absolute',
        bottom: -204,
        left: 137,
      }}
    />
  </div>
))

interface ILoadingArrowProps {
  coverFrom?: number
  coverHeight?: number
}

const LoadingArrow = React.forwardRef<HTMLImageElement, ILoadingArrowProps>(
  ({ coverFrom, coverHeight }, ref) => {
    return (
      <div className={css.arrowsContainer}>
        <img alt="" className={css.arrowDown} src={arrowDown} />
        <img ref={ref} alt="" className={css.arrowDownLoaded} src={arrowDown} />
        {coverHeight && (
          <div className={css.arrowCover} style={{ height: coverHeight, top: coverFrom }} />
        )}
      </div>
    )
  }
)
