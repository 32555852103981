// .core
import React, { useCallback, useEffect, useRef, useState } from 'react'
// components
import CaseItemDetail from './CaseItem.Detail'
import { Icon } from 'components/Icon/Icon'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
// interfaces
import { ICase } from '../../content/Cases/Cases'
// libraries
import cx from 'classnames'
import { useBreakpoint } from 'use-breakpoint'
// @ts-ignore
import window from 'global/window'
// styles
import css from './CaseItem.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { BREAKPOINTS } from 'utils/constants'
import { goTo, getPath } from 'utils/functions'

export interface ICaseItemProps extends ICase {
  /**
   * Whether to show fade in animation or not.
   */
  animateFadeIn?: boolean
  /**
   * Whether to show fade out animation or not.
   */
  animateFadeOut?: boolean
  /**
   * ClassName for custom styling.
   */
  className?: string
  /**
   * Disables scrollbar recalculation.
   */
  disabledRecalc?: boolean
  forceEnableRecalc?: boolean
  /**
   * Hides scrollbar from the non-detail view.
   */
  hideScrollbar?: boolean
  hrefId?: string
  /**
   * Whether to show detailed info or not.
   */
  showDetail?: boolean
  /**
   * Custom threshold for onUnload callback.
   * NOTE: default is 10.
   */
  onUnloadThreshold?: number
  /**
   * Whether to swap sides of the case study info sections (img and text).
   */
  swap?: boolean
  /**
   * OnLoad callback (by def called when 90% or more).
   */
  onLoad?: () => void
  /**
   * OnUnload callback (by def called when 10% or less, can be overwritten with onUnloadThreshold prop).
   */
  onUnload?: () => void
}

export const CaseItem = (props: ICaseItemProps) => {
  const {
    animation,
    animateFadeIn,
    animateFadeOut,
    className,
    disabledRecalc,
    externalUrl,
    forceEnableRecalc,
    hideScrollbar,
    hrefId,
    img: CaseImage,
    onUnloadThreshold,
    showDetail,
    subtitle,
    swap,
    title,
    shortTitle,
    text,
    url,
    onLoad,
    onUnload,
  } = props

  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  const detailPath =
    externalUrl || (url ? getPath(`${translate.i18n.ROUTES.solutions}/${url}`) : undefined)

  const aniRef = useRef<HTMLVideoElement>(null)

  const videoEnded = useRef<boolean | undefined>(undefined)

  const [shouldAnimateSelf, setShouldAnimateSelf] = useState<boolean>()
  const [shouldShowReplay, setShouldShowReplay] = useState<boolean>(false)

  const handleClick = useCallback(() => {
    if (externalUrl && !showDetail) {
      window.open(externalUrl, '_blank')
    } else if (!showDetail) {
      goTo(`${translate.i18n.ROUTES.solutions}/${url}`)
    }
  }, [externalUrl, showDetail, url])

  const handleReplayVideo = () => {
    if (!(aniRef.current && videoEnded.current && shouldShowReplay)) return

    if (aniRef.current) {
      setShouldShowReplay(false)
      videoEnded.current = false
      aniRef.current.pause()
      aniRef.current.currentTime = 0
      try {
        aniRef.current.play().catch((_) => {
          if (aniRef.current) {
            aniRef.current.controls = true
          }
        })
      } catch (_) {}
    }
  }

  const handleVideoEnd = useCallback(() => {
    setShouldShowReplay(true)
    videoEnded.current = true
  }, [])

  const handleVideoLoadStart = useCallback(() => setShouldShowReplay(false), [])

  useEffect(() => {
    if (aniRef.current && showDetail) {
      aniRef.current.currentTime = 0
      try {
        aniRef.current.play().catch((_) => {
          if (aniRef.current) {
            aniRef.current.controls = true
          }
        })
      } catch (_) {}
      videoEnded.current = false
    }
  }, [])

  if (aniRef.current) {
    if ((animateFadeIn || shouldAnimateSelf) && videoEnded.current === undefined) {
      aniRef.current.currentTime = 0
      try {
        aniRef.current.play().catch((_) => {
          if (aniRef.current) {
            aniRef.current.controls = true
          }
        })
      } catch (_) {}
      videoEnded.current = false
    } else if (animateFadeOut || shouldAnimateSelf === false) {
      aniRef.current.pause()
      videoEnded.current = undefined
    }
  }

  const isMobile = breakpoint !== 'desktop'

  return (
    <div className={cx(css.caseItem, className, showDetail && 'mt-0')} id={hrefId}>
      {!showDetail && !hideScrollbar && (
        <ScrollBar
          disabledRecalc={disabledRecalc}
          forceEnableRecalc={forceEnableRecalc}
          onLoadThreshold={90}
          onUnloadThreshold={onUnloadThreshold}
          onLoadChanged={setShouldAnimateSelf}
        />
      )}
      <div
        className={cx(
          css.content,
          swap && css.reverse,
          (animateFadeIn || shouldAnimateSelf) && css.visible,
          ((animateFadeOut && !animateFadeIn) || shouldAnimateSelf === false) && css.hidden
        )}
      >
        {!showDetail ? (
          <>
            <div className={css.textWrapper}>
              {/* txts */}
              <h2 className={css.title}>
                {detailPath ? (
                  <a
                    href={!showDetail ? detailPath : undefined}
                    rel={externalUrl ? 'noopener noreferer' : undefined}
                    target={externalUrl ? '_blank' : undefined}
                  >
                    {shortTitle || title}
                  </a>
                ) : (
                  <span>{shortTitle || title}</span>
                )}
              </h2>

              {isMobile ? (
                <div
                  className={cx(
                    css.imgWrapper,
                    css.mobile,
                    !!animation && css.disableMax,
                    !!animation && 'px-0 w-100'
                  )}
                >
                  {CaseImage && !animation ? (
                    typeof CaseImage === 'string' ? (
                      <img
                        alt=""
                        className={cx(css.img, 'position-relative')}
                        src={CaseImage}
                        onClick={handleClick}
                      />
                    ) : (
                      <CaseImage className={cx(css.img, css.imgComponent, 'position-relative')} />
                    )
                  ) : animation ? (
                    <div className="w-100 position-relative">
                      <video
                        ref={aniRef}
                        muted
                        playsInline
                        className="w-100"
                        src={animation}
                        onEndedCapture={handleVideoEnd}
                        onLoadStart={handleVideoLoadStart}
                      ></video>
                      <button
                        className={cx(
                          css.replayBtn,
                          !(shouldShowReplay && videoEnded.current) && css.hidden
                        )}
                        onClick={handleReplayVideo}
                      >
                        <Icon name="replay" className="mr-2" />
                        {translate.i18n.REPLAY}
                      </button>
                    </div>
                  ) : null}
                  {!showDetail ? (
                    <a className={css.hlink} href={detailPath}>
                      {translate.i18n.READ_MORE}
                    </a>
                  ) : null}
                </div>
              ) : null}

              <h4 className={css.subtitle}>
                <a
                  href={!showDetail ? detailPath : undefined}
                  rel={!showDetail && externalUrl ? 'noopener noreferer' : undefined}
                  target={!showDetail && externalUrl ? '_blank' : undefined}
                >
                  {subtitle}
                </a>
              </h4>

              <p className={css.text}>{text}</p>

              {!showDetail && detailPath ? (
                <a
                  className={css.link}
                  href={detailPath}
                  rel={externalUrl ? 'noopener noreferer' : undefined}
                  target={externalUrl ? '_blank' : undefined}
                >
                  {translate.i18n.READ_MORE}
                  <Icon name="arrowRight" className="ml-2" />
                </a>
              ) : null}
            </div>
            {!isMobile ? (
              <div className={cx(css.imgWrapper, css.desktop)}>
                {/* img */}
                {CaseImage && !animation ? (
                  typeof CaseImage === 'string' ? (
                    <img
                      alt=""
                      className={cx(css.img, 'position-relative h-auto')}
                      src={CaseImage}
                      onClick={handleClick}
                    />
                  ) : (
                    <CaseImage
                      className={cx(css.img, css.imgComponent, 'position-relative h-auto d-flex')}
                    />
                  )
                ) : animation ? (
                  <div className="w-100 position-relative">
                    <video
                      ref={aniRef}
                      muted
                      playsInline
                      className="w-100"
                      src={animation}
                      onEndedCapture={handleVideoEnd}
                      onLoadStart={handleVideoLoadStart}
                    ></video>
                    <button
                      className={cx(
                        css.replayBtn,
                        !(shouldShowReplay && videoEnded.current) && css.hidden
                      )}
                      onClick={handleReplayVideo}
                    >
                      <Icon name="replay" />
                      &nbsp;
                      {translate.i18n.REPLAY}
                    </button>
                  </div>
                ) : null}
                {!showDetail ? (
                  <a className={css.hlink} href={detailPath}>
                    {translate.i18n.READ_MORE}
                  </a>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      {showDetail ? (
        <>
          <CaseItemDetail {...props} />
          <ScrollBar
            disabledRecalc={disabledRecalc}
            forceEnableRecalc={forceEnableRecalc}
            onLoadThreshold={90}
            onUnloadThreshold={onUnloadThreshold}
            onLoad={onLoad}
            onUnload={onUnload}
          />
        </>
      ) : null}
    </div>
  )
}
