// core
import React, { useCallback, useEffect, useState } from 'react'
// components
import { Layout } from 'layouts/Layout'
import { Meta } from 'components/Meta/Meta'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { IResponse as IBlogsResponse, IBlog as IBlogsItem, BlogItem } from 'pages/Blog/Blog'
// libraries
import Axios, { AxiosResponse } from 'axios'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'
// styles
import css from './BlogArticle.module.scss'
import listCss from '../Blog/Blog.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { formatDate, goTo, getPath } from 'utils/functions'

interface IBlog {
  ID: number
  content: string
  hits: number
  image: string
  keywords: string
  perex: string
  publish_on: string
  tags: string
  title: string
  url: string
}

interface IResponse {
  data: IBlog
  error: boolean
}

export default function BlogArticle() {
  const loc = useLocation()

  const url = loc.pathname.substring(`/${translate.i18n.BLOG}/`.length)

  const [blog, setBlog] = useState<IBlog>()
  const [latestBlogs, setLatestBlogs] = useState<IBlogsItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const setAnimateFooterFalse = useCallback(() => {
    setShouldAnimateFooter(false)
  }, [])

  const setAnimateFooterTrue = useCallback(() => {
    setShouldAnimateFooter(true)
  }, [])

  useEffect(() => {
    setIsLoading(true)

    Promise.all([
      Axios.get<IResponse>(`https://www.ambitas.sk/sk/api/feed/json/${url}`)
        .then((res) => {
          if (res.status === 404) {
            goTo(getPath('404'))
          } else if (res.data.error === false && res.data.data) {
            setBlog(res.data.data)
          } else throw new Error()
        })
        .catch((err) => {
          if (err.toJSON().message.includes('404')) goTo(getPath('404'))
          else throw err
        }),
      Axios.get<any, AxiosResponse<IBlogsResponse>>(
        `https://www.ambitas.sk/sk/api/feed/json/1`
      ).then((res) => {
        if (res.status === 200 && !res.data.error) {
          setLatestBlogs(res.data.data)
        } else throw new Error()
      }),
    ]).finally(() => setIsLoading(false))
  }, [url])

  return (
    <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
      {isLoading ? (
        <div className={css.loading}>
          <div className={css.logo} />
        </div>
      ) : (
        <>
          {blog ? (
            <>
              <Meta title={blog.title} description={blog.perex} />

              <div className={css.header}>
                <div className="container d-flex justify-content-center flex-column align-content-center text-center">
                  <h1 className={css.title}>{blog.title}</h1>
                  <span className={css.publicationDate}>
                    {formatDate(new Date(blog.publish_on))}
                  </span>
                </div>
                <div
                  className={css.background}
                  style={{ backgroundImage: `url('https://www.ambitas.sk/${blog.image}')` }}
                />
              </div>
            </>
          ) : null}

          <div className={cx('container', css.root)}>
            {blog ? (
              <>
                <p dangerouslySetInnerHTML={{ __html: blog.content }} />
              </>
            ) : null}
          </div>

          {latestBlogs.length > 0 ? (
            <>
              <ScrollBar />

              <h2 className={css.latestPostsHeading}>{translate.i18n.LATEST_POSTS}</h2>

              <div className={cx('container', listCss.root)}>
                {latestBlogs
                  .filter((b) => b.url !== blog?.url)
                  .slice(0, 4)
                  .map((blog) => (
                    <BlogItem key={blog.url} blog={blog} />
                  ))}
              </div>
            </>
          ) : null}
        </>
      )}

      <ScrollBar onLoad={setAnimateFooterTrue} onUnload={setAnimateFooterFalse} />
    </Layout>
  )
}
