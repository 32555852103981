// .core
import React from 'react'
// components
import Button from 'components/Button/Button'
import { Layout } from 'layouts/Layout'
// libraries
import Helmet from 'react-helmet'
// styles
import css from './E404.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { getPath } from 'utils/functions'

export default function E404() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>

      <Layout blockFooter>
        <div className={css.e404Wrapper}>
          <div className={css.e404}>
            <section className={`${css.banner} container`}>
              <h1 className={css.title}>
                <span>404</span>
                {translate.i18n.ERROR_404}
                <Button
                  className="mx-auto mt-4"
                  text={translate.i18n.TAKE_ME_HOME}
                  href={getPath(translate.i18n.ROUTES.home)}
                />
              </h1>
            </section>
          </div>
        </div>
      </Layout>
    </>
  )
}
