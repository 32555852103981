import { translate } from '../../i18n'

export interface ICounter {
  duration: number
  end?: number
  suffix?: string
  prefix?: string
  title: string
  description?: string
}

export const generateCounters = (): ICounter[] => [
  {
    duration: 10,
    end: new Date().getFullYear() - 2009,
    suffix: '+',
    title: translate.i18n.YEARS_OF_EXPERIENCE,
  },
  {
    duration: 10,
    end: 11,
    suffix: '+',
    title: translate.i18n.COUNTRIES_SERVED,
  },
]
