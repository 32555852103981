import React from 'react'

export const DataCollection = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Data-collection"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M27.7102,8.1936 L27.7098,9.8396 L30.1472302,9.8403 L34.8248171,18.9023281 L34.8238,28.9993 C34.8238,30.6275922 33.5513968,31.9581487 31.9464484,32.0511115 L31.7668,32.0563 L8.4828,32.0563 C6.85421895,32.0563 5.52300084,30.7836184 5.42999111,29.1779587 L5.4248,28.9982295 L5.42581713,18.9022617 L10.1033698,9.8403 L12.5398,9.8396 L12.5402,8.1936 L27.7102,8.1936 Z M6.8248,28.9983 C6.8248,29.8660984 7.4909368,30.5777137 8.33972669,30.6502167 L8.4828,30.6563 L31.7668,30.6563 C32.6340217,30.6563 33.3452555,29.9907095 33.4177199,29.1422499 L33.4238,28.9992295 L33.4238,19.7716 L25.382,19.772 L24.3602859,22.2225 L15.8897141,22.2225 L14.867,19.772 L6.8248,19.7716 L6.8248,28.9983 Z M26.31,9.593 L13.94,9.593 L13.9402,11.9945 L12.5402,11.9945 L12.5398,11.2396 L10.955,11.24 L7.2738,18.3716 L15.7992859,18.3725 L16.821,20.822 L23.428,20.822 L24.4507141,18.3725 L32.9748,18.3716 L29.294,11.24 L27.7098,11.2396 L27.7102,11.9945 L26.3102,11.9945 L26.31,9.593 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <polygon
        id="Shape"
        fill="#288BA0"
        points="29.0617 11.9945 29.0617 18.3725 27.6617 18.3725 27.661 13.394 12.588 13.394 12.5887 18.3725 11.1887 18.3725 11.1887 11.9945"
      ></polygon>
    </g>
  </svg>
)
