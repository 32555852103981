// core
import React, { useState } from 'react'
// libraries
import cx from 'classnames'
// components
import { Layout } from 'layouts/Layout'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
// styles
import css from './GDPR.module.scss'
// trans
import { translate } from 'i18n'

export default function GDPR() {
  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  return (
    <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
      <main className={cx(css.wrapper, 'container')}>
        <h1 className={css.title}>{translate.i18n.GDPR.TITLE}</h1>

        {translate.i18n.GDPR.ARTICLES.map((article, articleIndex) => (
          <div key={`gdprArticle_${articleIndex}`} className={css.article}>
            <h2 className={css.title}>{article.TITLE}</h2>

            {article.TEXTS.map((text, textIndex) => (
              <p key={`gdprArticleParagraph_${textIndex}`} className={`${css.paragraph}`}>
                {`${text}`}
                {articleIndex === translate.i18n.GDPR.ARTICLES.length - 1 &&
                textIndex === translate.i18n.GDPR.ARTICLES[articleIndex].TEXTS.length - 1 ? (
                  <>
                    &nbsp;
                    <a className={css.email} href={`mailto:${translate.i18n.GDPR.EMAIL}`}>
                      {translate.i18n.GDPR.EMAIL}
                    </a>
                    .
                  </>
                ) : null}
              </p>
            ))}
          </div>
        ))}
      </main>

      <ScrollBar
        onLoadThreshold={90}
        onUnloadThreshold={10}
        onLoadChanged={setShouldAnimateFooter}
      />
    </Layout>
  )
}
