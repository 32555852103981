import React from 'react'

export const HMI = (props: { className: string }) => (
  <svg className={props.className || ''} width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 9.5H9C8.17157 9.5 7.5 10.1716 7.5 11V29C7.5 29.8284 8.17157 30.5 9 30.5H31C31.8284 30.5 32.5 29.8284 32.5 29V11C32.5 10.1716 31.8284 9.5 31 9.5ZM9 8C7.34315 8 6 9.34315 6 11V29C6 30.6569 7.34315 32 9 32H31C32.6569 32 34 30.6569 34 29V11C34 9.34315 32.6569 8 31 8H9Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M33 23H7V21.5H33V23Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 31V23H10V31H9Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 31V23H13V31H12Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 31V23H16V31H15Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18 31V23H19V31H18Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 31V23H22V31H21Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 31V23H25V31H24Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27 31V23H28V31H27Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30 31V23H31V31H30Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33 26H7V25H33V26Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33 29H7V28H33V29Z" fill="white" />
    <path
      d="M17.7288 18.0008H18.2448V13.8008H17.7288V15.6188H15.3288V13.8008H14.8008V18.0008H15.3288V16.1048H17.7288V18.0008Z"
      fill="#288BA0"
    />
    <path
      d="M23.0314 14.5508V18.0008H23.5474V13.8008H22.9234L21.4594 15.9608L19.9894 13.8008H19.3594V18.0008H19.8814V14.5388L21.3994 16.6808H21.5074L23.0314 14.5508Z"
      fill="#288BA0"
    />
    <path d="M24.668 18.0008H25.196V13.8008H24.668V18.0008Z" fill="#288BA0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M34 22.5H6V22H34V22.5Z" fill="#10161D" />
  </svg>
)
