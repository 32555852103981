import React from 'react'

export const Clients = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g id="Icon-/-Outline-/-Clients" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M29.1959,4.9249 L17.9669,4.9249 C16.0801062,4.9249 14.5509,6.45449515 14.5509,8.3419 L14.5509,20.3777021 L18.252,16.75 L29.1959,16.7509 C31.0828049,16.7509 32.6129,15.2211938 32.6129,13.3339 L32.6129,8.3419 C32.6129,6.45460615 31.0828049,4.9249 29.1959,4.9249 Z M17.9669,6.3249 L29.1959,6.3249 C30.3096859,6.3249 31.2129,7.22788449 31.2129,8.3419 L31.2129,13.3339 C31.2129,14.4479155 30.3096859,15.3509 29.1959,15.3509 L17.6811358,15.3509 L15.95,17.046 L15.9509,8.3419 C15.9509,7.22761411 16.8533846,6.3249 17.9669,6.3249 Z"
        id="Shape"
        fill="#fff"
      ></path>
      <path
        d="M20.1255,9.7212 C25.8702651,9.7212 30.5285,14.3797665 30.5285,20.1252 C30.5285,23.7602844 28.6477789,27.0690943 25.6322222,28.9536161 L25.3549752,29.120982 L24.6500248,27.911418 C27.4010639,26.3080751 29.1285,23.3671445 29.1285,20.1252 C29.1285,15.1529428 25.0970442,11.1212 20.1255,11.1212 C15.1524558,11.1212 11.1215,15.1524428 11.1215,20.1252 C11.1215,20.4562126 11.1394136,20.7855716 11.1749092,21.1125617 L11.2162532,21.4387358 L9.83074684,21.6396642 C9.75822374,21.1395795 9.7215,20.634025 9.7215,20.1252 C9.7215,14.3792664 14.3792349,9.7212 20.1255,9.7212 Z"
        id="Shape"
        fill="#288BA0"
      ></path>
      <path
        d="M22.2828,20.838 L11.0538,20.838 C9.16700623,20.838 7.6378,22.3675951 7.6378,24.255 L7.6378,29.247 L7.64285396,29.4345044 C7.7401444,31.2346783 9.22989936,32.664 11.0538,32.664 L21.996,32.664 L25.6988,36.2908021 L25.6988,24.255 C25.6988,22.3675951 24.1695938,20.838 22.2828,20.838 Z M22.2828,22.238 L22.43327,22.2435304 C23.4764948,22.3205021 24.2988,23.1913635 24.2988,24.255 L24.298,32.958 L22.5685642,31.264 L11.0538,31.264 C9.94028459,31.264 9.0378,30.3612859 9.0378,29.247 L9.0378,24.255 C9.0378,23.1407141 9.94028459,22.238 11.0538,22.238 L22.2828,22.238 Z"
        id="Shape"
        fill="#fff"
      ></path>
    </g>
  </svg>
)
