import React from 'react'

export const BuildingInstallation = ({ className }: { className: SVGElement['className'] }) => (
  <svg
    className={className}
    width="1080"
    zoomAndPan="magnify"
    viewBox="0 0 810 809.999993"
    height="1080"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <path
      fill="#ffffff"
      d="M 129.867188 680.078125 C 99.347656 649.558594 99.339844 600.15625 129.867188 569.628906 L 328.84375 370.652344 C 333.40625 366.089844 340.804688 366.089844 345.371094 370.652344 L 439.289062 464.574219 C 443.851562 469.136719 443.851562 476.535156 439.289062 481.097656 L 240.3125 680.078125 C 209.792969 710.59375 160.390625 710.601562 129.867188 680.078125 Z M 337.105469 395.441406 L 146.390625 586.15625 C 125.003906 607.539062 125 642.164062 146.390625 663.550781 C 167.777344 684.9375 202.394531 684.941406 223.785156 663.550781 L 414.5 472.835938 Z M 337.105469 395.441406 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 383.640625 520.21875 L 289.722656 426.300781 C 285.160156 421.738281 285.160156 414.339844 289.722656 409.773438 C 294.285156 405.210938 301.683594 405.210938 306.25 409.773438 L 400.167969 503.691406 C 404.730469 508.257812 404.730469 515.65625 400.167969 520.21875 C 395.601562 524.78125 388.207031 524.78125 383.640625 520.21875 Z M 383.640625 520.21875 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 342.324219 561.535156 L 248.40625 467.617188 C 243.84375 463.050781 243.84375 455.652344 248.40625 451.089844 C 252.972656 446.527344 260.367188 446.527344 264.933594 451.089844 L 358.851562 545.007812 C 363.417969 549.570312 363.417969 556.96875 358.851562 561.535156 C 354.285156 566.097656 346.890625 566.097656 342.324219 561.535156 Z M 342.324219 561.535156 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 401.890625 460.226562 L 349.714844 408.050781 C 345.152344 403.488281 345.152344 396.089844 349.714844 391.523438 L 545.464844 195.773438 L 555.027344 167.082031 C 555.808594 164.742188 557.3125 162.707031 559.324219 161.269531 L 632.371094 109.09375 C 637.019531 105.773438 643.386719 106.296875 647.429688 110.335938 L 699.605469 162.515625 C 703.644531 166.554688 704.171875 172.921875 700.851562 177.570312 L 648.671875 250.617188 C 647.238281 252.628906 645.203125 254.132812 642.859375 254.910156 L 614.167969 264.476562 L 418.417969 460.226562 C 413.863281 464.78125 406.464844 464.800781 401.890625 460.226562 Z M 374.503906 399.785156 L 410.15625 435.4375 L 599.59375 246 C 600.875 244.714844 602.441406 243.75 604.160156 243.175781 L 631.863281 233.941406 L 676.078125 172.042969 L 637.898438 133.863281 L 576 178.078125 L 566.765625 205.78125 C 566.191406 207.503906 565.226562 209.066406 563.941406 210.347656 Z M 374.503906 399.785156 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 531.304688 673.464844 C 503.375 645.53125 494.507812 603.316406 508.351562 566.695312 L 401.890625 460.230469 C 397.324219 455.667969 397.324219 448.269531 401.890625 443.703125 C 406.453125 439.140625 413.851562 439.140625 418.417969 443.703125 L 529.007812 554.296875 C 533.289062 558.636719 534.460938 565.207031 531.972656 570.707031 C 518.917969 599.730469 525.285156 634.394531 547.832031 656.9375 C 566.765625 675.875 593.914062 683.359375 619.328125 677.679688 L 583.679688 642.035156 C 579.121094 637.472656 579.117188 630.074219 583.679688 625.507812 L 625.589844 583.597656 C 630.152344 579.039062 637.550781 579.039062 642.117188 583.597656 L 677.757812 619.242188 C 683.429688 593.832031 675.949219 566.679688 657.023438 547.75 C 634.472656 525.203125 599.808594 518.835938 570.765625 531.902344 C 565.289062 534.378906 558.71875 533.207031 554.433594 528.976562 L 443.785156 418.335938 C 439.222656 413.769531 439.222656 406.371094 443.785156 401.808594 C 448.351562 397.246094 455.746094 397.246094 460.3125 401.808594 L 566.777344 508.273438 C 603.410156 494.421875 645.617188 503.292969 673.546875 531.222656 C 703.617188 561.296875 711.511719 607.296875 693.195312 645.6875 C 689.734375 652.941406 680.105469 654.636719 674.382812 648.921875 L 633.851562 608.390625 L 608.46875 633.773438 L 649 674.304688 C 654.691406 679.996094 653.054688 689.632812 645.773438 693.109375 C 607.21875 711.519531 561.253906 703.417969 531.304688 673.464844 Z M 531.304688 673.464844 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 349.710938 408.050781 L 243.246094 301.585938 C 206.609375 315.429688 164.402344 306.5625 136.476562 278.636719 C 106.394531 248.554688 98.5 202.554688 116.832031 164.167969 C 120.300781 156.90625 129.9375 155.234375 135.640625 160.941406 L 176.171875 201.472656 L 201.554688 176.089844 L 161.023438 135.558594 C 155.332031 129.867188 156.96875 120.222656 164.253906 116.75 C 202.648438 98.429688 248.648438 106.324219 278.71875 136.394531 C 306.652344 164.332031 315.519531 206.546875 301.671875 243.167969 L 408.136719 349.628906 C 412.699219 354.191406 412.699219 361.59375 408.136719 366.15625 C 403.570312 370.71875 396.175781 370.71875 391.609375 366.15625 L 281.015625 255.566406 C 276.734375 251.222656 275.5625 244.648438 278.050781 239.15625 C 291.109375 210.132812 284.742188 175.46875 262.191406 152.921875 C 243.261719 133.996094 216.113281 126.511719 190.699219 132.183594 L 226.34375 167.828125 C 230.902344 172.390625 230.90625 179.792969 226.34375 184.355469 L 184.433594 226.261719 C 182.242188 228.453125 179.269531 229.6875 176.171875 229.6875 C 173.074219 229.6875 170.101562 228.453125 167.910156 226.261719 L 132.261719 190.613281 C 126.585938 216.023438 134.066406 243.175781 153.003906 262.109375 C 175.546875 284.65625 210.210938 291.027344 239.257812 277.957031 C 244.734375 275.480469 251.304688 276.652344 255.589844 280.878906 L 366.238281 391.527344 C 370.800781 396.089844 370.800781 403.488281 366.238281 408.054688 C 361.671875 412.617188 354.277344 412.617188 349.710938 408.050781 Z M 349.710938 408.050781 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      strokeLinecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 273.291026, 459.258045)"
      fill="none"
      strokeLinejoin="miter"
      d="M -0.00207008 15.999924 L 146.022853 15.999924 "
      stroke="#27849c"
      strokeWidth="32"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 314.650768, 417.706511)"
      fill="none"
      strokeLinejoin="miter"
      d="M -0.00170715 16.00098 L 146.023216 16.00098 "
      stroke="#27849c"
      strokeWidth="32"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
  </svg>
)
