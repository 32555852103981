// .core
import React, { useEffect, useRef, useState } from 'react'
// libraries
import cx from 'classnames'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './ScrollBar.module.scss'

export interface IScrollBarProps {
  /**
   * ClassName for custom styling of the loader wrapper.
   */
  className?: string
  /**
   * Fills scrollbar higher
   */
  highFill?: boolean
  /**
   * whether scrollbar should be dotted
   */
  dotted?: boolean
  /**
   * Disables height recalc.
   */
  disabledRecalc?: boolean
  /**
   * Enables recalc, note: does not override disableRecalc prop.
   */
  forceEnableRecalc?: boolean
  /**
   * Allows initial recalculation of the scrollbar, should be used on the first scrollbar at the page.
   */
  initRecalc?: boolean
  initRecalcTimeout?: number
  /**
   * Percentual threshold for onLoad callback trigger.
   */
  onLoadThreshold?: number
  /**
   * Percentual threshold for onUnload callback trigger (default 0).
   */
  onUnloadThreshold?: number
  /**
   * Called when reached onLoadThreshold or 100%.
   */
  onLoad?: () => void
  /**
   * Called when reached 0%.
   */
  onUnload?: () => void
  /**
   * Alternative for the combination of onLoad / onUnload
   * @param flag
   * @returns
   */
  onLoadChanged?: (flag: boolean) => void
}

export const ScrollBar = ({
  className,
  disabledRecalc,
  dotted,
  forceEnableRecalc,
  highFill,
  initRecalc,
  initRecalcTimeout = 0,
  onLoadThreshold = 100,
  onUnloadThreshold = 1,
  onLoad,
  onLoadChanged,
  onUnload,
}: IScrollBarProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const innerRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [fillPercentage, setFillPercentage] = useState<string>('unset')

  const normalizePercentage = (percentage: number) =>
    Math.max(0, Math.min(100, Math.ceil(percentage)))

  const handleScroll = () => {
    if (!disabledRecalc) {
      if (wrapperRef.current && innerRef.current) {
        const TOP =
          wrapperRef.current.offsetTop -
          window.scrollY -
          window.innerHeight * ((highFill ? 1 : 2) / 3)
        const normalizedPercentage = normalizePercentage(100 - TOP * 0.2)

        if (forceEnableRecalc || fillPercentage !== `${normalizedPercentage}%`) {
          // check if unload condition was met
          if (normalizedPercentage < onUnloadThreshold && (onUnload || onLoadChanged) && isLoaded) {
            setIsLoaded(false)
            if (onUnload) onUnload()
            if (onLoadChanged) onLoadChanged(false)
          }

          // check if load condition was met
          if (normalizedPercentage >= onLoadThreshold && (onLoad || onLoadChanged) && !isLoaded) {
            setIsLoaded(true)
            if (onLoad) onLoad()
            if (onLoadChanged) onLoadChanged(true)
          }

          // set the fill percentage
          setFillPercentage(`${normalizedPercentage}%`)

          return normalizedPercentage
        }
      } else if (fillPercentage !== 'unset') {
        setFillPercentage('unset')
        return undefined
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    if (initRecalc) {
      setTimeout(() => {
        const perc = handleScroll()

        if (perc !== undefined && !Number.isNaN(perc)) {
          if (perc > onUnloadThreshold) {
            onLoad?.()
          }
        }
      }, initRecalcTimeout)
    }

    return () => {
      document.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [
    disabledRecalc,
    highFill,
    onLoadThreshold,
    onUnloadThreshold,
    isLoaded,
    initRecalc,
    initRecalcTimeout,
    !!onLoad,
    !!onUnload,
    !!onLoadChanged,
  ])

  return (
    <div ref={wrapperRef} className={cx(css.scrollBar, dotted && css.dotted, className)}>
      <div ref={innerRef} className={css.filler} style={{ height: fillPercentage }} />
    </div>
  )
}
