import React from 'react'

export const DesignPlanning = ({ className }: { className: SVGElement['className'] }) => (
  <svg
    width="1080"
    zoomAndPan="magnify"
    viewBox="0 0 810 809.999993"
    height="1080"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    className={className}
  >
    <path
      fill="#ffffff"
      d="M 111.269531 547.265625 L 698.753906 547.265625 L 698.753906 146.921875 L 111.269531 146.921875 Z M 706.910156 563.746094 L 102.945312 563.746094 C 98.371094 563.746094 94.621094 559.996094 94.621094 555.585938 L 94.621094 138.761719 C 94.621094 134.1875 98.371094 130.441406 102.945312 130.441406 L 706.886719 130.441406 C 711.464844 130.441406 715.210938 134.1875 715.210938 138.761719 L 715.210938 555.585938 C 715.210938 559.996094 711.464844 563.746094 706.910156 563.746094 Z M 706.910156 563.746094 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 344.722656 672.5625 C 340.144531 672.5625 336.398438 668.816406 336.398438 664.238281 L 336.398438 605.171875 C 336.398438 600.597656 340.144531 596.847656 344.722656 596.847656 C 349.296875 596.847656 353.042969 600.597656 353.042969 605.171875 L 353.042969 664.238281 C 353.042969 668.816406 349.296875 672.5625 344.722656 672.5625 Z M 344.722656 672.5625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 465.132812 672.5625 C 460.558594 672.5625 456.8125 668.816406 456.8125 664.238281 L 456.8125 605.171875 C 456.8125 600.597656 460.558594 596.847656 465.132812 596.847656 C 469.707031 596.847656 473.457031 600.597656 473.457031 605.171875 L 473.457031 664.238281 C 473.433594 668.816406 469.6875 672.5625 465.132812 672.5625 Z M 465.132812 672.5625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 276.855469 712.539062 L 533.308594 712.539062 C 530.371094 690.035156 511.117188 672.5625 487.804688 672.5625 L 322.382812 672.5625 C 299.050781 672.5625 279.796875 690.015625 276.855469 712.539062 Z M 541.796875 729 L 268.203125 729 C 263.625 729 259.878906 725.253906 259.878906 720.675781 L 259.878906 718.398438 C 259.878906 683.96875 287.933594 656.082031 322.195312 656.082031 L 487.617188 656.082031 C 522.046875 656.082031 549.933594 684.136719 549.933594 718.398438 L 549.933594 720.675781 C 550.121094 725.253906 546.375 729 541.796875 729 Z M 541.796875 729 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 86.132812 97.3125 C 72.59375 97.3125 61.496094 108.410156 61.496094 121.953125 L 61.496094 572.234375 C 61.496094 585.773438 72.59375 596.871094 86.132812 596.871094 L 723.867188 596.871094 C 730.554688 596.871094 736.597656 594.261719 741.320312 589.6875 C 745.894531 585.109375 748.335938 578.921875 748.335938 572.398438 L 748.335938 121.953125 C 748.335938 108.410156 737.238281 97.3125 723.699219 97.3125 Z M 723.699219 613.496094 L 86.132812 613.496094 C 63.464844 613.496094 45.015625 595.070312 45.015625 572.378906 L 45.015625 122.117188 C 45.015625 99.445312 63.445312 81 86.132812 81 L 723.699219 81 C 746.371094 81 764.816406 99.425781 764.816406 122.117188 L 764.816406 572.398438 C 764.984375 583.328125 760.738281 593.597656 753.078125 601.445312 C 745.085938 609.269531 734.796875 613.496094 723.699219 613.496094 Z M 723.699219 613.496094 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 445.382812 273.832031 C 444.410156 273.832031 443.414062 273.667969 442.609375 273.335938 L 329.546875 232.550781 C 325.300781 231.078125 323.023438 226.359375 324.660156 221.949219 C 326.128906 217.707031 330.851562 215.429688 335.261719 217.0625 L 445.546875 256.710938 L 555.671875 217.0625 C 559.914062 215.59375 564.632812 217.726562 566.269531 221.949219 C 567.738281 226.195312 565.609375 230.914062 561.382812 232.550781 L 448.488281 273.335938 C 447.183594 273.6875 446.355469 273.832031 445.382812 273.832031 Z M 445.382812 273.832031 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 340.476562 379.070312 L 445.214844 416.75 L 549.789062 379.070312 L 549.789062 230.769531 L 445.214844 194.890625 L 340.476562 230.789062 Z M 445.382812 433.890625 C 444.410156 433.890625 443.414062 433.726562 442.609375 433.394531 L 329.546875 392.609375 C 326.273438 391.46875 324.164062 388.363281 324.164062 384.78125 L 324.164062 224.746094 C 324.164062 221.164062 326.441406 218.058594 329.710938 216.917969 L 442.773438 178.097656 C 444.574219 177.4375 446.355469 177.4375 448.15625 178.097656 L 561.054688 216.917969 C 564.324219 218.058594 566.601562 221.164062 566.601562 224.746094 L 566.601562 384.78125 C 566.601562 388.199219 564.488281 391.304688 561.21875 392.609375 L 448.320312 433.394531 C 447.183594 433.726562 446.355469 433.890625 445.382812 433.890625 Z M 445.382812 433.890625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 445.382812 433.890625 C 440.808594 433.890625 437.058594 430.144531 437.058594 425.570312 L 437.058594 265.53125 C 437.058594 260.957031 440.808594 257.207031 445.382812 257.207031 C 449.957031 257.207031 453.703125 260.957031 453.703125 265.53125 L 453.703125 425.570312 C 453.539062 430.125 449.957031 433.890625 445.382812 433.890625 Z M 445.382812 433.890625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 354.515625 516.4375 C 352.402344 516.4375 350.269531 515.628906 348.632812 513.992188 L 326.441406 491.800781 C 323.167969 488.527344 323.167969 483.3125 326.441406 480.226562 L 348.632812 457.867188 C 351.90625 454.59375 357.125 454.59375 360.207031 457.867188 C 363.480469 461.136719 363.480469 466.355469 360.207031 469.441406 L 343.726562 486.085938 L 360.207031 502.566406 C 363.480469 505.835938 363.480469 511.054688 360.207031 514.140625 C 358.757812 515.609375 356.625 516.4375 354.515625 516.4375 Z M 354.515625 516.4375 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 535.917969 516.4375 C 533.808594 516.4375 531.675781 515.628906 530.039062 513.992188 C 526.769531 510.722656 526.769531 505.503906 530.039062 502.421875 L 546.519531 485.941406 L 530.039062 469.292969 C 526.769531 466.023438 526.933594 460.804688 530.039062 457.722656 C 533.308594 454.449219 538.527344 454.449219 541.613281 457.722656 L 563.804688 480.082031 C 567.078125 483.351562 567.078125 488.570312 563.804688 491.65625 L 541.613281 513.847656 C 540.164062 515.609375 538.050781 516.4375 535.917969 516.4375 Z M 535.917969 516.4375 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 558.277344 494.242188 L 332.320312 494.242188 C 327.746094 494.242188 323.996094 490.496094 323.996094 485.917969 C 323.996094 481.34375 327.746094 477.597656 332.320312 477.597656 L 558.277344 477.597656 C 562.855469 477.597656 566.601562 481.34375 566.601562 485.917969 C 566.4375 490.496094 562.6875 494.242188 558.277344 494.242188 Z M 558.277344 494.242188 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 273.914062 433.890625 C 271.804688 433.890625 269.671875 433.085938 268.035156 431.449219 L 245.839844 409.089844 C 242.570312 405.816406 242.734375 400.601562 245.839844 397.515625 C 249.113281 394.246094 254.328125 394.246094 257.414062 397.515625 L 273.894531 413.996094 L 290.210938 397.515625 C 293.480469 394.246094 298.699219 394.246094 301.78125 397.515625 C 305.054688 400.785156 305.054688 406.003906 301.78125 409.089844 L 279.753906 431.449219 C 278.160156 433.0625 276.046875 433.890625 273.914062 433.890625 Z M 273.914062 433.890625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 296.109375 216.585938 C 293.996094 216.585938 291.867188 215.78125 290.230469 214.144531 L 273.75 197.664062 L 257.269531 214.144531 C 254 217.414062 248.78125 217.414062 245.695312 214.144531 C 242.425781 210.875 242.425781 205.65625 245.695312 202.570312 L 267.890625 180.355469 C 269.359375 178.886719 271.472656 177.914062 273.769531 177.914062 C 275.882812 177.914062 278.015625 178.722656 279.652344 180.355469 L 301.824219 202.550781 C 305.09375 205.820312 305.09375 211.039062 301.824219 214.125 C 300.355469 215.757812 298.222656 216.585938 296.109375 216.585938 Z M 296.109375 216.585938 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 273.914062 433.890625 C 269.339844 433.890625 265.59375 430.144531 265.59375 425.570312 L 265.59375 186.070312 C 265.59375 181.496094 269.339844 177.746094 273.914062 177.746094 C 278.492188 177.746094 282.238281 181.496094 282.238281 186.070312 L 282.238281 425.570312 C 282.074219 430.125 278.492188 433.890625 273.914062 433.890625 Z M 273.914062 433.890625 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 175.863281 483.972656 L 152.054688 483.972656 C 147.480469 483.972656 143.730469 480.226562 143.730469 475.816406 C 143.730469 471.242188 147.480469 467.492188 152.054688 467.492188 L 175.863281 467.492188 C 180.4375 467.492188 184.1875 471.242188 184.1875 475.816406 C 184.1875 480.226562 180.4375 483.972656 175.863281 483.972656 Z M 175.863281 483.972656 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 175.863281 516.4375 L 152.054688 516.4375 C 147.480469 516.4375 143.730469 512.691406 143.730469 508.28125 C 143.730469 503.703125 147.480469 499.957031 152.054688 499.957031 L 175.863281 499.957031 C 180.4375 499.957031 184.1875 503.703125 184.1875 508.28125 C 184.1875 512.835938 180.4375 516.4375 175.863281 516.4375 Z M 175.863281 516.4375 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.000438886, -0.75, 0.75, 0.000438886, 265.138262, 434.172622)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.502521 11.50163 L 330.74738 11.502314 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.522981, -0.537579, 0.537579, 0.522981, 239.79837, 208.686324)"
      fill="none"
      strokeLinejoin="miter"
      d="M 10.998762 11.001795 L 50.736797 10.999993 "
      stroke="#27849c"
      strokeWidth="22"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(-0.525366, -0.535248, 0.535248, -0.525366, 295.601854, 220.495132)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.501752 11.500491 L 49.510389 11.49991 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(-0.525366, -0.535248, 0.535248, -0.525366, 271.742363, 435.68491)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.500095 11.49908 L 49.512449 11.502148 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.522981, -0.537579, 0.537579, 0.522981, 263.555937, 424.782623)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.001425 10.999659 L 50.735727 11.001489 "
      stroke="#27849c"
      strokeWidth="22"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(-0.75, 0, 0, -0.75, 566.945303, 494.411587)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.499988 11.501908 L 313.390628 11.501908 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.53145, -0.529208, 0.529208, 0.53145, 321.35404, 484.24178)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.501038 11.497401 L 50.233642 11.500257 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(-0.525366, -0.535248, 0.535248, -0.525366, 353.780815, 520.586586)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.498889 11.499037 L 49.514891 11.498388 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(-0.525366, -0.535248, 0.535248, -0.525366, 556.149888, 496.117915)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.500724 11.497318 L 49.513078 11.500385 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.538422, -0.522112, 0.522112, 0.538422, 523.496401, 508.05129)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.499441 11.498386 L 51.326826 11.500979 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
  </svg>
)
