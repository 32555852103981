// .core
import React from 'react'

export const SM = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Soil-moisure"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M31.8316124,8.41487665 L31.0944901,8.42567109 C30.6215753,8.43381952 29.8873818,8.47893483 28.9460415,8.58678184 C27.4547331,8.75763731 25.8656789,9.04299014 24.2328519,9.46909566 C19.6241116,10.6718009 15.5031885,12.756962 12.3148253,15.9453253 C11.9285587,16.3315918 11.5864435,16.7278094 11.2874892,17.1334374 C9.10868837,20.0896839 9.31299258,23.2604662 10.9427206,26.2138805 C11.4019224,27.0460519 11.8636744,27.6642305 12.184407,28.017692 C12.5858695,28.3864256 13.2040481,28.8481776 14.0362195,29.3073794 C16.9896338,30.9371074 20.1604161,31.1414116 23.1166626,28.9626108 C23.5222906,28.6636565 23.9185082,28.3215413 24.3047747,27.9352747 C26.1137662,26.1262833 27.5818184,23.9924565 28.7420133,21.6025795 C30.0663056,18.8746794 30.9266323,15.959257 31.4167602,13.0450153 C31.5882693,12.0252424 31.7013997,11.0775257 31.7674669,10.2254052 L31.7968406,9.80074969 L31.8223429,9.24736396 L31.8316124,8.41487665 Z M30.39,9.858 L30.3716559,10.1171843 C30.3087024,10.9291463 30.2004864,11.835693 30.0361498,12.8128192 C29.5667183,15.6040033 28.743402,18.3940068 27.4825771,20.9911708 C26.3875299,23.2468506 25.007689,25.2524615 23.3148253,26.9453253 C22.9775263,27.2826243 22.6344455,27.5788583 22.286057,27.8356262 C19.8371452,29.6405132 17.233663,29.4727621 14.7126099,28.0816159 C14.287361,27.8469586 13.9030879,27.595495 13.5677077,27.3449808 C13.3703831,27.1975881 13.2363715,27.0862362 13.173192,27.028907 L12.9658885,26.7629218 C12.9464959,26.7374786 12.9261753,26.7105815 12.9051192,26.6823923 C12.654605,26.3470121 12.4031414,25.962739 12.1684841,25.5374901 C10.7773379,23.016437 10.6095868,20.4129548 12.4144738,17.964043 C12.6712417,17.6156545 12.9674757,17.2725737 13.3047747,16.9352747 C16.2965699,13.9434796 20.199865,11.9684369 24.5863595,10.8237293 C26.1519542,10.4151689 27.6766846,10.1413669 29.1053936,9.97768331 C29.4034889,9.94353128 29.6788196,9.91605625 29.9293487,9.89432746 L30.39,9.858 Z"
        id="Stroke-1"
        fill="#fff"
      ></path>
      <path
        d="M16.7721,18.8377 L16.772,23.488 L21.4121,23.4887 L21.4121,24.8887 L16.3497609,24.8887 L9.61963909,31.6194104 L8.62976091,30.6293896 L15.3717609,23.8877 L15.3721,18.8377 L16.7721,18.8377 Z"
        id="Combined-Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
