// core
import React from 'react'
// assets - device templates
import iphone1 from 'assets/images/herosection/iphone 1.webp'
import iphone2 from 'assets/images/herosection/iphone 2.webp'
import iphone61 from 'assets/images/herosection/iphone6 1.webp'
import iphone62 from 'assets/images/herosection/iphone6 2.webp'
import samsung1 from 'assets/images/herosection/samsung 1.webp'
import samsung2 from 'assets/images/herosection/samsung 2.webp'
import ipad1 from 'assets/images/herosection/ipad 1.webp'
import ipad2 from 'assets/images/herosection/ipad 2.webp'
import macbook from 'assets/images/herosection/macbook.webp'
import ipad1a from 'assets/images/herosection/ipad-1a.webp'
import ipad2a from 'assets/images/herosection/ipad-2a.webp'
import iphone13rightSide from 'assets/images/herosection/iphone13-right-side-standing.webp'
// content
import { IHerosectionItem } from 'content/Herosection/Herosection'
// libraries
import cx from 'classnames'
// styles
import css from './Herosection.module.scss'

const phoneMap = {
  iphone12: [iphone1, iphone2],
  iphone6: [iphone61, iphone62],
  samsung: [samsung1, samsung2],
  tablet: [ipad1, ipad2],
  macbook: [macbook],
  portrait: [ipad1a, ipad2a],
}

export interface IHerosectionImagesProps extends IHerosectionItem {
  index?: number
  swipeIndex?: number
  /**
   * Whether to show fadeIn animation or not.
   */
  animateIn?: boolean
  /**
   * Whether to show fadeOut animation or not.
   */
  animateOut?: boolean
  /**
   * images fo mobile devices
   */
  small?: boolean
  /**
   * Whether show detail of herosection images
   */
  detail?: boolean
}

export const HerosectionImages = ({
  type,
  screens,
  detail,
  animateIn,
  animateOut,
  small,
  index,
  swipeIndex,
}: IHerosectionImagesProps) => {
  const mobileMap = ['iphone6', 'samsung', 'iphone12']

  const animationScreen = detail
    ? ['render', 'render-and-screen'].includes(type)
      ? css.fadeIn
      : ''
    : animateIn && index === swipeIndex
    ? css.fadeIn
    : animateOut
    ? css.fadeOut
    : ''

  const mobileClasses = detail
    ? `${css.logo} ${css.background} ${css.phoneIntro}`
    : `${css.logo} ${css.background} ${animateIn && index === swipeIndex ? css.phoneIntro : ''} ${
        css.fadeIn
      }`

  return (
    <div
      className={cx(
        detail && type === 'macbook'
          ? css.macbookWrapper
          : detail
          ? css.detailImgWrapper
          : css.imgWrapper,
        small && css.small
      )}
      style={detail && type === 'render' ? { left: 0, right: 0 } : undefined}
    >
      {type === 'render' ? (
        <div className={css.renderOnly}>
          <img alt="render" className={cx(css.renderPlacement, animationScreen)} src={screens[0]} />
        </div>
      ) : type === 'render-and-screen' ? (
        <div className={css.renderWithScreenshot}>
          <img alt="render" className={cx(css.renderPlacement, animationScreen)} src={screens[0]} />

          <img
            alt="app preview frame"
            className={cx(css.screenshotPlacement, animationScreen)}
            src={iphone13rightSide}
          />
          <img
            alt="app preview content"
            className={cx(css.screenshotPlacement, animationScreen)}
            src={screens[1]}
          />
        </div>
      ) : mobileMap.includes(type) ? (
        <>
          <img className={mobileClasses} src={phoneMap[type][0]} alt="iphone1" />
          <img className={cx(css.logo, animationScreen)} src={screens[0]} alt="Screen1" />

          <img
            className={`${mobileClasses} ${css.secondScreen}`}
            src={phoneMap[type][1]}
            alt="iphone2"
          />
          <img
            className={`${css.logo} ${animationScreen} ${css.secondScreen}`}
            src={screens[1]}
            alt="Screen2"
          />

          <img
            className={`${mobileClasses} ${css.thirdScreen}`}
            src={phoneMap[type][0]}
            alt="iphone3"
          />
          <img
            className={`${css.logo} ${animationScreen} ${css.thirdScreen}`}
            src={screens[2]}
            alt="Screen3"
          />
        </>
      ) : type === 'tablet' || type === 'portrait' ? (
        <>
          <img
            className={` ${css.ipad} ${type === 'portrait' ? css.portrait : ''} ${mobileClasses} ${
              css.secondScreen
            }`}
            src={phoneMap[type][1]}
            alt="ipad1"
          />
          <img
            className={`${css.logo} ${css.ipad} ${
              type === 'portrait' ? css.portrait : ''
            } ${animationScreen} ${css.secondScreen}`}
            src={screens[1]}
            alt="ipad screen"
          />

          <img
            className={`${css.ipad} ${type === 'portrait' ? css.portrait : ''} ${mobileClasses}`}
            src={phoneMap[type][0]}
            alt="ipad2"
          />
          <img
            className={`${css.logo} ${css.ipad} ${
              type === 'portrait' ? css.portrait : ''
            } ${animationScreen}`}
            src={screens[0]}
            alt="ipad screen"
          />
        </>
      ) : (
        <>
          <img
            className={`${css.macbook} ${mobileClasses}`}
            src={phoneMap[type][0]}
            alt="macbook"
          />
          <img
            className={`${css.macbook} ${css.logo} ${animationScreen}`}
            src={screens[0]}
            alt="macbook screen"
          />
        </>
      )}
    </div>
  )
}
