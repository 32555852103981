// core
import React from 'react'
// components
import { Icon } from 'components/Icon/Icon'
// libraries
import cx from 'classnames'
// styles
import css from './NavigationArrows.module.scss'

interface INavigationArrowsProps {
  currentPage: number
  isLoading?: 'prev' | 'next'
  numberOfPages: number
  onNext: () => void
  onPrev: () => void
}

export const NavigationArrows = ({
  currentPage,
  isLoading,
  numberOfPages,
  onNext,
  onPrev,
}: INavigationArrowsProps) => {
  return (
    <div className={cx(css.arrows, 'mt-3')}>
      <button
        aria-label="Navigate to the previous"
        disabled={currentPage === 0 || !!isLoading}
        className={css.button}
        onClick={onPrev}
      >
        <>
          <span
            className={cx(
              'spinner-border text-light',
              css.loader,
              isLoading !== 'prev' && 'd-none'
            )}
          />
          <span className={cx(isLoading === 'prev' && 'd-none')}>
            <Icon name="arrowLeft" />
          </span>
        </>
      </button>
      {currentPage + 1}/{numberOfPages}
      <button
        aria-label="Navigate to the next"
        className={css.button}
        disabled={currentPage === numberOfPages - 1 || !!isLoading}
        onClick={onNext}
      >
        <>
          <span
            className={cx(
              'spinner-border text-light',
              css.loader,
              isLoading !== 'next' && 'd-none'
            )}
          />
          <span className={cx(isLoading === 'next' && 'd-none')}>
            <Icon name="arrowRight" />
          </span>
        </>
      </button>
    </div>
  )
}
