import { translate } from '../../i18n'

import ilcc from 'assets/images/clients/ilcc.jpg'
import merchyou from 'assets/images/clients/merchyou.jpg'
import slovaktual from 'assets/images/clients/slovaktual.jpg'
import webswing from 'assets/images/clients/webswing.jpg'
import diamat from 'assets/images/clients/diamat.jpg'
import arisan from 'assets/images/clients/arisan.jpg'
import nudchPhoto from 'assets/images/clients/nudch.jpg'
import mHroncek from 'assets/images/clients/mHroncek.png'
import mKerenyiova from 'assets/images/clients/mKerenyiova.jpg'
import bKajos from 'assets/images/clients/bKajos.webp'

export interface ITestimonial {
  photo: string
  title: string
  subtitle: string
  text: string
}

export const TESTIMONIALS = {
  veles: {
    ...translate.i18n.TESTIMONIALS.VELES,
    photo: mHroncek,
  },
  yit: {
    ...translate.i18n.TESTIMONIALS.YIT,
    photo: bKajos,
  },
  dss: {
    ...translate.i18n.TESTIMONIALS.DSS,
    photo: mKerenyiova,
  },
  ilcc: {
    ...translate.i18n.TESTIMONIALS.ILCC,
    photo: ilcc,
  },
  slovaktual: {
    ...translate.i18n.TESTIMONIALS.SLOVAKTUAL,
    photo: slovaktual,
  },
  nudch: {
    ...translate.i18n.TESTIMONIALS.NUDCH,
    photo: nudchPhoto,
  },
  merchYou: {
    ...translate.i18n.TESTIMONIALS.MERCHYOU,
    photo: merchyou,
  },
  webswing: {
    ...translate.i18n.TESTIMONIALS.WEBSWING,
    photo: webswing,
  },
  diamat: {
    ...translate.i18n.TESTIMONIALS.DIAMAT,
    photo: diamat,
  },
  arisan: {
    ...translate.i18n.TESTIMONIALS.ARISAN,
    photo: arisan,
  },
}

export const generateTestimonials = (): ITestimonial[] => Object.values(TESTIMONIALS)
