// assets
import eng from 'assets/images/locationImg/us_flag.webp'
// maps
import en from './en'
// libs
// @ts-ignore
import document from 'global/document'

export const locales = [
  {
    name: 'en',
    img: eng,
  },
]

class I18n {
  get i18n() {
    return en
  }

  setLng = (lng?: string) => {
    document.documentElement.lang = lng || 'en'
  }

  lng = (str: string) => {
    let i18n: any = this.i18n

    i18n = en

    const KEYS = str.split('.')

    switch (KEYS.length) {
      case 3:
        return i18n[KEYS[0]][KEYS[1]][KEYS[2]] ? i18n[KEYS[0]][KEYS[1]][KEYS[2]] : KEYS[2]
      case 2:
        return i18n[KEYS[0]][KEYS[1]] ? i18n[KEYS[0]][KEYS[1]] : KEYS[1]
      case 1:
      default:
        return i18n[str] ? i18n[str] : str
    }
  }
}

export const translate = new I18n()
