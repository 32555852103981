import React from 'react'

export const EnviromentControl = ({ className }: { className: SVGElement['className'] }) => (
  <svg
    width="1080"
    zoomAndPan="magnify"
    viewBox="0 0 810 809.999993"
    height="1080"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    className={className}
  >
    <defs>
      <clipPath id="65420e23ff">
        <path
          d="M 330.136719 330.136719 L 479.863281 330.136719 L 479.863281 479.863281 L 330.136719 479.863281 Z M 330.136719 330.136719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="37e7cb0fba">
        <path
          d="M 405 330.136719 C 363.65625 330.136719 330.136719 363.65625 330.136719 405 C 330.136719 446.34375 363.65625 479.863281 405 479.863281 C 446.34375 479.863281 479.863281 446.34375 479.863281 405 C 479.863281 363.65625 446.34375 330.136719 405 330.136719 Z M 405 330.136719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="7dd9ee8d37">
        <path
          d="M 57.09375 163 L 753 163 L 753 651 L 57.09375 651 Z M 57.09375 163 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      fill="#ffffff"
      d="M 405 356.953125 C 377.550781 356.953125 355.296875 379.203125 355.296875 406.652344 C 355.296875 434.101562 377.550781 456.355469 405 456.355469 C 432.449219 456.355469 454.699219 434.101562 454.699219 406.652344 C 454.699219 379.203125 432.449219 356.953125 405 356.953125 Z M 322.164062 406.652344 C 322.164062 360.90625 359.25 323.816406 405 323.816406 C 450.746094 323.816406 487.835938 360.90625 487.835938 406.652344 C 487.835938 452.402344 450.746094 489.488281 405 489.488281 C 359.25 489.488281 322.164062 452.402344 322.164062 406.652344 Z M 322.164062 406.652344 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <path
      fill="#ffffff"
      d="M 269.164062 285.167969 C 240.296875 317.417969 222.761719 359.96875 222.761719 406.652344 C 222.761719 453.339844 240.296875 495.890625 269.164062 528.136719 L 244.472656 550.238281 C 210.378906 512.144531 189.628906 461.804688 189.628906 406.652344 C 189.628906 351.503906 210.378906 301.160156 244.472656 263.070312 Z M 587.234375 406.652344 C 587.234375 359.96875 569.703125 317.417969 540.835938 285.167969 L 565.527344 263.070312 C 599.617188 301.160156 620.371094 351.503906 620.371094 406.652344 C 620.371094 461.804688 599.617188 512.144531 565.527344 550.238281 L 540.835938 528.136719 C 569.703125 495.890625 587.234375 453.339844 587.234375 406.652344 Z M 587.234375 406.652344 "
      fillOpacity="1"
      fillRule="evenodd"
    />
    <g clipPath="url(#7dd9ee8d37)">
      <path
        fill="#ffffff"
        d="M 180.207031 186.3125 C 124.53125 243.105469 90.226562 320.859375 90.226562 406.652344 C 90.226562 492.449219 124.53125 570.199219 180.207031 626.992188 L 156.546875 650.1875 C 95.042969 587.453125 57.09375 501.46875 57.09375 406.652344 C 57.09375 311.839844 95.042969 225.855469 156.546875 163.117188 Z M 719.773438 406.652344 C 719.773438 320.859375 685.46875 243.105469 629.792969 186.3125 L 653.453125 163.117188 C 714.953125 225.855469 752.90625 311.839844 752.90625 406.652344 C 752.90625 501.46875 714.953125 587.453125 653.453125 650.1875 L 629.792969 626.992188 C 685.46875 570.199219 719.773438 492.449219 719.773438 406.652344 Z M 719.773438 406.652344 "
        fillOpacity="1"
        fillRule="evenodd"
      />
    </g>
  </svg>
)
