import forbes from '../../assets/images/logos/forbes.webp'
import startitup from '../../assets/images/logos/startitup.svg'
import avf from '../../assets/images/logos/avf-logo.webp'
import { translate } from 'i18n'

export interface IMedia {
  logo: string
  title: string
  url?: string
}

export const generateMedia = (): IMedia[] => [
  {
    logo: forbes,
    title: translate.i18n.MEDIA.forbes,
    url: `https://www.forbes.sk/startupom-roka-je-photoneo-kto-vyhral-na-cesawards-suboj-o-najlepsi-coworking-ci-zensky-idol/`,
  },
  {
    logo: startitup,
    title: translate.i18n.MEDIA.startitupCovid,
    url: `https://www.startitup.sk/mladi-slovaci-za-par-hodin-vytvorili-online-system-vdaka-ktoremu-sa-na-ockovanie-objednas-jednoducho-a-rychlo/`,
  },
  {
    logo: avf,
    title: translate.i18n.MEDIA.avf,
    url: `https://vertical-farming.net/blog/2024/04/05/welcome-to-avf-qa-with-ambitas/`,
  },
]
