// core
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// components
import { NavigationArrows } from 'components/NavigationArrows/NavigationArrows'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { Layout } from 'layouts/Layout'
// libraries
import Axios, { AxiosResponse } from 'axios'
import cx from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
// styles
import css from './Blog.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { formatDate } from 'utils/functions'

export interface IBlog {
  image: string
  keywords: string
  perex: string
  publish_on: string
  tags: string
  title: string
  url: string
}

export interface IResponse {
  data: IBlog[]
  error: boolean
  page: number
  total: number
}

interface IBlogItemProps {
  blog: IBlog
}

export const BlogItemPlaceholder = () => {
  return <div className={cx('card', css.blogItem, css.placeholder)} />
}

export const BlogItem = ({ blog }: IBlogItemProps) => {
  const date = new Date(blog.publish_on)

  return (
    <a className={cx('card', css.blogItem)} href={`/${translate.i18n.ROUTES.blog}/${blog.url}`}>
      <img alt="" className="card-img-top" src={`https://www.ambitas.sk/${blog.image}`} />
      <div className="card-body">
        <h5 className="card-title">{blog.title}</h5>
        <p className="card-text" dangerouslySetInnerHTML={{ __html: blog.perex }} />
        <span className={css.footer}>{formatDate(date)}</span>
      </div>
    </a>
  )
}

const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export default function Blog() {
  const [page, setPage] = useState<number>()
  const [pageCount, setPageCount] = useState<number>()
  const [blogs, setBlogs] = useState<IBlog[]>([])

  const queryParams = useQuery()
  const history = useHistory()

  // undefined -> main content; 0 -> no; -1 -> prev btn; +1 -> next btn;
  const [isLoading, setIsLoading] = useState<number>()

  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const setAnimateFooterFalse = useCallback(() => {
    setShouldAnimateFooter(false)
  }, [])

  const setAnimateFooterTrue = useCallback(() => {
    setShouldAnimateFooter(true)
  }, [])

  const handleNextPage = useCallback(() => {
    setPage((p) => (typeof p === 'number' ? p + 1 : p))
    setIsLoading(1)
  }, [])

  const handlePrevPage = useCallback(() => {
    setPage((p) => (typeof p === 'number' ? Math.max(1, p - 1) : p))
    setIsLoading(-1)
  }, [])

  useEffect(() => {
    setPage(!Number(queryParams.get('page')) ? 1 : Number(queryParams.get('page')))
  }, [])

  useEffect(() => {
    if (typeof page === 'number') queryParams.set('page', page.toString())
    history.push({
      search: queryParams.toString(),
    })
  }, [page])

  useEffect(() => {
    if (typeof page === 'number') {
      Axios.get<any, AxiosResponse<IResponse>>(`https://www.ambitas.sk/sk/api/feed/json/${page}`)
        .then((res) => {
          if (res.status === 200 && !res.data.error) {
            setBlogs(res.data.data)
            setPageCount(res.data.data.length ? Math.ceil(res.data.total / 10) : 1)
          } else throw new Error()
        })
        .finally(() => setIsLoading(0))
    }
  }, [page])

  return (
    <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
      <header className={cx(css.header, isLoading === undefined && css.loading)}>
        <div className={css.logo} />
        <h1>{translate.i18n.BLOG}</h1>
      </header>

      <main className={cx('container', css.root)}>
        {blogs.length ? (
          blogs.map((blog) => <BlogItem key={blog.url} blog={blog} />)
        ) : (
          <>
            <p className={cx('text-center text-wrap', css.fallbackText)}>
              {translate.i18n.BLOG_NO_CONTENT}
            </p>
          </>
        )}
      </main>

      {typeof page === 'number' && typeof pageCount === 'number' && blogs.length > 0 && (
        <NavigationArrows
          currentPage={page - 1}
          isLoading={isLoading === 1 ? 'next' : isLoading === -1 ? 'prev' : undefined}
          numberOfPages={pageCount}
          onNext={handleNextPage}
          onPrev={handlePrevPage}
        />
      )}

      <ScrollBar
        initRecalc
        initRecalcTimeout={550}
        onLoad={setAnimateFooterTrue}
        onUnload={setAnimateFooterFalse}
      />
    </Layout>
  )
}
