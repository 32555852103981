import React from 'react'

export const LargeFarmers = ({ className }: { className: SVGElement['className'] }) => (
  <svg
    width="1080"
    zoomAndPan="magnify"
    viewBox="0 0 810 809.999993"
    height="1080"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    className={className}
  >
    <defs>
      <clipPath id="7468273c9e">
        <path
          d="M 50.359375 105.976562 L 759.628906 105.976562 L 759.628906 703.726562 L 50.359375 703.726562 Z M 50.359375 105.976562 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="039f05b50c">
        <path
          d="M 483 592 L 596 592 L 596 703.726562 L 483 703.726562 Z M 483 592 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d3ded5fee2">
        <path
          d="M 214 592 L 327 592 L 327 703.726562 L 214 703.726562 Z M 214 592 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="68e07480f1">
        <path
          d="M 349 592 L 461 592 L 461 703.726562 L 349 703.726562 Z M 349 592 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="746782a885">
        <path
          d="M 213.402344 592.253906 L 327.402344 592.253906 L 327.402344 707.753906 L 213.402344 707.753906 Z M 213.402344 592.253906 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="6a746b3336">
        <path
          d="M 223.902344 592.253906 L 317.191406 592.253906 C 322.992188 592.253906 327.691406 596.957031 327.691406 602.753906 L 327.691406 696.929688 C 327.691406 702.726562 322.992188 707.425781 317.191406 707.425781 L 223.902344 707.425781 C 218.105469 707.425781 213.402344 702.726562 213.402344 696.929688 L 213.402344 602.753906 C 213.402344 596.957031 218.105469 592.253906 223.902344 592.253906 Z M 223.902344 592.253906 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="e53e5748a7">
        <path
          d="M 348.214844 592.253906 L 462.214844 592.253906 L 462.214844 707.753906 L 348.214844 707.753906 Z M 348.214844 592.253906 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="27b24c3c2d">
        <path
          d="M 358.714844 592.253906 L 452.003906 592.253906 C 457.804688 592.253906 462.503906 596.957031 462.503906 602.753906 L 462.503906 696.929688 C 462.503906 702.726562 457.804688 707.425781 452.003906 707.425781 L 358.714844 707.425781 C 352.914062 707.425781 348.214844 702.726562 348.214844 696.929688 L 348.214844 602.753906 C 348.214844 596.957031 352.914062 592.253906 358.714844 592.253906 Z M 358.714844 592.253906 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f5a3e48061">
        <path
          d="M 483.117188 592.253906 L 597.117188 592.253906 L 597.117188 707.753906 L 483.117188 707.753906 Z M 483.117188 592.253906 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f1333d414d">
        <path
          d="M 493.617188 592.253906 L 586.910156 592.253906 C 592.707031 592.253906 597.410156 596.957031 597.410156 602.753906 L 597.410156 696.929688 C 597.410156 702.726562 592.707031 707.425781 586.910156 707.425781 L 493.617188 707.425781 C 487.820312 707.425781 483.117188 702.726562 483.117188 696.929688 L 483.117188 602.753906 C 483.117188 596.957031 487.820312 592.253906 493.617188 592.253906 Z M 493.617188 592.253906 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0bb9fdf1c6">
        <path
          d="M 280.171875 455.332031 L 394.167969 455.332031 L 394.167969 570.832031 L 280.171875 570.832031 Z M 280.171875 455.332031 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="80f08827f2">
        <path
          d="M 290.671875 455.332031 L 383.960938 455.332031 C 389.761719 455.332031 394.460938 460.035156 394.460938 465.832031 L 394.460938 560.003906 C 394.460938 565.804688 389.761719 570.503906 383.960938 570.503906 L 290.671875 570.503906 C 284.871094 570.503906 280.171875 565.804688 280.171875 560.003906 L 280.171875 465.832031 C 280.171875 460.035156 284.871094 455.332031 290.671875 455.332031 Z M 290.671875 455.332031 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="61914733df">
        <path
          d="M 415.269531 455.023438 L 529.269531 455.023438 L 529.269531 570.523438 L 415.269531 570.523438 Z M 415.269531 455.023438 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="a0fd2a5d33">
        <path
          d="M 425.769531 455.023438 L 519.0625 455.023438 C 524.859375 455.023438 529.5625 459.722656 529.5625 465.523438 L 529.5625 559.695312 C 529.5625 565.492188 524.859375 570.195312 519.0625 570.195312 L 425.769531 570.195312 C 419.972656 570.195312 415.269531 565.492188 415.269531 559.695312 L 415.269531 465.523438 C 415.269531 459.722656 419.972656 455.023438 425.769531 455.023438 Z M 425.769531 455.023438 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#7468273c9e)">
      <path
        fill="#ffffff"
        d="M 404.492188 121.78125 C 407.957031 121.71875 411.480469 122.414062 414.628906 123.796875 L 743.824219 271.457031 L 743.824219 328.316406 L 698.929688 328.316406 L 698.929688 689.058594 L 648.933594 689.058594 L 648.933594 349.914062 L 160.996094 349.914062 L 160.996094 689.058594 L 110.9375 689.058594 L 110.9375 328.316406 L 66.039062 328.316406 L 66.039062 271.457031 L 395.296875 123.796875 C 398.195312 122.539062 401.28125 121.847656 404.492188 121.78125 Z M 404.113281 105.976562 C 398.886719 106.105469 393.660156 107.300781 388.875 109.441406 L 50.359375 261.257812 L 50.359375 344.058594 L 95.195312 344.058594 L 95.195312 704.800781 L 176.800781 704.800781 L 176.800781 365.65625 L 633.191406 365.65625 L 633.191406 704.800781 L 714.734375 704.800781 L 714.734375 344.058594 L 759.628906 344.058594 L 759.628906 261.257812 L 421.117188 109.441406 C 415.761719 107.046875 409.96875 105.851562 404.113281 105.976562 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#ffffff"
      d="M 502.027344 263.964844 C 506.6875 263.964844 510.464844 260.1875 510.464844 255.527344 C 510.464844 250.804688 506.6875 247.089844 502.027344 247.089844 L 307.960938 247.089844 C 303.300781 247.089844 299.523438 250.804688 299.523438 255.527344 C 299.523438 260.1875 303.300781 263.964844 307.960938 263.964844 L 502.027344 263.964844 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 463.242188 223.035156 C 467.902344 223.035156 471.679688 219.257812 471.679688 214.597656 C 471.679688 209.9375 467.902344 206.160156 463.242188 206.160156 L 346.75 206.160156 C 342.027344 206.160156 338.25 209.9375 338.3125 214.597656 C 338.3125 219.257812 342.089844 223.035156 346.75 223.035156 L 463.242188 223.035156 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <g clipPath="url(#039f05b50c)">
      <path
        fill="#ffffff"
        d="M 499.195312 608.335938 L 579.667969 608.335938 L 579.667969 689.058594 L 499.195312 689.058594 Z M 491.765625 592.53125 C 487.167969 592.53125 483.453125 596.308594 483.453125 600.90625 L 483.453125 696.488281 C 483.453125 701.085938 487.167969 704.800781 491.765625 704.800781 L 587.035156 704.800781 C 591.695312 704.863281 595.410156 701.085938 595.472656 696.488281 L 595.472656 600.90625 C 595.472656 596.308594 591.695312 592.53125 587.097656 592.53125 C 587.097656 592.53125 587.035156 592.53125 587.035156 592.53125 L 491.765625 592.53125 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#d3ded5fee2)">
      <path
        fill="#ffffff"
        d="M 230.324219 608.335938 L 310.796875 608.335938 L 310.796875 689.058594 L 230.324219 689.058594 Z M 222.894531 592.53125 C 218.296875 592.59375 214.582031 596.308594 214.582031 600.90625 L 214.582031 696.488281 C 214.582031 701.085938 218.296875 704.800781 222.894531 704.800781 L 318.164062 704.800781 C 322.824219 704.800781 326.539062 701.085938 326.539062 696.488281 L 326.539062 600.90625 C 326.539062 596.308594 322.824219 592.53125 318.164062 592.53125 L 222.894531 592.53125 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#68e07480f1)">
      <path
        fill="#ffffff"
        d="M 364.757812 608.335938 L 445.230469 608.335938 L 445.230469 689.058594 L 364.757812 689.058594 Z M 357.328125 592.53125 C 352.734375 592.53125 349.015625 596.308594 349.015625 600.90625 L 349.015625 696.488281 C 349.015625 701.085938 352.734375 704.800781 357.328125 704.800781 L 452.664062 704.800781 C 457.195312 704.863281 460.972656 701.085938 460.972656 696.488281 L 460.972656 600.90625 C 460.972656 596.308594 457.257812 592.53125 452.664062 592.53125 L 357.328125 592.53125 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#ffffff"
      d="M 297.574219 471.003906 L 377.984375 471.003906 L 377.984375 551.789062 L 297.574219 551.789062 Z M 290.078125 455.261719 C 285.484375 455.261719 281.769531 458.976562 281.769531 463.570312 L 281.769531 559.15625 C 281.769531 563.816406 285.484375 567.53125 290.078125 567.53125 L 385.414062 567.53125 C 390.007812 567.53125 393.722656 563.753906 393.722656 559.15625 L 393.722656 463.570312 C 393.722656 458.976562 390.007812 455.261719 385.414062 455.261719 L 290.078125 455.261719 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 432.007812 471.003906 L 512.417969 471.003906 L 512.417969 551.789062 L 432.007812 551.789062 Z M 424.578125 455.261719 C 419.917969 455.261719 416.265625 458.976562 416.265625 463.570312 L 416.265625 559.15625 C 416.265625 563.816406 419.917969 567.53125 424.578125 567.53125 L 519.847656 567.53125 C 524.445312 567.53125 528.222656 563.816406 528.222656 559.21875 C 528.222656 559.21875 528.222656 559.21875 528.222656 559.15625 L 528.222656 463.570312 C 528.222656 458.976562 524.445312 455.261719 519.910156 455.261719 C 519.847656 455.261719 519.847656 455.261719 519.847656 455.261719 L 424.578125 455.261719 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.749998, 0.0016427, -0.0016427, 0.749998, 337.883718, 205.613448)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.999063 11.999567 L 167.030685 11.998548 "
      stroke="#27849c"
      strokeWidth="24"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <path
      strokeLinecap="round"
      transform="matrix(0.75, 0, 0, 0.75, 299.256814, 247.093133)"
      fill="none"
      strokeLinejoin="miter"
      d="M 11.501332 11.500823 L 271.444053 11.500823 "
      stroke="#27849c"
      strokeWidth="23"
      strokeOpacity="1"
      strokeMiterlimit="4"
    />
    <g clipPath="url(#746782a885)">
      <g clipPath="url(#6a746b3336)">
        <path
          strokeLinecap="butt"
          transform="matrix(0.75, 0, 0, 0.75, 213.40278, 592.255526)"
          fill="none"
          strokeLinejoin="miter"
          d="M 13.999419 -0.00215994 L 138.384842 -0.00215994 C 146.119217 -0.00215994 152.384843 6.268674 152.384843 13.997841 L 152.384843 139.565555 C 152.384843 147.294722 146.119217 153.560348 138.384842 153.560348 L 13.999419 153.560348 C 6.270252 153.560348 -0.000581619 147.294722 -0.000581619 139.565555 L -0.000581619 13.997841 C -0.000581619 6.268674 6.270252 -0.00215994 13.999419 -0.00215994 Z M 13.999419 -0.00215994 "
          stroke="#27849c"
          strokeWidth="48"
          strokeOpacity="1"
          strokeMiterlimit="4"
        />
      </g>
    </g>
    <g clipPath="url(#e53e5748a7)">
      <g clipPath="url(#27b24c3c2d)">
        <path
          strokeLinecap="butt"
          transform="matrix(0.75, 0, 0, 0.75, 348.214352, 592.255526)"
          fill="none"
          strokeLinejoin="miter"
          d="M 14.000657 -0.00215994 L 138.38608 -0.00215994 C 146.120455 -0.00215994 152.386081 6.268674 152.386081 13.997841 L 152.386081 139.565555 C 152.386081 147.294722 146.120455 153.560348 138.38608 153.560348 L 14.000657 153.560348 C 6.266282 153.560348 0.000656224 147.294722 0.000656224 139.565555 L 0.000656224 13.997841 C 0.000656224 6.268674 6.266282 -0.00215994 14.000657 -0.00215994 Z M 14.000657 -0.00215994 "
          stroke="#27849c"
          strokeWidth="48"
          strokeOpacity="1"
          strokeMiterlimit="4"
        />
      </g>
    </g>
    <g clipPath="url(#f5a3e48061)">
      <g clipPath="url(#f1333d414d)">
        <path
          strokeLinecap="butt"
          transform="matrix(0.75, 0, 0, 0.75, 483.118251, 592.255526)"
          fill="none"
          strokeLinejoin="miter"
          d="M 13.998582 -0.00215994 L 138.389214 -0.00215994 C 146.118381 -0.00215994 152.389214 6.268674 152.389214 13.997841 L 152.389214 139.565555 C 152.389214 147.294722 146.118381 153.560348 138.389214 153.560348 L 13.998582 153.560348 C 6.269415 153.560348 -0.00141826 147.294722 -0.00141826 139.565555 L -0.00141826 13.997841 C -0.00141826 6.268674 6.269415 -0.00215994 13.998582 -0.00215994 Z M 13.998582 -0.00215994 "
          stroke="#27849c"
          strokeWidth="48"
          strokeOpacity="1"
          strokeMiterlimit="4"
        />
      </g>
    </g>
    <g clipPath="url(#0bb9fdf1c6)">
      <g clipPath="url(#80f08827f2)">
        <path
          strokeLinecap="butt"
          transform="matrix(0.75, 0, 0, 0.75, 280.171103, 455.332509)"
          fill="none"
          strokeLinejoin="miter"
          d="M 14.00103 -0.000637124 L 138.386453 -0.000637124 C 146.120828 -0.000637124 152.386454 6.270197 152.386454 13.999364 L 152.386454 139.56187 C 152.386454 147.296245 146.120828 153.561871 138.386453 153.561871 L 14.00103 153.561871 C 6.266655 153.561871 0.00102933 147.296245 0.00102933 139.56187 L 0.00102933 13.999364 C 0.00102933 6.270197 6.266655 -0.000637124 14.00103 -0.000637124 Z M 14.00103 -0.000637124 "
          stroke="#27849c"
          strokeWidth="52"
          strokeOpacity="1"
          strokeMiterlimit="4"
        />
      </g>
    </g>
    <g clipPath="url(#61914733df)">
      <g clipPath="url(#a0fd2a5d33)">
        <path
          strokeLinecap="butt"
          transform="matrix(0.75, 0, 0, 0.75, 415.271167, 455.021949)"
          fill="none"
          strokeLinejoin="miter"
          d="M 13.99782 0.00198452 L 138.388451 0.00198452 C 146.117619 0.00198452 152.388452 6.26761 152.388452 14.001985 L 152.388452 139.564492 C 152.388452 147.293659 146.117619 153.564492 138.388451 153.564492 L 13.99782 153.564492 C 6.268653 153.564492 -0.00218048 147.293659 -0.00218048 139.564492 L -0.00218048 14.001985 C -0.00218048 6.26761 6.268653 0.00198452 13.99782 0.00198452 Z M 13.99782 0.00198452 "
          stroke="#27849c"
          strokeWidth="50"
          strokeOpacity="1"
          strokeMiterlimit="4"
        />
      </g>
    </g>
  </svg>
)
