import React from 'react'

export const HCM = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Healthcare"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Shape"
        fill="#288BA0"
        points="20.041 22.308 23.2346037 12.4959713 25.174 18.326 26.1877987 16.3628858 28.092 20.028 34.625 20.0285 34.625 21.4285 27.2418354 21.4285 26.192 19.408 24.8953555 21.9220861 23.249 16.975 20.2122065 26.3089029 16.734 18.076 14.9772973 21.4285 5.625 21.4285 5.625 20.0285 14.131 20.028 16.8712431 14.8033167"
      ></polygon>
      <path
        d="M25.8471,9.1013 C23.9273539,9.1013 22.3421915,9.58350976 21.0808628,10.391966 L20.8569314,10.5409407 C20.6411694,10.6899518 20.4498267,10.8386135 20.2821008,10.982799 L20.127,11.121 L19.9717813,10.9827275 C19.7481069,10.7904983 19.482454,10.5903096 19.1729258,10.3919427 C17.9114581,9.58350712 16.3264065,9.1013 14.4071,9.1013 C10.1268246,9.1013 7.5931,12.1719315 7.5931,16.5583 C7.5931,20.8346598 11.3602852,25.5583405 19.3106004,30.7531958 L20.1271,31.2806156 L20.505795,31.037019 C28.7556809,25.7302585 32.6611,20.9124118 32.6611,16.5583 C32.6611,12.1723744 30.1266969,9.1013 25.8471,9.1013 Z M25.8471,10.5013 C29.2877149,10.5013 31.2611,12.8925583 31.2611,16.5583 L31.2575029,16.7639162 C31.1326929,20.341437 27.756436,24.5549592 20.5845307,29.3134363 L20.127,29.614 L19.6620111,29.3083545 C12.3575403,24.4604901 8.9931,20.1783727 8.9931,16.5583 C8.9931,12.8920864 10.965852,10.5013 14.4071,10.5013 C16.0534185,10.5013 17.3774294,10.9040929 18.4175242,11.5706573 C18.7749261,11.7997052 19.07076,12.0431814 19.3076502,12.2837239 L19.4435947,12.4283579 C19.4988304,12.4903816 19.5350747,12.5360947 19.5528386,12.5615797 L20.1272746,13.3856946 L20.7014756,12.5614159 C20.731046,12.5189669 20.8119276,12.4203278 20.9464972,12.2836344 C21.1832768,12.043118 21.4790111,11.7996643 21.8363372,11.570634 C22.8762585,10.9040902 24.2003461,10.5013 25.8471,10.5013 Z"
        id="Shape"
        fill="#fff"
      ></path>
    </g>
  </svg>
)
