// .core
import React, { useCallback, useState } from 'react'
// components
import Button, { IButtonProps } from 'components/Button/Button'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { Link } from 'components/Link/Link'
// libs
import cx from 'classnames'
// styles
import css from './ArticlesTiles.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { getPath } from 'utils/functions'

export interface ITile {
  externalUrl?: string
  href?: string
  img?: any
  title: string
  text: string | string[]
  url?: string
}

export interface IArticlesTilesProps {
  /**
   * Footer button props.
   */
  button?: IButtonProps
  /**
   * Sets mt-5 to the ScrollBar if true.
   */
  disableScrollbarMt?: boolean
  tileClassName?: string
  tiles: {
    title: string
    tiles: ITile[]
  }
  title?: string
  text?: string
  /**
   * Whether should be shown link above button.
   */
  link?: React.HTMLProps<HTMLAnchorElement>
}

export const ArticlesTiles = ({
  button,
  disableScrollbarMt,
  tileClassName,
  tiles,
  title,
  text,
  link,
}: IArticlesTilesProps) => {
  const [animate, setAnimate] = useState<boolean>()

  const onLoad = useCallback(() => setAnimate(true), [])
  const onUnload = useCallback(() => setAnimate(false), [])

  return (
    <div className={cx(css.articlesTiles, css.animated, animate ? css.visible : css.hidden)}>
      <ScrollBar
        className={cx(disableScrollbarMt && 'mt-0')}
        onLoadThreshold={90}
        onUnloadThreshold={10}
        onLoad={onLoad}
        onUnload={onUnload}
      />
      {!!(title || text) && (
        <header className={css.header}>
          {!!title && <h2 className={css.title}>{title}</h2>}
          {!!text && <p className={css.text}>{text}</p>}
        </header>
      )}
      <main className={cx(css.main, css.desktop)}>
        <div className={css.tiles}>
          {tiles.tiles.map((tile, tileIndex) => (
            <a
              key={`articleTile_${tile.title}_${tileIndex}`}
              className={cx(css.tileWrapper, tileClassName)}
              href={
                tile.externalUrl
                  ? tile.externalUrl
                  : tile.url
                  ? `${getPath(`${translate.i18n.ROUTES.solutions}/${tile.url}`)}`
                  : !!tile.href
                  ? `#${tile.href}`
                  : undefined
              }
              target={tile.externalUrl ? '_blank' : undefined}
            >
              <div
                className={cx(
                  css.tile,
                  tile.externalUrl || tile.url || tile.href ? css.clickable : ''
                )}
              >
                {tile.img ? <tile.img className={css.img} /> : null}
                <h3 className={css.title}>{tile.title}</h3>
                {typeof tile.text === typeof 'string' ? (
                  <p className={css.text}>{tile.text}</p>
                ) : (
                  (tile.text as string[]).map((tTxt, tTxtIndex) => (
                    <p key={tTxtIndex} className={css.text}>
                      {tTxt}
                    </p>
                  ))
                )}
              </div>
            </a>
          ))}
        </div>
      </main>
      <main className={cx(css.main, css.mobile)}>
        <div className={cx(css.tiles, css.mt50)}>
          {tiles.tiles.map((tile, tileIndex) => (
            <a
              key={`articleTile_${tile.title}_${tileIndex}`}
              className={css.tileWrapper}
              href={
                tile.externalUrl
                  ? tile.externalUrl
                  : tile.url
                  ? `${getPath(`${translate.i18n.ROUTES.solutions}/${tile.url}`)}`
                  : !!tile.href
                  ? `#${tile.href}`
                  : undefined
              }
              target={tile.externalUrl ? '_blank' : undefined}
            >
              <div
                className={`${css.tile} ${
                  tile.externalUrl || tile.url || tile.href ? css.clickable : ''
                }`}
              >
                {tile.img ? <tile.img className={`${css.img}`} /> : null}
                <h5 className={`${css.title}`}>{tile.title}</h5>
                {typeof tile.text === typeof 'string' ? (
                  <p className={`${css.text}`}>{tile.text}</p>
                ) : (
                  (tile.text as string[]).map((tTxt, tTxtIndex) => {
                    return (
                      <p key={tTxtIndex} className={`${css.text}`}>
                        {tTxt}
                      </p>
                    )
                  })
                )}
              </div>
            </a>
          ))}
        </div>
      </main>
      <footer className={css.footer}>
        {button ? <Button {...button} className={`${css.button}`} /> : null}
        {link ? <Link {...link} /> : null}
      </footer>
    </div>
  )
}
