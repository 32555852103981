// .core
import { useEffect } from 'react'
//lib
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent.js'
import './CookieConsent.css'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'

export default function CookieConsent(): null {
  useEffect(() => {
    // @ts-ignore
    const cc = window.initCookieConsent()

    // @ts-ignore
    cc.run({
      autorun: true,
      current_lang: 'en',
      autoclear_cookies: true,
      page_scripts: true,
      gui_options: {
        consent_modal: {
          layout: 'cloud', // box/cloud/bar
          position: 'bottom center', // bottom/middle/top + left/right/center
          transition: 'zoom', // zoom/slide
          swap_buttons: false, // enable to invert buttons
        },
        settings_modal: {
          layout: 'box', // box/bar
          // position: 'left',           // left/right
          transition: 'slide', // zoom/slide
        },
      },
      revision: 1,
      languages: {
        en: {
          consent_modal: {
            title: 'Consent to the use of cookies',
            description: `<p>When you click “Accept”, you allow us to collect fundamental analytics that help us improve our site in order to provide you with better content and services. Read our <a href='/terms-of-use-and-personal-data-processing' target="_blank">Privacy Policy</a>. Here you can manage cookie <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="cc-link"> settings</button>.`,

            primary_btn: {
              text: 'Accept',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept',
            // reject_all_btn: 'Accept necessary',
            close_btn_label: 'Close',
            cookie_table_headers: [{ col1: 'Name' }, { col2: 'Description' }],
            blocks: [
              {
                title: 'Cookies usage',
                description:
                  'By accepting cookies you allow us to collect analytic data that help us improve our site in order to provide you with better content and services.',
              },
              {
                title: 'Strictly necessary cookies',
                description:
                  'These cookies are necessary for the functionality of the website and do not store any personal data',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Performance and Analytics cookies',
                description:
                  'These cookies collect anonymous information and provide a way for us to improve the functioning of the website and help improve its usage',
                toggle: {
                  value: 'analytics', // your cookie category
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: 'Marketing cookies',
                description:
                  'These cookies provide us with deeper analytics and allow us to combine various data to reach out to you with more information about Webswing beyond our website.',
                toggle: {
                  value: 'marketing', // your cookie category
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
      },
    })

    document.body.classList.toggle('cc_ambitas')
  }, [])

  return null
}
