// .core
import React from 'react'

export const MM = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Machines-maintanence"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M18.4679,10.0535 L18.4679,20.6135 C18.4679,21.5280093 19.209608,22.2695 20.1249,22.2695 C20.9920187,22.2695 21.7033446,21.604007 21.7758191,20.7563755 L21.7819,20.6135 L21.7819,10.0535 L23.1819,10.0535 L23.1819,20.6135 C23.1819,22.0601856 22.176397,23.2720632 20.8258623,23.5887898 L20.825885,28.0687788 C21.8259983,28.3693926 22.5545,29.2971964 22.5545,30.3953 C22.5545,31.7376166 21.4673822,32.8243 20.1255,32.8243 C18.7828348,32.8243 17.6955,31.7378337 17.6955,30.3953 C17.6955,29.2970186 18.4241472,28.3693281 19.4247426,28.0687612 L19.4249308,23.5890225 C18.1301752,23.2858359 17.1523112,22.1599708 17.0730892,20.793065 L17.0679,20.6135 L17.0679,10.0535 L18.4679,10.0535 Z M20.1255,29.3663 C19.5561914,29.3663 19.0955,29.8268074 19.0955,30.3953 C19.0955,30.9643845 19.5557831,31.4243 20.1255,31.4243 C20.6943088,31.4243 21.1545,30.9642927 21.1545,30.3953 C21.1545,29.8268993 20.6939007,29.3663 20.1255,29.3663 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <path
        d="M30.7559414,7.75725111 C32.1522883,10.0205192 32.9032,12.628397 32.9032,15.3448 C32.9032,17.9094803 32.2333436,20.3780996 30.9826613,22.5510053 L30.7559414,22.9313489 L29.5644586,22.1962511 C30.8254137,20.1524328 31.5032,17.7987585 31.5032,15.3448 C31.5032,13.0343705 30.9028633,10.8135243 29.7809169,8.85621382 L29.5644586,8.49234889 L30.7559414,7.75725111 Z M9.4941586,7.75725111 L10.6856414,8.49234889 C9.42462385,10.5362684 8.7469,12.8899687 8.7469,15.3448 C8.7469,17.654408 9.34729203,19.8751829 10.4691928,21.8324034 L10.6856414,22.1962511 L9.4941586,22.9313489 C8.09788092,20.668193 7.3469,18.0603438 7.3469,15.3448 C7.3469,12.7793083 8.01669468,10.3106184 9.26742845,8.13761281 L9.4941586,7.75725111 Z M27.8470999,9.7198782 C28.7742384,11.4326905 29.2665,13.3532687 29.2665,15.3441 C29.2665,17.2022092 28.8376855,18.9991202 28.0267122,20.6230299 L27.8470999,20.9683218 L26.6159001,20.3018782 C27.4328974,18.7925428 27.8665,17.1008248 27.8665,15.3441 C27.8665,13.7128555 27.4926284,12.1376628 26.7850844,10.7124996 L26.6159001,10.3863218 L27.8470999,9.7198782 Z M12.4030001,9.7198782 L13.6341999,10.3863218 C12.8172026,11.8956572 12.3836,13.5873752 12.3836,15.3441 C12.3836,16.9753445 12.7574716,18.5505372 13.4650156,19.9757004 L13.6341999,20.3018782 L12.4030001,20.9683218 C11.4758616,19.2555095 10.9836,17.3349313 10.9836,15.3441 C10.9836,13.4859908 11.4124145,11.6890798 12.2233878,10.0651701 L12.4030001,9.7198782 Z"
        id="Combined-Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
