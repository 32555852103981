// .core
import React from 'react'

export const HM = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Herd-monitoring"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M20.1248,6.2622 C27.6430014,6.2622 33.8248,10.1762442 33.8248,15.1932 C33.8248,15.2002135 33.8247872,15.2072263 33.8247618,15.2142382 L33.8246,20.5542 L33.774,20.554 L33.7411693,20.7779271 C33.1599299,24.3179643 29.4524946,27.231205 24.3644265,28.2999054 L24.037798,28.3656036 L23.773402,26.9907964 C28.7773398,26.0284644 32.2384637,23.2038098 32.4138718,20.0531084 L32.4206352,19.8471814 L32.424,19.428 L32.424,19.154 L32.3609842,19.2391927 C30.0963355,22.1630214 25.4449978,24.1232 20.1246,24.1232 C14.8046672,24.1232 10.1531363,22.1629172 7.88833501,19.2391014 L7.824,19.154 L7.824,19.352 L7.82956477,19.8471776 C7.86129337,23.0097466 11.2123127,25.880055 16.1432389,26.923405 L16.4670431,26.9888436 L16.2021569,28.3635564 C10.951632,27.3518602 7.10189092,24.3911104 6.50894714,20.7774813 L6.477,20.554 L6.4246,20.5542 L6.4246,15.1932 L6.4291718,14.9624416 C6.6153255,10.0567385 12.7222633,6.2622 20.1248,6.2622 Z M20.1248,12.2712 C14.7254206,12.2712 10.049713,14.450596 8.42829849,17.5040654 C10.0267347,20.4935717 14.629965,22.7232 20.1246,22.7232 C25.6197589,22.7232 30.2226715,20.4937197 31.8214888,17.5050377 C30.199887,14.450596 25.5241794,12.2712 20.1248,12.2712 Z M20.1248,7.6622 C13.28305,7.6622 7.8248,11.1181249 7.8248,15.1932 C7.8248,15.3619994 7.83456759,15.5306201 7.8539935,15.6989723 L7.8539935,15.6989723 L7.868,15.799 L7.96929116,15.6671058 C10.2279295,12.8249696 14.7083266,10.9615179 19.7545764,10.8743933 L19.7545764,10.8743933 L20.1248,10.8712 C25.3213957,10.8712 29.9665818,12.7556492 32.2803088,15.6671058 L32.2803088,15.6671058 L32.381,15.798 L32.3940198,15.7205313 C32.4050547,15.6255841 32.4131363,15.5302221 32.4182105,15.4344829 L32.4236,15.2142 L32.4246,15.1932 C32.4248,11.1181249 26.96655,7.6622 20.1248,7.6622 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <path
        d="M22.8712221,24.4745 L17.3789779,24.4745 L14.6328115,29.2304569 L17.3789281,33.9875 L22.8712719,33.9875 L25.6173885,29.2304569 L22.8712221,24.4745 Z M22.062,25.874 L24,29.23 L22.062,32.587 L18.187,32.587 L16.249,29.23 L18.187,25.874 L22.062,25.874 Z"
        id="Stroke-9"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
