// screens
import ambienceCover from 'assets/images/herosection/ambience-cover.webp'
import dssCover from 'assets/images/herosection/dss-cover.webp'
import iot1 from '../../assets/images/herosection/iot 1 screen.webp'
import iot2 from '../../assets/images/herosection/iot 2 screen.webp'
import iot3 from '../../assets/images/herosection/iot 3 screen.webp'
import okt1 from '../../assets/images/herosection/oktagon 1 screen.webp'
import merchYouScreen1 from '../../assets/images/herosection/merchyou 1 screen.webp'
import ppec1 from '../../assets/images/herosection/ppec 1 screen.webp'
import ppec2 from '../../assets/images/herosection/ppec 2 screen.webp'
import substitutes1 from '../../assets/images/herosection/nahradnici 1 screen.webp'
import substitutes2 from '../../assets/images/herosection/nahradnici 2 screen.webp'
import nudchscreen1 from '../../assets/images/herosection/nudch 1 screen.webp'
import nudchscreen2 from '../../assets/images/herosection/nudch 2 screen.webp'
import nudchscreen3 from '../../assets/images/herosection/nudch 3 screen.webp'
import csobscreen1 from '../../assets/images/herosection/csob 1 screen.webp'
import csobscreen2 from '../../assets/images/herosection/csob 2 screen.webp'
import csobscreen3 from '../../assets/images/herosection/csob 3 screen.webp'
import ibdscreen1 from '../../assets/images/herosection/ibd 1 screen.webp'
import ibdscreen2 from '../../assets/images/herosection/ibd 2 screen.webp'
import ibdscreen3 from '../../assets/images/herosection/ibd 3 screen.webp'
import telekomscreen1 from '../../assets/images/herosection/telecom 1 screen.webp'
import telekomscreen2 from '../../assets/images/herosection/telecom 2 screen.webp'
import telekomscreen3 from '../../assets/images/herosection/telecom 3 screen.webp'
import samsungscreen1 from '../../assets/images/herosection/samsung 1 screen.webp'
import samsungscreen2 from '../../assets/images/herosection/samsung 2 screen.webp'
import samsungscreen3 from '../../assets/images/herosection/samsung 3 screen.webp'
import slovaktualscreen1 from '../../assets/images/herosection/slovaktual 1 screen.webp'
import slovaktualscreen2 from '../../assets/images/herosection/slovaktual 2 screen.webp'
import slovaktualscreen3 from '../../assets/images/herosection/slovaktual 3 screen.webp'
import o2screen1 from '../../assets/images/herosection/o2 1 screen.webp'
import o2screen2 from '../../assets/images/herosection/o2 2 screen.webp'
import webswingscreen1 from '../../assets/images/herosection/webswing.webp'
import tasrcreen1 from '../../assets/images/herosection/webreport-02.webp'
import ravaltradescreen1 from '../../assets/images/herosection/raval trade.webp'
import bauholdingscreen1 from '../../assets/images/herosection/bauholding-04.webp'
import arisanscreen1 from '../../assets/images/herosection/arisan-04.webp'
import terminyscreen from '../../assets/images/herosection/terminyorg.webp'
import velesFarmingTower from '../../assets/images/herosection/veles_tower_front.webp'
import velesFarmingScreen from '../../assets/images/herosection/veles-farming-screen.webp'
import vfs2Cover from '../../assets/images/herosection/vfs2-cover.webp'

// refs
import ambienceSensor from '../../assets/images/herosection/ambience-sensor.webp'
import ambienceSensorExploded from '../../assets/images/herosection/ambience-sensor-exploded.webp'
import craneAiRender from '../../assets/images/herosection/craneai-render.webp'
import ambienceUi from '../../assets/images/herosection/ambience-ui.webp'
import iotscreen1 from '../../assets/images/screenshots/BauHolding/iot-screen-8.webp'
import iotscreen2 from '../../assets/images/screenshots/BauHolding/iot-screen-9.webp'
import iotscreen3 from '../../assets/images/screenshots/BauHolding/iot-screen-10.webp'
import ibd1 from 'assets/images/screenshots/IBD/ibd content 1.webp'
import ibd2 from 'assets/images/screenshots/IBD/ibd content 2.webp'
import ibd3 from 'assets/images/screenshots/IBD/ibd content 3.webp'
import nudch1 from 'assets/images/screenshots/nudch/nudch content 1.webp'
import nudch2 from 'assets/images/screenshots/nudch/nudch content 2.webp'
import nudch3 from 'assets/images/screenshots/nudch/nudch content 3.webp'
import csob1 from 'assets/images/screenshots/csob/csob-03.webp'
import csob2 from 'assets/images/screenshots/csob/csob-00-home.webp'
import csob3 from 'assets/images/screenshots/csob/csob-05.webp'
import o21 from 'assets/images/screenshots/O2/o2-02.webp'
import o22 from 'assets/images/screenshots/O2/o2-03.webp'
import o23 from 'assets/images/screenshots/O2/o2-05.webp'
import my1 from 'assets/images/screenshots/Merchyou/merchyou 1 content.webp'
import my2 from 'assets/images/screenshots/Merchyou/merchyou 2 content.webp'
import my3 from 'assets/images/screenshots/Merchyou/merchyou 3 content.webp'
import tk1 from 'assets/images/screenshots/telekom/Telekom_1.webp'
import tk2 from 'assets/images/screenshots/telekom/telekom_2.webp'
import tk3 from 'assets/images/screenshots/telekom/telekom_3.webp'
import ppeDeal from 'assets/images/screenshots/PPE/ppec_deal_detail.webp'
import ppeUser from 'assets/images/screenshots/PPE/ppec_user_detail.webp'
import ppeReg from 'assets/images/screenshots/PPE/ppec_registration.webp'
import sama1 from 'assets/images/screenshots/SAMA/slovaktual-02.webp'
import sama2 from 'assets/images/screenshots/SAMA/slovaktual-03.webp'
import sama3 from 'assets/images/screenshots/SAMA/slovaktual-04.webp'
import smsng1 from 'assets/images/screenshots/Samsung/samsung-02.webp'
import smsng2 from 'assets/images/screenshots/Samsung/samsung-03.webp'
import smsng3 from 'assets/images/screenshots/Samsung/samsung-07.webp'
import oktagon1 from 'assets/images/screenshots/Oktagon/oktagon 1 content.webp'
import oktagon2 from 'assets/images/screenshots/Oktagon/oktagon 2 content.webp'
import oktagon3 from 'assets/images/screenshots/Oktagon/oktagon 3 content.webp'
import ws1 from 'assets/images/screenshots/webswing/webswing-00-home.webp'
import ws2 from 'assets/images/screenshots/webswing/webswing-01.webp'
import ws3 from 'assets/images/screenshots/webswing/webswing-04.webp'
import bh1 from 'assets/images/screenshots/BauHolding/bauholding-02-min.webp'
import bh2 from 'assets/images/screenshots/BauHolding/bauholding-03-min.webp'
import bh3 from 'assets/images/screenshots/BauHolding/bauholding-04-min.webp'
import rt1 from 'assets/images/screenshots/raval trade/ravaltrade-00-home.webp'
import rt2 from 'assets/images/screenshots/raval trade/ravaltrade-04.webp'
import rt3 from 'assets/images/screenshots/raval trade/ravaltrade-07.webp'
import wr1 from 'assets/images/screenshots/Webreport/webreport-02-min.webp'
import wr2 from 'assets/images/screenshots/Webreport/webreport-03-min.webp'
import wr3 from 'assets/images/screenshots/Webreport/webreport-05-min.webp'
import oa1 from 'assets/images/screenshots/onlineAPP/arisan-02.webp'
import oa2 from 'assets/images/screenshots/onlineAPP/arisan-03.webp'
import oa3 from 'assets/images/screenshots/onlineAPP/arisan-04.webp'
import nahradnici1 from 'assets/images/screenshots/substitutes/nahradnici1.webp'
import nahradnici2 from 'assets/images/screenshots/substitutes/nahradnici2.webp'
import nahradnici3 from 'assets/images/screenshots/substitutes/nahradnici3.webp'
import sct1screen from 'assets/images/herosection/sct1.webp'
import sct2screen from 'assets/images/herosection/sct2.webp'
import sct3screen from 'assets/images/herosection/sct3.webp'
import sct1screenComb from 'assets/images/herosection/sct1_comb.webp'
import sct2screenComb from 'assets/images/herosection/sct2_comb.webp'
import sct3screenComb from 'assets/images/herosection/sct3_comb.webp'
import veles1 from 'assets/images/herosection/veles1.webp'
import veles2 from 'assets/images/herosection/veles2.webp'
import veles3 from 'assets/images/herosection/veles3.webp'
import vfs2_1 from 'assets/images/herosection/vfs2_1.webp'
import vfs2_2 from 'assets/images/herosection/vfs2_2.webp'
import vfs2_3 from 'assets/images/herosection/vfs2_3.webp'

// logos
import ambitasLogo from 'assets/images/logos/ambitas.svg'
import ambienceLogo from 'assets/images/logos/ambience.svg'
import bskLogo from 'assets/images/logos/bsk.svg'
import domsnov from 'assets/images/logos/dom snov.svg'
import merchyou from 'assets/images/logos/merchyou.svg'
import oktagon from 'assets/images/logos/oktagon.svg'
import nudch from 'assets/images/logos/nudch.svg'
import bbsk from 'assets/images/logos/BBSK.svg'
import csob from 'assets/images/logos/csob.svg'
import ppe from 'assets/images/logos/ppe.webp'
import telekom from 'assets/images/logos/telekom.svg'
import ibd from 'assets/images/logos/slovak ibd.svg'
import o2 from 'assets/images/logos/o2.svg'
import slovaktual from 'assets/images/logos/slovaktual.svg'
import samsung from 'assets/images/logos/samsung.svg'
import webswing from 'assets/images/logos/webswing.svg'
import bauholding from 'assets/images/logos/dom snov.svg'
import ravalTrade from 'assets/images/logos/raval trade.svg'
import webReport from 'assets/images/logos/tasr_webreport.svg'
import onlineApp from 'assets/images/logos/aaa.svg'
import starter from 'assets/images/logos/starter.svg'
import velesfarmingLogo from 'assets/images/logos/velesfarming.svg'
import yitLogo from 'assets/images/logos/yit.svg'

// other
import { translate } from 'i18n'
import { ICounter } from 'content/AboutUs/AboutUs'
import { TESTIMONIALS } from 'content/Testimonials/Testimonials'

export interface IHerosectionItem {
  externalUrl?: string
  url?: string
  subUrl?: string
  title: string
  seoTitle?: string
  subtitle?: string
  reference?: {
    references?: {
      text?: string | string[]
      title?: string
      subtitle?: string
    }[]
    imgs?: string[]
    key: string
    logo: string
  }
  tags?: { text: string; link: string }[] | string[]
  // Screens
  type:
    | 'iphone6'
    | 'samsung'
    | 'iphone12'
    | 'tablet'
    | 'macbook'
    | 'portrait'
    | 'render'
    | 'render-and-screen'
  screens: string[]
  // Video (shown in detail instead of screens)
  video?: string
  text: string
  details?: {
    duration: string
    team: string
  }
  results?: ICounter[]
  logo?: string
  testimonial?: {
    title: string
    subtitle: string
    text: string
    photo: string
  }
  techDocsUrl?: string
}

export const generateHerosection = (
  type?: 'engineering' | 'ambience' | 'software' | string,
  filterByKeys?: string[]
): IHerosectionItem[] => {
  const softwareSolutions: IHerosectionItem[] = [
    {
      ...translate.i18n.HEROSECTION.domsnov,
      screens: [iot1, iot2, iot3],
      type: 'iphone12',
      logo: domsnov,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.domsnov],
        imgs: [iotscreen1, iotscreen2, iotscreen3],
        key: 'domsnov',
        logo: domsnov,
      },
    },
    {
      ...translate.i18n.HEROSECTION.smartxcity,
      screens: [sct1screen, sct2screen, sct3screen],
      type: 'iphone12',
      logo: starter,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.sct],
        imgs: [sct1screenComb, sct2screenComb, sct3screenComb],
        key: 'smartxcity',
        logo: starter,
      },
    },
    {
      ...translate.i18n.HEROSECTION.slovaktual,
      screens: [slovaktualscreen1, slovaktualscreen2, slovaktualscreen3],
      type: 'iphone6',
      logo: slovaktual,
      reference: {
        references: [...translate.i18n.CASES.logisticSolutions.references.samaSlovaktual],
        imgs: [sama1, sama2, sama3],
        key: 'slovaktual',
        logo: slovaktual,
      },
      testimonial: TESTIMONIALS.slovaktual,
    },
    {
      ...translate.i18n.HEROSECTION.ravalTrade,
      screens: [ravaltradescreen1],
      type: 'macbook',
      logo: ravalTrade,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.ravalTrade],
        imgs: [rt1, rt2, rt3],
        key: 'ravalTrade',
        logo: ravalTrade,
      },
    },
    {
      ...translate.i18n.HEROSECTION.substitutes,
      screens: [substitutes1, substitutes2],
      type: 'tablet',
      logo: bbsk,
      reference: {
        imgs: [nahradnici1, nahradnici2, nahradnici3],
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.nahradnici],
        key: 'nahradnici',
        logo: bbsk,
      },
    },
    {
      ...translate.i18n.HEROSECTION.terminyorg,
      screens: [terminyscreen],
      type: 'macbook',
      reference: {
        imgs: [terminyscreen, terminyscreen, terminyscreen],
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.terminyorg],
        key: 'terminyorg',
        logo: ambitasLogo,
      },
    },
    {
      ...translate.i18n.HEROSECTION.ppec,
      screens: [ppec1, ppec2],
      type: 'tablet',
      logo: ppe,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.ppe],
        imgs: [ppeReg, ppeUser, ppeDeal],
        key: 'ppe',
        logo: ppe,
      },
    },
    {
      ...translate.i18n.HEROSECTION.oktagon,
      screens: [okt1],
      type: 'macbook',
      logo: oktagon,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.oktagon],
        imgs: [oktagon1, oktagon2, oktagon3],
        key: 'oktagon',
        logo: oktagon,
      },
    },
    {
      ...translate.i18n.HEROSECTION.webswing,
      screens: [webswingscreen1],
      type: 'macbook',
      logo: webswing,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.webSwing],
        imgs: [ws1, ws2, ws3],
        key: 'webswing',
        logo: webswing,
      },
      testimonial: TESTIMONIALS.webswing,
    },
    {
      ...translate.i18n.HEROSECTION.merchyou,
      screens: [merchYouScreen1],
      type: 'macbook',
      logo: merchyou,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.merchyou],
        imgs: [my1, my2, my3],
        key: 'merchyou',
        logo: merchyou,
      },
    },
    {
      ...translate.i18n.HEROSECTION.tasr,
      screens: [tasrcreen1],
      type: 'macbook',
      logo: webReport,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.webReport],
        imgs: [wr1, wr2, wr3],
        key: 'webReport',
        logo: webReport,
      },
    },
    {
      ...translate.i18n.HEROSECTION.arisan,
      screens: [arisanscreen1],
      type: 'macbook',
      logo: onlineApp,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.onlineApp],
        imgs: [oa1, oa2, oa3],
        key: 'onlineApp',
        logo: onlineApp,
      },
      testimonial: TESTIMONIALS.arisan,
    },
    {
      ...translate.i18n.HEROSECTION.bauholding,
      screens: [bauholdingscreen1],
      type: 'macbook',
      logo: bauholding,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.bauHolding],
        imgs: [bh1, bh2, bh3],
        key: 'bauholding',
        logo: bauholding,
      },
    },
    {
      ...translate.i18n.HEROSECTION.nudch,
      screens: [nudchscreen1, nudchscreen2, nudchscreen3],
      type: 'iphone12',
      logo: nudch,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.nudch],
        imgs: (translate.i18n.HEROSECTION.nudch as any).imgs || [nudch1, nudch2, nudch3],
        key: 'nudch',
        logo: nudch,
      },
      testimonial: TESTIMONIALS.nudch,
    },
    {
      screens: [ibdscreen1, ibdscreen2, ibdscreen3],
      ...translate.i18n.HEROSECTION.ibd,
      type: 'iphone12',
      logo: ibd,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.ibdDiary],
        imgs: (translate.i18n.HEROSECTION.ibd as any).imgs || [ibd1, ibd2, ibd3],
        key: 'ibd',
        logo: ibd,
      },
    },
    {
      ...translate.i18n.HEROSECTION.csob,
      screens: [csobscreen1, csobscreen2, csobscreen3],
      type: 'iphone12',
      logo: csob,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.csob],
        imgs: [csob1, csob2, csob3],
        key: 'csob',
        logo: csob,
      },
    },
    {
      ...translate.i18n.HEROSECTION.telekom,
      screens: [telekomscreen1, telekomscreen2, telekomscreen3],
      type: 'iphone12',
      logo: telekom,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.telekom],
        imgs: [tk1, tk2, tk3],
        key: 'telekom',
        logo: telekom,
      },
    },
    {
      ...translate.i18n.HEROSECTION.o2,
      screens: [o2screen1, o2screen2],
      type: 'tablet',
      logo: o2,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.o2],
        imgs: [o21, o22, o23],
        key: 'o2',
        logo: o2,
      },
    },
    {
      ...translate.i18n.HEROSECTION.samsung,
      screens: [samsungscreen1, samsungscreen2, samsungscreen3],
      type: 'samsung',
      logo: samsung,
      reference: {
        references: [...translate.i18n.CASES.customSoftwareSolutions.references.samsung],
        imgs: [smsng1, smsng2, smsng3],
        key: 'samsung',
        logo: samsung,
      },
    },
  ]

  const verticalFarmingSolutions: IHerosectionItem[] = [
    {
      ...translate.i18n.HEROSECTION.veles2,
      screens: [vfs2Cover],
      type: 'render',
      logo: velesfarmingLogo,
      reference: {
        key: 'veles-farming-20',
        logo: velesfarmingLogo,
        imgs: [vfs2_1, vfs2_2, vfs2_3],
        references: [...translate.i18n.CASES.engineering.references.velesFarming2],
      },
      testimonial: TESTIMONIALS.veles,
    },
    {
      ...translate.i18n.HEROSECTION.veles,
      screens: [velesFarmingTower, velesFarmingScreen],
      type: 'render-and-screen',
      logo: velesfarmingLogo,
      reference: {
        key: 'veles-farming',
        logo: velesfarmingLogo,
        imgs: [veles1, veles2, veles3],
        references: [...translate.i18n.CASES.engineering.references.velesFarming],
      },
      testimonial: TESTIMONIALS.veles,
    },
  ]

  const engineeringSolutions: IHerosectionItem[] = [
    ...verticalFarmingSolutions,
    {
      ...translate.i18n.HEROSECTION.dss,
      screens: [dssCover],
      type: 'render',
      logo: bskLogo,
      reference: {
        key: 'dss',
        logo: bskLogo,
        imgs: [ambienceSensorExploded, ambienceUi, ambienceSensor],
        references: [...translate.i18n.CASES.ambience.references.dss],
      },
      testimonial: TESTIMONIALS.dss,
    },
    {
      ...translate.i18n.HEROSECTION.craneAI,
      screens: [craneAiRender],
      type: 'render',
      logo: yitLogo,
      reference: {
        key: 'crane-ai',
        logo: yitLogo,
      },
    },
  ]

  const ambienceSolutions: IHerosectionItem[] = [
    type === 'ambience' && {
      ...translate.i18n.HEROSECTION.dss,
      screens: [dssCover],
      type: 'render',
      logo: bskLogo,
      reference: {
        key: 'dss',
        logo: bskLogo,
        imgs: [ambienceSensorExploded, ambienceUi, ambienceSensor],
        references: [...translate.i18n.CASES.ambience.references.dss],
      },
      testimonial: TESTIMONIALS.dss,
    },
    {
      ...translate.i18n.HEROSECTION.ambience,
      screens: [ambienceCover],
      type: 'render',
      logo: ambienceLogo,
      reference: {
        key: 'ambience',
        logo: ambienceLogo,
        imgs: [],
        references: [],
      },
    },
  ].filter(Boolean) as IHerosectionItem[]

  const allSolutions: IHerosectionItem[] = [
    ...verticalFarmingSolutions,
    ...engineeringSolutions,
    ...ambienceSolutions,
    ...softwareSolutions,
  ]

  if (filterByKeys instanceof Array) {
    return filterByKeys
      .map((key) => allSolutions.find((item) => item.reference && item.reference.key === key))
      .filter(Boolean) as IHerosectionItem[]
  }

  switch (type) {
    case 'ambience':
      return ambienceSolutions
    case 'engineering':
      return engineeringSolutions
    case 'software':
      return softwareSolutions
    case 'verticalfarming':
      return verticalFarmingSolutions
    default:
      return allSolutions
  }
}
