import React from 'react'

export const ECM = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-E-commerce"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Shape"
        fill="#288BA0"
        points="29.6252 26.0862 29.6252 27.4862 10.6252 27.4862 10.6252 26.0862"
      ></polygon>
      <path
        d="M20.945,5.452 C22.9471172,5.452 24.6241242,6.84839463 25.0541197,8.72006247 L27.3205,8.7203 C28.9800993,8.7203 30.3255,10.0657007 30.3255,11.7253 L30.3255,11.7253 L30.3255,29.7933 C30.3255,31.4528993 28.9800993,32.7983 27.3205,32.7983 L27.3205,32.7983 L12.9295,32.7983 C11.2699974,32.7983 9.9255,31.4529963 9.9255,29.7933 L9.9255,29.7933 L9.9255,11.7253 C9.9255,10.0656037 11.2699974,8.7203 12.9295,8.7203 L12.9295,8.7203 L15.1648336,8.72002241 C15.5795038,6.91424244 17.1544213,5.55097703 19.062653,5.45715918 L19.273,5.452 L20.945,5.452 Z M27.3205,10.1203 L25.1605,10.12 L25.161,15.733 L23.761,15.733 L23.7605,10.12 L16.4575,10.12 L16.458,15.733 L15.058,15.733 L15.0575,10.12 L12.9295,10.1203 C12.0926127,10.1203 11.4057841,10.7610188 11.3320528,11.5791724 L11.3255,11.7253 L11.3255,29.7933 C11.3255,30.6799854 12.043384,31.3983 12.9295,31.3983 L12.9295,31.3983 L27.3205,31.3983 C28.2069007,31.3983 28.9255,30.6797007 28.9255,29.7933 L28.9255,29.7933 L28.9255,11.7253 C28.9255,10.8388993 28.2069007,10.1203 27.3205,10.1203 L27.3205,10.1203 Z M20.945,6.852 L19.273,6.852 C18.0506505,6.852 17.0099858,7.63154629 16.6211522,8.72049261 L23.5977575,8.72059256 C23.2270404,7.68298698 22.2641756,6.92613091 21.116483,6.85713953 L20.945,6.852 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
    </g>
  </svg>
)
