// .core
import React from 'react'
// components
import { CaseItem } from '../../components/CaseItem/CaseItem'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
// content
import { CASES, ICase } from '../../content/Cases/Cases'
// styles
import css from './Solutions.module.scss'
// translate
import { translate } from 'i18n'

interface ISolutionsContentProps {
  onLoadLast: () => void
  onUnloadLast: () => void
}

export const SolutionsContent = ({ onLoadLast, onUnloadLast }: ISolutionsContentProps) => {
  const cases = React.useRef<ICase[]>(CASES)

  return (
    <div className={css.solutions}>
      <section className="container">
        <h1 className={css.title}>{translate.i18n.SOLUTIONS}</h1>
        <h5 className={css.subtitle}>{translate.i18n.SOLUTIONS_SUBTITLE}</h5>
      </section>
      <section className="container">
        {cases.current.map((caseItem, caseIndex) => {
          return (
            <CaseItem
              {...caseItem}
              animateFadeIn={!caseIndex}
              key={`case_${caseIndex}`}
              className="container"
              forceEnableRecalc={caseIndex === cases.current.length - 1}
              hideScrollbar={!caseIndex}
              swap={!(caseIndex % 2)}
            />
          )
        })}
        <ScrollBar
          onLoadThreshold={90}
          onUnloadThreshold={10}
          onLoad={onLoadLast}
          onUnload={onUnloadLast}
        />
      </section>
    </div>
  )
}
