import React from 'react'

export const VF = (props: { className: string }) => (
    <svg className={props.className || ''} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9324 27.8262H16.8819C16.0711 27.8262 15.3954 28.4149 15.2598 29.1873H16.3103C17.1209 29.1873 17.7968 28.5987 17.9324 27.8262Z" fill="white"/>
    <path d="M8.08984 33.2715H31.9101V34.6326H8.08984V33.2715Z" fill="#27849C"/>
    <path d="M23.1169 27.8262H22.0664C22.2022 28.5987 22.8779 29.1873 23.6885 29.1873H24.7391C24.6034 28.4149 23.9275 27.8262 23.1169 27.8262Z" fill="white"/>
    <path d="M28.823 27.8262H27.7725C26.9617 27.8262 26.286 28.4149 26.1504 29.1873H27.2009C28.0115 29.1873 28.6872 28.5987 28.823 27.8262Z" fill="white"/>
    <path d="M5.36719 4H6.76534V36H5.36719V4Z" fill="white"/>
    <g mask="url(#mask0_705_4)">
    <path d="M5.36719 35.9941H6.7283C6.7283 31.2154 6.7283 8.86718 6.7283 4.00684H5.36719V35.9941Z" fill="white"/>
    </g>
    <path d="M23.6885 6.04861H24.7391C24.6034 5.27604 23.9275 4.6875 23.1169 4.6875H22.0664C22.202 5.45988 22.8779 6.04861 23.6885 6.04861Z" fill="white"/>
    <path d="M28.823 4.6875H27.7725C26.9617 4.6875 26.286 5.27623 26.1504 6.04861H27.2009C28.0115 6.04861 28.6872 5.45988 28.823 4.6875Z" fill="white"/>
    <path d="M17.9324 4.6875H16.8819C16.0711 4.6875 15.3954 5.27623 15.2598 6.04861H16.3103C17.1209 6.04861 17.7968 5.45988 17.9324 4.6875Z" fill="white"/>
    <path d="M12.7998 6.04861H13.8502C13.7146 5.27604 13.0387 4.6875 12.2281 4.6875H11.1777C11.3133 5.45988 11.9891 6.04861 12.7998 6.04861Z" fill="white"/>
    <path d="M8.08984 21.7012H31.9101V23.0625H8.08984V21.7012Z" fill="#27849C"/>
    <path d="M33.2344 4H34.6264V36H33.2344V4Z" fill="white"/>
    <g mask="url(#mask1_705_4)">
    <path d="M33.2715 4.00684C33.2715 16.262 33.2715 26.725 33.2715 35.9941H34.6326V4.00684H33.2715Z" fill="white"/>
    </g>
    <path d="M8.08984 10.1318H31.9101V11.4929H8.08984V10.1318Z" fill="#27849C"/>
    <path d="M12.2281 16.2568H11.1777C11.3133 17.0294 11.9891 17.6181 12.7998 17.6181H13.8502C13.7146 16.8456 13.0388 16.2568 12.2281 16.2568Z" fill="white"/>
    <path d="M12.2281 27.8262H11.1777C11.3133 28.5987 11.9891 29.1873 12.7998 29.1873H13.8502C13.7146 28.4149 13.0388 27.8262 12.2281 27.8262Z" fill="white"/>
    <path d="M17.9324 16.2568H16.8819C16.0711 16.2568 15.3954 16.8456 15.2598 17.6181H16.3103C17.1209 17.6181 17.7968 17.0294 17.9324 16.2568Z" fill="white"/>
    <path d="M28.823 16.2568H27.7725C26.9617 16.2568 26.286 16.8456 26.1504 17.6181H27.2009C28.0115 17.6181 28.6872 17.0294 28.823 16.2568Z" fill="white"/>
    <path d="M23.1169 16.2568H22.0664C22.2022 17.0294 22.8779 17.6181 23.6885 17.6181H24.7391C24.6034 16.8456 23.9275 16.2568 23.1169 16.2568Z" fill="white"/>
    <path d="M13.8496 6.04785H15.2605V8.77007H13.8496V6.04785Z" fill="white"/>
    <path d="M24.7383 6.04785H26.1492V8.77007H24.7383V6.04785Z" fill="white"/>
    <path d="M13.8496 17.6182H15.2605V20.3404H13.8496V17.6182Z" fill="white"/>
    <path d="M24.7383 17.6182H26.1492V20.3404H24.7383V17.6182Z" fill="white"/>
    <path d="M13.8496 29.1875H15.2605V31.9097H13.8496V29.1875Z" fill="white"/>
    <path d="M24.7383 29.1875H26.1492V31.9097H24.7383V29.1875Z" fill="white"/>
    </svg>
    
)
