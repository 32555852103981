// .core
import React from 'react'

export const IoT = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g id="Icon-/-Outline-/-IoT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20.1249,6.0380257 L32.3249,13.0811387 L32.3249,27.1684613 L20.1249,34.2115743 L7.9249,27.1684613 L7.9249,13.0811387 L20.1249,6.0380257 Z M20.124,7.654 L9.324,13.889 L9.324,26.36 L20.124,32.595 L30.924,26.36 L30.9239,23.4040257 L23.98,23.404 L22.0756633,25.3096336 C22.1935088,25.5748391 22.259,25.8684173 22.259,26.1772 C22.259,27.3585694 21.3018292,28.3162 20.121,28.3162 C18.9394007,28.3162 17.982,27.3587993 17.982,26.1772 C17.982,24.9963305 18.9396707,24.0382 20.121,24.0382 C20.4819432,24.0382 20.82203,24.1277841 21.1202741,24.2859155 L23.4010505,22.0041 L30.9239,22.0040257 L30.9239,18.2450257 L26.8259734,18.2459 L24.165,15.584 L20.125,15.5849 L20.125,14.1849 L24.7460266,14.1849 L27.405,16.845 L30.9239,16.8450257 L30.924,13.889 L20.124,7.654 Z M20.121,25.4382 C19.7130198,25.4382 19.382,25.7693787 19.382,26.1772 C19.382,26.5856007 19.7125993,26.9162 20.121,26.9162 C20.52848,26.9162 20.859,26.5855212 20.859,26.1772 C20.859,25.7694583 20.5280596,25.4382 20.121,25.4382 Z"
        id="Combined-Shape"
        fill="#FFFFFF"
      ></path>
      <path
        d="M17.2431,17.986 C18.1784951,17.986 18.9744055,18.5876338 19.264668,19.4247382 L31.623,19.425 L31.623,20.825 L19.2646957,20.8255473 C18.9744944,21.6628245 18.1787092,22.264 17.2431,22.264 C16.0615007,22.264 15.1041,21.3065993 15.1041,20.125 C15.1041,18.9441305 16.0617707,17.986 17.2431,17.986 Z M17.2431,19.386 C16.8351198,19.386 16.5041,19.7171787 16.5041,20.125 C16.5041,20.5334007 16.8346993,20.864 17.2431,20.864 C17.6509213,20.864 17.9821,20.5329802 17.9821,20.125 C17.9821,19.7175993 17.6505007,19.386 17.2431,19.386 Z"
        id="Combined-Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
