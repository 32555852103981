// .core
import React, { useCallback, useEffect, useState } from 'react'
// components
import { Layout } from '../../layouts/Layout'
import { CaseStudyDetail } from './CaseStudyDetail'
// content
import { IHerosectionItem } from 'content/Herosection/Herosection'
// libraries
import cx from 'classnames'
import { useParams } from 'react-router'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './CaseStudy.module.scss'

interface IParams {
  localeName: string
  solutionUrl: string
  caseStudyUrl: string
}

interface ICaseStudyProps {
  caseStudy?: IHerosectionItem
}

export default function CaseStudy({ caseStudy }: ICaseStudyProps) {
  const params = useParams<IParams>()

  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const setAnimateFooterFalse = useCallback(() => {
    setShouldAnimateFooter(false)
  }, [])

  const setAnimateFooterTrue = useCallback(() => {
    setShouldAnimateFooter(true)
  }, [])

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'default'
    window.history.scrollRestoration = 'manual'
    window.scrollTo(0, 0)
    document.documentElement.style.scrollBehavior = 'smooth'
  }, [params.caseStudyUrl, params.solutionUrl])

  return caseStudy ? (
    <>
      <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
        <div className={cx('container', css.solution)}>
          <CaseStudyDetail
            {...caseStudy}
            onLoad={setAnimateFooterTrue}
            onUnload={setAnimateFooterFalse}
          />
        </div>
      </Layout>
    </>
  ) : null
}
