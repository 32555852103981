// .core
import React, { useCallback, useEffect, useState } from 'react'
// components
import Button from 'components/Button/Button'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { IButtonProps } from 'components/Button/Button'
import { Link } from 'components/Link/Link'
import { NavigationArrows } from 'components/NavigationArrows/NavigationArrows'
// libs
import cx from 'classnames'
import { Carousel } from 'react-responsive-carousel'
// @ts-ignore
import window from 'global/window'
// styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import css from './Articles.module.scss'
// utils
import { chunkify } from 'utils/functions'

export interface IArticle {
  /**
   * The title of the article.
   */
  title: string
  /**
   * The subtitle of the article.
   */
  subtitle?: string
  /**
   * The text of the article.
   */
  text?: string
  /**
   * Photo (preferably should be used as author photo).
   */
  photo?: string
}

export interface IArticlesProps {
  /**
   * Whether to show fadeIn animation or not.
   */
  animateIn?: boolean
  /**
   * Whether to show fadeOut animation or not.
   */
  animateOut?: boolean
  /**
   * All of the articles you want to display.
   */
  articles: IArticle[]
  button?: IButtonProps
  /**
   * ClassName for custom styling.
   */
  className?: string
  forceEnableRecalc?: boolean
  /**
   * Removes the scrollbar from the component.
   */
  hideScrollbar?: boolean
  initRecalc?: boolean
  /**
   * If articles should be swipeable on overflow or not.
   * NOTE: this does not change the default behaviour of grouping
   * the articles into the groups of 3 (lg, xl devices) and 1 (sm, md devices).
   */
  swipeable?: boolean
  /**
   * Main text (description for articles).
   */
  text?: string
  /**
   * Whether should be shown link to about-us page's section Ambitas in media.
   */
  link?: React.HTMLProps<HTMLAnchorElement>
  /**
   * Title of all articles.
   */
  title?: string
  /**
   * OnLoad callback (after reaching 90% or more).
   */
  onLoad?: () => void
  /**
   * OnUnload callback (after reaching 10% or less).
   */
  onUnLoad?: () => void
}

export default function Articles({
  articles,
  animateIn,
  animateOut,
  button,
  className,
  forceEnableRecalc,
  hideScrollbar,
  initRecalc,
  swipeable,
  text,
  title,
  link,
  onLoad,
  onUnLoad,
}: IArticlesProps) {
  const [currentSwipeIndex, setCurrentSwipeIndex] = useState<number>(0)
  const [itemsPerGroup, setItemsPerGroup] = useState<number>(window.innerWidth < 992 ? 1 : 3)

  const updateItemsPerGroup = () => {
    setItemsPerGroup(window.innerWidth < 992 ? 1 : 3)

    if (Math.floor(articles.length / itemsPerGroup) <= currentSwipeIndex) {
      setCurrentSwipeIndex(Math.floor(articles.length / itemsPerGroup))
    }
  }

  const onSwipeNext = useCallback(() => {
    setCurrentSwipeIndex((prev) => prev + 1)
  }, [])

  const onSwipePrev = useCallback(() => {
    setCurrentSwipeIndex((prev) => prev - 1)
  }, [])

  const swipeCards = chunkify<JSX.Element>(
    articles.map((article, articleIndex) => {
      let delayFactor = 0
      const rowIndex = Math.floor(articleIndex / itemsPerGroup),
        colIndex = articleIndex % itemsPerGroup

      if (swipeable) delayFactor = articleIndex
      else if (rowIndex == 0 || colIndex == 0) delayFactor = 1
      else if (rowIndex == 1 || colIndex == 1) delayFactor = 2
      else if (rowIndex == 2 || colIndex == 2) delayFactor = 3

      return (
        <div
          key={`ARTICLE_${article.title}_${articleIndex}`}
          className={cx(css.article, 'col-lg-4 col-md-8 col-sm-12')}
          style={{ transitionDelay: `${(delayFactor + (title ? 1 : 0)) * 0.3}s` }}
        >
          <header className={cx(css.header, article.photo && css.hasImg)}>
            {article.photo ? (
              <img
                alt={`${article.title} - ${article.subtitle}`}
                className={css.img}
                src={article.photo}
              />
            ) : null}
            <div className={css.headerContent}>
              {article.title ? <span className={css.title}>{article.title}</span> : null}
              {article.subtitle ? <span className={css.subtitle}>{article.subtitle}</span> : null}
            </div>
          </header>
          {article.text ? (
            <main className={css.main}>
              <p className={css.text}>{article.text}</p>
            </main>
          ) : null}
        </div>
      )
    }),
    itemsPerGroup
  ).map((group, groupIndex) => (
    <div key={`swipeCard_${groupIndex}`} className={css.swipeCard}>
      {group}
    </div>
  ))

  useEffect(() => {
    updateItemsPerGroup()
    window.addEventListener('resize', updateItemsPerGroup)

    return () => window.removeEventListener('resize', updateItemsPerGroup)
  }, [])

  return (
    <>
      <div
        className={`${css.articles} ${
          animateIn ? css.animateFadeIn : animateOut ? css.animateFadeOut : ''
        } ${className || ''}`}
      >
        {title ? <h2 className={css.title}>{title}</h2> : null}
        {text ? <span className={css.text}>{text}</span> : null}
        {swipeable ? (
          <>
            <Carousel
              emulateTouch
              swipeable
              infiniteLoop={false}
              selectedItem={currentSwipeIndex}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              onChange={(index) => setCurrentSwipeIndex(index)}
            >
              {swipeCards}
            </Carousel>
            <NavigationArrows
              currentPage={currentSwipeIndex}
              numberOfPages={swipeCards.length}
              onNext={onSwipeNext}
              onPrev={onSwipePrev}
            />
          </>
        ) : (
          swipeCards
        )}
        {button ? <Button className={cx(css.button, 'mt-5')} {...button} /> : null}
        {link ? (
          <div className="d-flex justify-content-center mt-2">
            <Link {...link} />
          </div>
        ) : null}
      </div>
      {!hideScrollbar ? (
        <ScrollBar
          initRecalc={initRecalc}
          forceEnableRecalc={forceEnableRecalc}
          onLoadThreshold={90}
          onUnloadThreshold={10}
          onLoad={onLoad}
          onUnload={onUnLoad}
        />
      ) : null}
    </>
  )
}
