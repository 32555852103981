import React from 'react'

export const CRM = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g id="Icon-/-Outline-/-CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M30.821,8.5327 C32.4798009,8.5327 33.825,9.87830231 33.825,11.5377 L33.825,11.5377 L33.825,28.7127 C33.825,30.3715009 32.4793977,31.7167 30.82,31.7167 L30.82,31.7167 L9.429,31.7167 C7.77040068,31.7167 6.425,30.3712993 6.425,28.7127 L6.425,28.7127 L6.425,11.5377 C6.425,9.87810068 7.77040068,8.5327 9.43,8.5327 L9.43,8.5327 Z M12.489,9.9327 L9.43,9.9327 C8.54359932,9.9327 7.825,10.6512993 7.825,11.5377 L7.825,11.5377 L7.825,28.7127 C7.825,29.5981007 8.54359932,30.3167 9.429,30.3167 L9.429,30.3167 L12.489,30.3167 L12.489,9.9327 Z M30.821,9.9327 L13.889,9.9327 L13.889,30.3167 L30.82,30.3167 C31.6570545,30.3167 32.3446264,29.6758232 32.4184398,28.8586437 L32.425,28.7127 L32.425,11.5377 C32.425,10.651407 31.7065083,9.9327 30.821,9.9327 L30.821,9.9327 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <path
        d="M18.5862,17.8531 C19.1832,17.8531 19.7382,18.0711 20.1862,18.5191 L19.6382,19.0481 C19.3462,18.7681 18.9662,18.6371 18.5862,18.6371 C17.5282,18.6371 17.0862,19.4221 17.0862,20.1501 C17.0862,20.8721 17.5152,21.6251 18.5862,21.6251 C18.9662,21.6251 19.3892,21.4761 19.6812,21.1841 L20.2422,21.7501 C19.7942,22.1981 19.2152,22.3971 18.5862,22.3971 C16.9682,22.3971 16.2762,21.2771 16.2762,20.1501 C16.2762,19.0111 17.0052,17.8531 18.5862,17.8531 Z M26.3713,17.9467 L27.7343,19.8207 L29.0983,17.9467 L30.0383,17.9467 L30.0383,22.3047 L29.2163,22.3047 L29.2163,19.1417 L27.7973,21.0347 L27.6343,21.0347 L26.2463,19.1357 L26.2463,22.3047 L25.4243,22.3047 L25.4243,17.9467 L26.3713,17.9467 Z M20.8874,17.9337 C21.5844,17.9337 22.2634,17.9397 22.9604,17.9397 C23.9884,17.9397 24.5294,18.6377 24.5294,19.3847 C24.5294,19.9817 24.2554,20.5857 23.4274,20.7537 L24.7474,22.2417 L24.7474,22.2977 L23.8074,22.2977 L22.5434,20.8407 L21.7094,20.8407 L21.7094,22.2977 L20.8874,22.2977 L20.8874,17.9337 Z M22.9604,18.7057 L21.7094,18.7057 L21.7094,20.1007 L22.9604,20.1007 C23.4834,20.1007 23.7084,19.7517 23.7084,19.4027 C23.7084,19.0547 23.4774,18.7057 22.9604,18.7057 Z"
        id="Combined-Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
