// .core
import React from 'react'
// components
import { Icon } from 'components/Icon/Icon'
// content
import { generateMedia } from 'content/Media/Media'
// styles
import css from './Media.module.scss'
// translations
import { translate } from 'i18n'
import Button, { IButtonProps } from 'components/Button/Button'

export interface IMediaProps {
  animate?: boolean
  /**
   * Footer button props.
   */
  button?: IButtonProps
}

export default class Media extends React.Component<IMediaProps> {
  render() {
    const { animate, button } = this.props

    return (
      <div className={`${css.mediaWrapper}`}>
        <h2
          className={`${css.heading} ${
            animate ? css.animateIn : animate === false ? css.animateOut : ''
          }`}
        >
          {translate.i18n.IN_MEDIA}
        </h2>
        <div className={`${css.cardsWrapper}`}>
          {generateMedia().map((media, mediaIndex) => {
            return (
              <React.Fragment key={`mediaCard_${mediaIndex}`}>
                <a className={`${css.link}`} href={media.url} target={'_blank'}>
                  <div
                    className={`${css.mediaCard} ${
                      animate ? css.animateIn : animate === false ? css.animateOut : ''
                    }`}
                  >
                    <img className={`${css.logo}`} src={`${media.logo}`} alt={''} />
                    <h4 className={`${css.title}`}>"{media.title}"</h4>
                    {media.url ? (
                      <span className={`${css.link}`}>
                        {translate.i18n.READ_MORE}
                        <Icon name="arrowRight" className={`${css.icon}`} />
                      </span>
                    ) : null}
                  </div>
                </a>
              </React.Fragment>
            )
          })}
        </div>

        {button ? (
          <div className="d-flex flex-column align-items-center mt-5">
            <Button {...button} />
          </div>
        ) : null}
      </div>
    )
  }
}
