// .core
import React from 'react'
// components
import { ScrollBar } from '../ScrollBar/ScrollBar'
// libraries
import cx from 'classnames'
// styles
import css from './Article.module.scss'

export interface IArticleProps {
  /**
   * Whether to show fadeIn animation or not.
   */
  animateIn?: boolean
  /**
   * Whether to show fadeOut animation or not (can be overwrited by the animateFadeIn).
   */
  animateOut?: boolean
  /**
   * Whether text should be bigger.
   */
  biggerText?: boolean
  /**
   * Hides scrollbar, which means that onLoad and onUnload events wont work.
   */
  hideScrollbar?: boolean
  /**
   * Custom img src.
   */
  img?: string
  /**
   * Significant article has custom styling constisting of a text with bigger font size and line height.
   */
  significant?: boolean
  /**
   * Custom threshold for onUnload callback.
   * NOTE: 10 by default.
   */
  onUnloadThreshold?: number
  /**
   * The text of the article.
   */
  text?: string
  /**
   * The title of the article.
   */
  title?: string
  /**
   * Callback for onLoad (by default after reaching 90%).
   */
  onLoad?: () => void
  /**
   * Callback for onUnload (by default after reaching 10%, value can be overwritten with onUnloadThreshold).
   */
  onUnload?: () => void
}

export function Article({
  animateIn,
  animateOut,
  hideScrollbar,
  img,
  significant,
  title,
  biggerText,
  text,
  onUnloadThreshold,
  onLoad,
  onUnload,
}: IArticleProps) {
  return title || text ? (
    <div
      className={cx(
        css.article,
        animateIn && css.animateIn,
        animateOut && css.animateOut,
        significant && css.significant
      )}
    >
      {title ? <h2 className={css.title} dangerouslySetInnerHTML={{ __html: title }} /> : null}
      {text ? (
        <p
          className={cx(css.text, biggerText && css.bigger)}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : null}
      {img ? <img className={css.img} src={img} /> : null}

      {!hideScrollbar && (
        <ScrollBar
          onLoadThreshold={90}
          onUnloadThreshold={onUnloadThreshold || 10}
          onLoad={onLoad}
          onUnload={onUnload}
        />
      )}
    </div>
  ) : null
}
