// .core
import React, { useCallback, useMemo, useState } from 'react'
// assets
import ErrorImg from '../../assets/images/error.svg'
// config
import { API_URL } from 'utils/constants'
// content
import { CASES } from 'content/Cases/Cases'
// components
import Button from 'components/Button/Button'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { ServiceSelect } from 'components/ServiceSelect/ServiceSelect'
// libraries
import axios from 'axios'
import cx from 'classnames'
import { Formik, Form, Field } from 'formik'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as Yup from 'yup'
// styles
import css from './ContactForm.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { getPath } from 'utils/functions'

export interface IApiResponse {
  /**
   * If the API response has errors or not.
   */
  error: boolean
  /**
   * The status of the response.
   */
  status: string
  /**
   * Response message.
   */
  message: string
  /**
   * Array of fields that has errors.
   */
  error_fields?: string[]
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  company: Yup.string().required(),
  email: Yup.string().email().required(),
  message: Yup.string().required(),
  industry: Yup.array().required(),
})

export interface IContactFormProps {
  /**
   * ClassName for custom wrapper styling.
   */
  className?: string
}
export const TextArea = (props: any) => {
  return (
    <Field
      {...props}
      render={({ field }: { field: any }) => {
        return (
          <textarea
            {...field}
            className={props.className}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        )
      }}
    />
  )
}

export const ContactForm = ({ className }: IContactFormProps) => {
  const history = useHistory()

  const match = useRouteMatch<any>()

  const [error, setError] = useState<boolean>(false)

  const handleSubmit = useCallback((data: Record<string, any>) => {
    data.industry = data.industry.join(', ')
    data.name = encodeURI(data.name)
    data.message = encodeURI(data.message)
    data.company = encodeURI(data.company)

    /**
     * @returns Whether the response object contains necessary fields
     */
    const checkResponseValidity = (resObj: any) =>
      ['error', 'status', 'message'].every((key) => resObj[key] !== undefined)

    return axios
      .post(`${API_URL}/ambitas-org/send-quick-touch`, data, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      })
      .then(({ data }) => {
        if (!checkResponseValidity(data)) {
          throw new Error('Response formatting error')
        }

        localStorage.setItem('apiResponse', JSON.stringify({ ...data }))

        return undefined
      })
      .catch(({ response }) => {
        const data: IApiResponse = response.data
        if (!checkResponseValidity(data) && data.error_fields && data.error_fields.length) {
          // TODO:
          return data.error_fields
        } else setError(true)
      })
      .finally(() => {
        setTimeout(() => {
          history.push(`/${translate.i18n.CONTACT_FORM.URL}`)
        }, 0)
      })
  }, [])

  const defaultIndustryValue = useMemo(() => {
    const matchedCase = CASES.find((c) =>
      match.path.startsWith(`/${translate.i18n.ROUTES.solutions}/${c.url}`)
    )

    const caseTitle = matchedCase
      ? matchedCase.formTitle || matchedCase.shortTitle || matchedCase.title
      : undefined

    return caseTitle ? [caseTitle] : []
  }, [match.path])

  return (
    <div id="contactForm" className={cx(css.contactForm, css.active, className)}>
      {!error ? (
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            company: '',
            email: '',
            message: '',
            industry: defaultIndustryValue,
            budget: 'N/A',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setSubmitting(true)

            handleSubmit({ ...values })
              .then((errors) => {
                if (errors) {
                  setErrors({
                    name: errors.includes('name') ? 'format err' : '',
                    company: errors.includes('company') ? 'format err' : '',
                    email: errors.includes('email') ? 'format err' : '',
                    message: errors.includes('message') ? 'format err' : '',
                    industry: errors.includes('industry') ? ['format err'] : [''],
                    budget: '',
                  })
                }
              })
              .finally(() => setSubmitting(false))
          }}
        >
          {({ values, errors, isSubmitting, isValid, setFieldValue, touched }) => {
            return (
              <Form className={css.form}>
                <div className={css.wrapper}>
                  <div className={css.multiCol}>
                    <p className={css.paragraph}>
                      <span>
                        <span className={cx(css.label, !!values.name && css.active)}>
                          {translate.i18n.CONTACT_FORM.NAME}
                        </span>
                        <Field
                          autoComplete="name"
                          name="name"
                          className={cx(css.input, errors.name && touched.name && css.error)}
                          placeholder={translate.i18n.CONTACT_FORM.NAME}
                          type="text"
                          value={values.name}
                        />
                      </span>
                    </p>
                    <p className={css.paragraph}>
                      <span>
                        <span className={cx(css.label, !!values.company && css.active)}>
                          {translate.i18n.CONTACT_FORM.COMPANY}
                        </span>
                        <Field
                          autoComplete="organization"
                          name="company"
                          className={cx(
                            css.input,
                            !!errors.company && touched.company && css.error
                          )}
                          placeholder={translate.i18n.CONTACT_FORM.COMPANY}
                          type="text"
                          value={values.company}
                        />
                      </span>
                    </p>
                    <p className={css.paragraph}>
                      <span>
                        <span className={cx(css.label, !!values.email && css.active)}>
                          {translate.i18n.CONTACT_FORM.EMAIL}
                        </span>
                        <Field
                          autoComplete="email"
                          name="email"
                          className={cx(
                            css.input,
                            !!errors.email && touched.email && css.error,
                            css.double
                          )}
                          placeholder={translate.i18n.CONTACT_FORM.EMAIL}
                          type="text"
                          value={values.email}
                        />
                      </span>
                    </p>
                  </div>
                  <div className={cx(css.paragraph, css.triple)}>
                    <ServiceSelect
                      selected={values.industry}
                      onChange={(val) => {
                        const newArr = [...values.industry]
                        if (!newArr.includes(val)) {
                          newArr.push(val)
                        } else {
                          newArr.splice(newArr.indexOf(val), 1)
                        }
                        setFieldValue('industry', newArr)
                      }}
                    />
                  </div>
                  <p className={cx(css.paragraph, css.triple)}>
                    <span className={css.disMw}>
                      <span className={cx(css.label, !!values.message.length && css.active)}>
                        {translate.i18n.CONTACT_FORM.MESSAGE}
                      </span>
                      <TextArea
                        name="message"
                        className={cx(
                          css.input,
                          css.tarea,
                          !!errors.message && touched.message && css.error
                        )}
                        placeholder={translate.i18n.CONTACT_FORM.MESSAGE}
                        type="text"
                        value={values.message}
                      />
                    </span>
                  </p>
                </div>
                <Button
                  className={cx(css.button, !isValid && css.disabled, 'mt-5 mb-4')}
                  loading={isSubmitting}
                  text={translate.i18n.CONTACT_FORM.SEND}
                  type="submit"
                />
                <p
                  className={css.notice}
                  dangerouslySetInnerHTML={{
                    __html: translate.i18n.GDPR.AGREEMENT(getPath(translate.i18n.GDPR.URL)),
                  }}
                />
                <ScrollBar />
              </Form>
            )
          }}
        </Formik>
      ) : (
        <img alt="" src={ErrorImg} />
      )}
    </div>
  )
}
