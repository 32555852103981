// .core
import React, { useEffect, useMemo, useRef, useState } from 'react'
// assets
import homeBackground from '../../assets/images/home-background.webp'
// components
import { AboutUs } from 'components/AboutUs/AboutUs'
import Articles from 'components/Articles/Articles'
import { ArticlesTiles } from 'components/ArticlesTiles/ArticlesTiles'
import Button from 'components/Button/Button'
import Herosection from 'components/Herosection/Herosection'
import { Link } from 'components/Link/Link'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { BlogItem, BlogItemPlaceholder, IBlog, IResponse as IBlogResponse } from 'pages/Blog/Blog'
// content
import { CASES } from 'content/Cases/Cases'
import { generateTestimonials } from 'content/Testimonials/Testimonials'
import { generateHerosection } from 'content/Herosection/Herosection'
// libraries
import Axios, { AxiosResponse } from 'axios'
import cx from 'classnames'
// @ts-ignore
import window from 'global/window'
// styles
import css from './Home.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { useAnimateHandler } from 'utils/hooks'
import { getPath, goTo, scrollToFooter } from 'utils/functions'

interface IHomeContentProps {
  onLoadLast: () => void
  onUnloadLast: () => void
}

export const HomeContent = ({ onLoadLast, onUnloadLast }: IHomeContentProps) => {
  const aboutUsRef = useRef<HTMLElement>(null)
  const blogsRef = useRef<HTMLElement>(null)
  const herosectionRef = useRef<HTMLElement>(null)
  const solutionsRef = useRef<HTMLElement>(null)
  const testimonialsRef = useRef<HTMLElement>(null)

  const [blogs, setBlogs] = useState<IBlog[]>([])
  const [isLoadingBlogs, setIsLoadingBlogs] = useState<boolean>(true)

  const {
    setShouldAnimateFalse: setShouldAnimateAboutUsFalse,
    setShouldAnimateTrue: setShouldAnimateAboutUsTrue,
    shouldAnimate: shouldAnimateAboutUs,
  } = useAnimateHandler()

  const {
    setShouldAnimateFalse: setShouldAnimateBlogsFalse,
    setShouldAnimateTrue: setShouldAnimateBlogsTrue,
    shouldAnimate: shouldAnimateBlogs,
  } = useAnimateHandler()

  const {
    setShouldAnimateFalse: setShouldAnimateHerosectionFalse,
    setShouldAnimateTrue: setShouldAnimateHerosectionTrue,
    shouldAnimate: shouldAnimateHerosection,
  } = useAnimateHandler()

  const {
    setShouldAnimateFalse: setShouldAnimateTestimonialsFalse,
    setShouldAnimateTrue: setShouldAnimateTestimonialsTrue,
    shouldAnimate: shouldAnimateTestimonials,
  } = useAnimateHandler()

  const solutionsTiles = useMemo(
    () => ({
      tiles: CASES.map((caseItem) => ({
        img: caseItem.icon,
        title: caseItem.shortTitle || caseItem.title,
        text: caseItem.shortText,
        url: caseItem.url,
        externalUrl: caseItem.externalUrl,
      })),
      title: translate.i18n.SOLUTIONS,
    }),

    []
  )

  const herosectionItems = useMemo(
    () =>
      generateHerosection(undefined, [
        'veles-farming-20',
        'veles-farming',
        'dss',
        'ambience',
        'crane-ai',
        'domsnov',
        'smartxcity',
        'slovaktual',
      ]),
    []
  )

  useEffect(() => {
    setIsLoadingBlogs(true)

    Axios.get<any, AxiosResponse<IBlogResponse>>(`https://www.ambitas.sk/sk/api/feed/json/0`)
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          setBlogs(res.data.data.slice(0, 2))
        } else throw new Error()
      })
      .finally(() => setIsLoadingBlogs(false))
  }, [])

  return (
    <>
      <div className={css.pageBackground}>
        <img alt="" src={homeBackground} />
        <div />
      </div>

      <div className={css.homeWrapper}>
        <div className={css.home}>
          <section className={css.banner}>
            <div className={css.logoCircle} />

            <div className={css.headline}>
              <h1 className={css.title}>{translate.i18n.TITLE_HOME}</h1>
              <h2 className={css.subtitle}>{translate.i18n.SUBTITLE_HOME}</h2>
              <Button
                className="mx-auto mt-5"
                text={translate.i18n.CASES.ambience.btnPhrase}
                onClick={() => {
                  if (herosectionRef.current)
                    window.scrollTo({
                      top: herosectionRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                }}
              />
            </div>
          </section>
          <section ref={herosectionRef} className="w-100 overflow-hidden position-relative z-1">
            <ScrollBar
              initRecalc
              forceEnableRecalc
              onLoad={setShouldAnimateHerosectionTrue}
              onUnload={setShouldAnimateHerosectionFalse}
            />

            <h2 className={css.title}>{translate.i18n.CASE_STUDIES}</h2>

            <Herosection
              animateIn={shouldAnimateHerosection === true}
              animateOut={shouldAnimateHerosection === false}
              items={herosectionItems}
            />

            <Button
              className="mx-auto mt-5 mb-5"
              text={translate.i18n.OUR_SOLUTIONS}
              onClick={() => {
                if (solutionsRef.current)
                  window.scrollTo({
                    top: solutionsRef.current.offsetTop + 160,
                    behavior: 'smooth',
                  })
              }}
            />
          </section>
          <section ref={solutionsRef} className="container">
            <ArticlesTiles
              button={{
                text: translate.i18n.GET_TO_KNOW_US,
                onClick: () => {
                  if (aboutUsRef.current)
                    window.scrollTo({
                      top: aboutUsRef.current.offsetTop - 80,
                      behavior: 'smooth',
                    })
                },
              }}
              tileClassName={css.tile}
              tiles={solutionsTiles}
              title={translate.i18n.SOLUTIONS}
              text={translate.i18n.SOLUTIONS_SUBTITLE}
            />
          </section>
          <ScrollBar
            onLoadThreshold={90}
            onUnloadThreshold={10}
            onLoad={setShouldAnimateAboutUsTrue}
            onUnload={setShouldAnimateAboutUsFalse}
          />
          <section ref={aboutUsRef} className={cx(css.aboutUs, 'container')}>
            <AboutUs
              button={{
                href: getPath(translate.i18n.ROUTES.aboutUs),
                text: translate.i18n.MORE_ABOUT_US,
              }}
              animateFadeIn={shouldAnimateAboutUs === true}
              animateFadeOut={shouldAnimateAboutUs === false}
            />
          </section>
          <ScrollBar
            onLoadThreshold={90}
            onUnloadThreshold={10}
            onLoad={setShouldAnimateBlogsTrue}
            onUnload={setShouldAnimateBlogsFalse}
          />
          <section
            ref={blogsRef}
            className={cx(
              css.blogSection,
              shouldAnimateBlogs && css.visible,
              'container d-flex flex-column'
            )}
          >
            <h2 className={css.sectionTitle}>{translate.i18n.OUR_LATEST_BLOGS}</h2>
            <main className="d-flex flex-column flex-md-row mt-4">
              {isLoadingBlogs ? (
                <>
                  <BlogItemPlaceholder />
                  <BlogItemPlaceholder />
                </>
              ) : (
                blogs.map((blog) => <BlogItem key={blog.url} blog={blog} />)
              )}
            </main>
            <Link className="my-4 cursor-pointer" onClick={() => goTo(translate.i18n.ROUTES.blog)}>
              {translate.i18n.SEE_MORE_BLOG_POSTS}
            </Link>
          </section>
          <section ref={testimonialsRef} className="container">
            <ScrollBar
              onLoadThreshold={90}
              onUnloadThreshold={10}
              onLoad={setShouldAnimateTestimonialsTrue}
              onUnload={setShouldAnimateTestimonialsFalse}
            />
            <Articles
              swipeable
              animateIn={shouldAnimateTestimonials === true}
              animateOut={shouldAnimateTestimonials === false}
              articles={generateTestimonials()}
              button={{
                text: translate.i18n.NAVBAR_TITLE_ICON,
                onClick: () => scrollToFooter(true),
              }}
              title={translate.i18n.REFERENCES}
              onLoad={onLoadLast}
              onUnLoad={onUnloadLast}
            />
          </section>
        </div>
      </div>
    </>
  )
}
