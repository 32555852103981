export const IconMap = {
  arrowDown: 'fal fa-arrow-down',
  arrowLeft: 'fal fa-arrow-left',
  arrowRight: 'fal fa-arrow-right',
  arrowUp: 'fas fa-arrow-up',
  camera: 'fal fa-video',
  cameraDisabled: 'fal fa-video-slash',
  chevronDown: 'fal fa-chevron-down',
  chevronLeft: 'fas fa-chevron-left-thin',
  chevronRight: 'fa fa-chevron-right-thin',
  circle: 'far fa-circle',
  circleSolid: 'fas fa-circle',
  close: 'fal fa-times',
  download: 'fal fa-cloud-download',
  envelope: 'fal fa-envelope',
  eur: 'fal fa-euro-sign',
  facebook: 'fab fa-facebook-f',
  info: 'fal fa-info-circle',
  instagram: 'fab fa-instagram',
  linkedin: 'fab fa-linkedin',
  magnifier: 'fal fa-search-plus',
  microphone: 'fal fa-microphone',
  microphoneDisabled: 'fal fa-microphone-slash',
  phone: 'fal fa-phone-rotary',
  playCircle: 'fas fa-play-circle',
  replay: 'fal fa-repeat',
  speaker: 'fal fa-volume-up',
  speakerDisabled: 'fal fa-volume-slash',
  spinner: 'fal fa-spinner-third',
  twitter: 'fab fa-twitter',
  usd: 'fal fa-dollar-sign',
  users: 'fal fa-users',
  youtube: 'fab fa-youtube',
}
