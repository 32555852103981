import { FOOTER_ID } from './constants'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'

/**
 * Goes to the given routePath with the prefix of: "/localeName/" .
 * @param routePath The pathname that the page will be redirected to.
 */
export const goTo = (routePath: string) => {
  window.location.href = routePath && routePath.startsWith('/') ? routePath : `/${routePath}`
}

/**
 * Return valid path for the requested pathname.
 * @param routePath Path you want to get.
 * @param lang Explicitly set language.
 */
export const getPath = (routePath: string): string => {
  return routePath && routePath.startsWith('/') ? routePath : `/${routePath}`
}

export const chunkify = <T>(items: T[], itemsPerGroup: number): T[][] => {
  if (isNaN(itemsPerGroup) || itemsPerGroup === 0) return []

  const groups: T[][] = []
  const workItems: T[] = [...items] // to avoid ref modifications

  while (workItems.length > 0) {
    groups.push(workItems.splice(0, itemsPerGroup))
  }

  return groups
}

export const scrollToFooter = (noDelay?: boolean, extraDelay?: boolean) => {
  const ele = document.getElementById(FOOTER_ID)

  setTimeout(
    () => {
      if (ele) {
        window.scrollTo({
          top: ele.offsetTop - 80,
          behavior: 'smooth',
        })
      }
    },
    noDelay ? 0 : 750 * (!extraDelay ? 1 : 1.66)
  )
}

export const formatDate = (date: Date) => {
  const day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear()

  return `${day < 10 ? `0${day}` : day}. ${month < 10 ? `0${month}` : month}. ${year}`
}
