// .core
import React, { useCallback, useState } from 'react'
// components
import { Layout } from '../../layouts/Layout'
import { SolutionsContent } from './Solutions.content'

export default function Solutions() {
  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const setAnimateFooterFalse = useCallback(() => {
    setShouldAnimateFooter(false)
  }, [])

  const setAnimateFooterTrue = useCallback(() => {
    setShouldAnimateFooter(true)
  }, [])

  return (
    <>
      <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
        <SolutionsContent onLoadLast={setAnimateFooterTrue} onUnloadLast={setAnimateFooterFalse} />
      </Layout>
    </>
  )
}
