// .core
import React from 'react'
// styles
import css from './Button.module.scss'
import { Link, LinkProps } from 'react-router-dom'

export interface IButtonProps {
  /**
   * Adds highlight effect.
   */
  active?: boolean
  /**
   * aria-hidden
   */
  ariaHidden?: boolean
  /**
   * Custom styling additional className(s).
   */
  className?: string
  /**
   * Adds disabled styling, disables events.
   */
  disabled?: boolean
  /**
   * In case that this button is only a simple link, you can use href attribute instead of onClick.
   */
  href?: string
  /**
   * Replaces text content with loader.
   */
  loading?: boolean
  /**
   * Custom CSS Properties (such as animationDely).
   */
  style?: React.CSSProperties
  /**
   * Only used if href specified. <a> target attribute..
   */
  target?: string
  /**
   * Button text.
   */
  text?: string
  /**
   * React router Link props
   */
  to?: LinkProps['to']
  /**
   * Button type.
   */
  type?: string
  /**
   * Click callback.
   */
  onClick?: () => void
}

export default class Button extends React.Component<IButtonProps> {
  render() {
    const {
      active,
      ariaHidden,
      className,
      disabled,
      href,
      loading,
      style,
      target,
      text,
      to,
      type,
      onClick,
    } = this.props

    const spinnerStyles = { width: '1.25rem', height: '1.25rem' }

    return (
      <>
        {to ? (
          <Link
            aria-hidden={ariaHidden}
            className={`${css.button} ${className || ''} ${active ? css.active : ''} ${
              disabled ? css.disabled : ''
            }`}
            to={to}
          >
            {text}
          </Link>
        ) : href ? (
          <a
            aria-hidden={ariaHidden}
            className={`${css.button} ${className || ''} ${active ? css.active : ''} ${
              disabled ? css.disabled : ''
            }`}
            href={`${href}`}
            style={{ ...style }}
            target={`${target || '_self'}`}
            rel={target === '_blank' ? 'noreferrer' : undefined}
          >
            {!loading ? (
              text || ''
            ) : (
              <>
                <div className="spinner-border text-light" style={spinnerStyles}></div>
              </>
            )}
          </a>
        ) : (
          <button
            aria-hidden={ariaHidden}
            className={`${css.button} ${className || ''} ${active ? css.active : ''} ${
              disabled ? css.disabled : ''
            }`}
            style={{ ...style }}
            type={(type as any) || 'button'}
            onClick={() => {
              onClick ? onClick() : null
            }}
          >
            {!loading ? (
              text || ''
            ) : (
              <>
                <div className={'spinner-border text-light'} style={spinnerStyles}></div>
              </>
            )}
          </button>
        )}
      </>
    )
  }
}
