import React from 'react'

export const HWDesign = (props: { className: string }) => (
  <svg className={props.className || ''} width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect x="7" y="12" width="13" height="19" rx="1" fill="white" />
    <path
      d="M6.5 15H7V14H6.5V15ZM4.5 14C4.22386 14 4 14.2239 4 14.5C4 14.7761 4.22386 15 4.5 15V14ZM6.5 14H4.5V15H6.5V14Z"
      fill="white"
    />
    <path
      d="M6.5 17H7V16H6.5V17ZM4.5 16C4.22386 16 4 16.2239 4 16.5C4 16.7761 4.22386 17 4.5 17V16ZM6.5 16H4.5V17H6.5V16Z"
      fill="white"
    />
    <path
      d="M6.5 19H7V18H6.5V19ZM4.5 18C4.22386 18 4 18.2239 4 18.5C4 18.7761 4.22386 19 4.5 19V18ZM6.5 18H4.5V19H6.5V18Z"
      fill="white"
    />
    <path
      d="M6.5 21H7V20H6.5V21ZM4.5 20C4.22386 20 4 20.2239 4 20.5C4 20.7761 4.22386 21 4.5 21V20ZM6.5 20H4.5V21H6.5V20Z"
      fill="white"
    />
    <path
      d="M6.5 23H7V22H6.5V23ZM4.5 22C4.22386 22 4 22.2239 4 22.5C4 22.7761 4.22386 23 4.5 23V22ZM6.5 22H4.5V23H6.5V22Z"
      fill="white"
    />
    <path
      d="M6.5 25H7V24H6.5V25ZM4.5 24C4.22386 24 4 24.2239 4 24.5C4 24.7761 4.22386 25 4.5 25V24ZM6.5 24H4.5V25H6.5V24Z"
      fill="white"
    />
    <path
      d="M6.5 27H7V26H6.5V27ZM4.5 26C4.22386 26 4 26.2239 4 26.5C4 26.7761 4.22386 27 4.5 27V26ZM6.5 26H4.5V27H6.5V26Z"
      fill="white"
    />
    <path
      d="M6.5 29H7V28H6.5V29ZM4.5 28C4.22386 28 4 28.2239 4 28.5C4 28.7761 4.22386 29 4.5 29V28ZM6.5 28H4.5V29H6.5V28Z"
      fill="white"
    />
    <path
      d="M20.5 15H20V14H20.5V15ZM22.5 14C22.7761 14 23 14.2239 23 14.5C23 14.7761 22.7761 15 22.5 15V14ZM20.5 14H22.5V15H20.5V14Z"
      fill="white"
    />
    <path
      d="M20.5 17H20V16H20.5V17ZM22.5 16C22.7761 16 23 16.2239 23 16.5C23 16.7761 22.7761 17 22.5 17V16ZM20.5 16H22.5V17H20.5V16Z"
      fill="white"
    />
    <path
      d="M20.5 19H20V18H20.5V19ZM22.5 18C22.7761 18 23 18.2239 23 18.5C23 18.7761 22.7761 19 22.5 19V18ZM20.5 18H22.5V19H20.5V18Z"
      fill="white"
    />
    <path
      d="M20.5 21H20V20H20.5V21ZM22.5 20C22.7761 20 23 20.2239 23 20.5C23 20.7761 22.7761 21 22.5 21V20ZM20.5 20H22.5V21H20.5V20Z"
      fill="white"
    />
    <path
      d="M20.5 23H20V22H20.5V23ZM22.5 22C22.7761 22 23 22.2239 23 22.5C23 22.7761 22.7761 23 22.5 23V22ZM20.5 22H22.5V23H20.5V22Z"
      fill="white"
    />
    <path
      d="M20.5 25H20V24H20.5V25ZM22.5 24C22.7761 24 23 24.2239 23 24.5C23 24.7761 22.7761 25 22.5 25V24ZM20.5 24H22.5V25H20.5V24Z"
      fill="white"
    />
    <path
      d="M20.5 27H20V26H20.5V27ZM22.5 26C22.7761 26 23 26.2239 23 26.5C23 26.7761 22.7761 27 22.5 27V26ZM20.5 26H22.5V27H20.5V26Z"
      fill="white"
    />
    <path
      d="M20.5 29H20V28H20.5V29ZM22.5 28C22.7761 28 23 28.2239 23 28.5C23 28.7761 22.7761 29 22.5 29V28ZM20.5 28H22.5V29H20.5V28Z"
      fill="white"
    />
    <path d="M22 14.4999L22.5 14.4999L27 10L32.4994 9.99965" stroke="white" />
    <path d="M21.5 16.5L23.5 16.5L27 20.0725L32.3383 20.0725" stroke="white" />
    <path d="M21 18.4707H23L26.5 22.0432L29 22.0432L29 25" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 11C34.5523 11 35 10.5523 35 10C35 9.44772 34.5523 9 34 9C33.4477 9 33 9.44772 33 10C33 10.5523 33.4477 11 34 11ZM34 12C35.1046 12 36 11.1046 36 10C36 8.89543 35.1046 8 34 8C32.8954 8 32 8.89543 32 10C32 11.1046 32.8954 12 34 12Z"
      fill="#288BA0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 21C34.5523 21 35 20.5523 35 20C35 19.4477 34.5523 19 34 19C33.4477 19 33 19.4477 33 20C33 20.5523 33.4477 21 34 21ZM34 22C35.1046 22 36 21.1046 36 20C36 18.8954 35.1046 18 34 18C32.8954 18 32 18.8954 32 20C32 21.1046 32.8954 22 34 22Z"
      fill="#288BA0"
    />
    <rect x="28" y="25" width="2" height="1" fill="white" />
    <rect x="28" y="27" width="2" height="1" fill="white" />
    <path d="M29.0005 27.5017L29.0005 29L32.5 29" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 30C34.5523 30 35 29.5523 35 29C35 28.4477 34.5523 28 34 28C33.4477 28 33 28.4477 33 29C33 29.5523 33.4477 30 34 30ZM34 31C35.1046 31 36 30.1046 36 29C36 27.8954 35.1046 27 34 27C32.8954 27 32 27.8954 32 29C32 30.1046 32.8954 31 34 31Z"
      fill="#288BA0"
    />
    <path
      d="M17.5 13.5C18.0523 13.5 18.5 13.9477 18.5 14.5V28.5C18.5 29.0523 18.0523 29.5 17.5 29.5H9.5C8.94772 29.5 8.5 29.0523 8.5 28.5V14.5C8.5 13.9477 8.94772 13.5 9.5 13.5H11.2273H13.0455H14.4091H15.7727H17.5Z"
      fill="#10161D"
    />
  </svg>
)
