// .core
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
// components
import { CaseItem } from './CaseItem'
import { Article } from 'components/Article/Article'
import Articles from 'components/Articles/Articles'
import { ArticlesTiles } from 'components/ArticlesTiles/ArticlesTiles'
import Button from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { Link } from 'components/Link/Link'
import Herosection from 'components/Herosection/Herosection'
// content
import { generateTestimonials } from 'content/Testimonials/Testimonials'
// interfaces
import { ICase } from '../../content/Cases/Cases'
// libraries
import cx from 'classnames'
// @ts-ignore
import Faq from 'react-faq-component'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './CaseItem.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { scrollToFooter } from 'utils/functions'

interface IIsVisible {
  detailImg?: boolean
  detailText?: boolean
  articles?: boolean
  testimonials?: boolean
  logos?: boolean
  herosection?: boolean
}

export interface ICaseItemDetailProps extends ICase {}

export default function CaseItemDetail({
  animation,
  articles,
  bannerTexts,
  bannerTextsIcons,
  bannerTextsTitle,
  btnPhrase,
  detailTexts = [],
  faq,
  hideTestimonials,
  img,
  subtitle,
  text,
  title,
  herosection,
  bannerButton,
  references,
  services,
  url,
  whoIsItFor,
  whoIsItForIcons,
  whyShouldYouDoIt,
  whyShouldYouDoItIcons,
}: ICaseItemDetailProps) {
  const aniRef = useRef<HTMLVideoElement>(null)
  const bannerTextsRef = useRef<HTMLDivElement>(null)
  const faqRef = useRef<HTMLElement>(null)
  const firstSectionRef = useRef<HTMLDivElement>(null)
  const herosectionRef = useRef<HTMLElement>(null)
  const servicesRef = useRef<HTMLElement>(null)
  const testimonialsRef = useRef<HTMLElement>(null)
  const whoIsItForRef = useRef<HTMLDivElement>(null)
  const whyShouldYouDoItRef = useRef<HTMLDivElement>(null)

  const videoEnded = useRef<boolean | undefined>(undefined)

  const [shouldShowReplay, setShouldShowReplay] = useState<boolean>(false)

  const [isVisible, _setIsVisible] = useState<IIsVisible>({
    herosection: undefined,
    detailText: undefined,
    articles: undefined,
    detailImg: undefined,
    logos: undefined,
    testimonials: undefined,
  })

  const setIsVisible = (targetKey: keyof IIsVisible, shouldBecomeVisible?: boolean) => () => {
    const newIsVisible: IIsVisible = {
      [targetKey]: !!shouldBecomeVisible,
    }

    const keys = Object.keys(isVisible)
    const targetKeyIndex = keys.indexOf(targetKey)

    // if (targetKey === 'footer') shouldBecomeVisible ? onLoadLast() : onUnloadLast()

    keys.forEach((key, keyIndex) => {
      if (keyIndex < targetKeyIndex) {
        isVisible[key as keyof IIsVisible] = true
        newIsVisible[key as keyof IIsVisible] = true
      } else if (keyIndex > targetKeyIndex && isVisible[key as keyof IIsVisible]) {
        newIsVisible[key as keyof IIsVisible] = false
      }
    })

    _setIsVisible((p) => ({ ...p, ...newIsVisible }))
  }

  const handleReplayVideo = () => {
    if (!(aniRef.current && videoEnded.current && shouldShowReplay)) return

    if (aniRef.current) {
      setShouldShowReplay(false)
      videoEnded.current = false
      aniRef.current.pause()
      aniRef.current.currentTime = 0
      try {
        aniRef.current.play().catch((_) => {
          if (aniRef.current) {
            aniRef.current.controls = true
          }
        })
      } catch (_) {}
    }
  }

  const handleVideoEnd = useCallback(() => {
    videoEnded.current = true
    setShouldShowReplay(true)
  }, [])

  const tiles = useMemo(() => {
    const texts = bannerTexts
    const icons = bannerTextsIcons

    if (!texts) return {}

    return {
      title: '',
      tiles: texts.map((btItem, btItemIndex) => ({
        href: services ? btItem.title.toLowerCase().replaceAll(' ', '') : btItem.href,
        img: icons && icons[btItemIndex] ? icons[btItemIndex] : undefined,
        title: btItem.title,
        text: btItem.items,
        url: btItem.url,
      })),
    }
  }, [bannerTexts, bannerTextsIcons, !!services])

  const whoIsItForTiles = useMemo(() => {
    const texts = whoIsItFor
    const icons = whoIsItForIcons

    if (!texts) return {}

    return {
      title: '',
      tiles: texts.map((btItem, btItemIndex) => ({
        href: btItem.href,
        img: icons && icons[btItemIndex] ? icons[btItemIndex] : undefined,
        title: btItem.title,
        text: btItem.items,
        url: btItem.url,
      })),
    }
  }, [whoIsItFor, whoIsItForIcons])

  const whyShouldYouDoItTiles = useMemo(() => {
    const texts = whyShouldYouDoIt
    const icons = whyShouldYouDoItIcons

    if (!texts) return {}

    return {
      title: '',
      tiles: texts.map((btItem, btItemIndex) => ({
        href: btItem.href,
        img: icons && icons[btItemIndex] ? icons[btItemIndex] : undefined,
        title: btItem.title,
        text: btItem.items,
        url: btItem.url,
      })),
    }
  }, [whyShouldYouDoIt, whyShouldYouDoItIcons])

  useEffect(() => {
    if (aniRef.current) {
      aniRef.current.currentTime = 0
      try {
        aniRef.current.play().catch((_) => {
          if (aniRef.current) {
            aniRef.current.controls = true
          }
        })
      } catch (_) {}
      videoEnded.current = false
    }
  }, [])

  const getAnimateClass = (shouldAnimate?: boolean) =>
    shouldAnimate ? css.animateIn : shouldAnimate === false ? css.animateOut : undefined

  return (
    <div className={css.detail}>
      <div className={cx(css.content, css.visible, css.landing)}>
        <span>
          <h2 className={css.title} style={{ transition: 'none', transitionDelay: '0s' }}>
            {title}
          </h2>
          <h3 className={css.subtitle} style={{ transition: 'none', transitionDelay: '0s' }}>
            {subtitle}
          </h3>
          <p
            className={cx(css.text, css.head)}
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ transition: 'none', transitionDelay: '0s' }}
          />
        </span>
        <div
          className={cx(
            css.imgWrapper,
            url === 'custom-software-solutions' ? css.small : undefined
          )}
        >
          {animation ? (
            <>
              <video
                ref={aniRef}
                muted
                playsInline
                src={animation}
                className="w-100"
                onEndedCapture={handleVideoEnd}
                onLoadStart={() => setShouldShowReplay(false)}
              >
                <source src={animation} type="video/mp4" />
              </video>
              {animation ? (
                <button
                  className={cx(
                    css.replayBtn,
                    !(shouldShowReplay && videoEnded.current) && css.hidden
                  )}
                  onClick={handleReplayVideo}
                >
                  <Icon name="replay" className="mr-2" />
                  {translate.i18n.REPLAY}
                </button>
              ) : null}
            </>
          ) : typeof img === 'string' ? (
            <img alt="" className={css.img} src={img} />
          ) : null}
        </div>
        <span
          className={cx(css.mt10, 'd-flex flex-column justify-content-center align-items-center')}
        >
          <>
            <Button
              className="mb-4"
              text={
                (herosection ? btnPhrase : translate.i18n.OUR_SOLUTIONS) ||
                translate.i18n.DISCOVER_MORE
              }
              onClick={() => {
                if (herosectionRef.current)
                  window.scrollTo({
                    top: herosectionRef.current.offsetTop + 160,
                    behavior: 'smooth',
                  })
              }}
            />
          </>
        </span>
      </div>
      {herosection && herosection.length ? (
        <section ref={herosectionRef} className="w-100 overflow-hidden">
          <ScrollBar
            onLoad={setIsVisible('herosection', true)}
            onUnload={setIsVisible('herosection', false)}
          />
          <Herosection
            animateIn={isVisible.herosection}
            animateOut={isVisible.herosection === false}
            items={herosection}
          />

          <Button
            className="mx-auto mt-5 mb-5"
            text={
              whoIsItFor?.length
                ? translate.i18n.WHO_IS_IT_FOR
                : whyShouldYouDoIt?.length
                ? translate.i18n.WHY_SHOULD_YOU_DO_IT
                : bannerTextsTitle && bannerTexts?.length
                ? `${translate.i18n.OUR} ${bannerTextsTitle.toLowerCase()}`
                : translate.i18n.OUR_SOLUTIONS
            }
            onClick={() => {
              if (firstSectionRef.current)
                window.scrollTo({
                  top: firstSectionRef.current.offsetTop + 90,
                  behavior: 'smooth',
                })
            }}
          />
        </section>
      ) : null}

      <div ref={firstSectionRef} />

      {whoIsItFor ? (
        <div ref={whoIsItForRef} className={css.imgWrapper}>
          <div className="container">
            <ArticlesTiles
              disableScrollbarMt
              title={translate.i18n.WHO_IS_IT_FOR}
              link={{
                children: translate.i18n.NAVBAR_TITLE_ICON,
                href: '#',
                onClick: () => {
                  scrollToFooter(true)
                },
              }}
              button={{
                className: cx(css.button, css.mt50, 'mt-0'),
                text: whyShouldYouDoItRef.current
                  ? translate.i18n.WHY_SHOULD_YOU_DO_IT
                  : bannerButton || translate.i18n.OUR_PROCESS,
                onClick: () => {
                  if (whyShouldYouDoItRef.current) {
                    window.scrollTo({
                      top: whyShouldYouDoItRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                  } else if (bannerTextsRef.current) {
                    window.scrollTo({
                      top: bannerTextsRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                  } else if (faqRef.current) {
                    window.scrollTo({
                      top: faqRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                  } else {
                    const scrollElement = document.getElementById('detailText_0')
                    if (scrollElement) {
                      window.scrollTo({
                        top: scrollElement.offsetTop - 120,
                        behavior: 'smooth',
                      })
                    } else if (testimonialsRef.current !== null) {
                      window.scrollTo({
                        top: testimonialsRef.current.offsetTop + 160,
                        behavior: 'smooth',
                      })
                    }
                  }
                },
              }}
              tiles={whoIsItForTiles as any}
            />
          </div>
        </div>
      ) : null}

      {whyShouldYouDoIt ? (
        <div ref={whyShouldYouDoItRef} className={css.imgWrapper}>
          <div className="container">
            <ArticlesTiles
              disableScrollbarMt={!whoIsItFor?.length}
              title={translate.i18n.WHY_SHOULD_YOU_DO_IT}
              link={{
                children: translate.i18n.NAVBAR_TITLE_ICON,
                href: '#',
                onClick: () => {
                  scrollToFooter(true)
                },
              }}
              button={{
                className: cx(css.button, css.mt50, 'mt-0'),
                text: bannerTexts?.length
                  ? bannerTextsTitle
                    ? `Our ${bannerTextsTitle?.toLowerCase()}`
                    : translate.i18n.OUR_SOLUTIONS
                  : faq?.length
                  ? 'FAQ'
                  : bannerButton || translate.i18n.OUR_PROCESS,
                onClick: () => {
                  if (bannerTextsRef.current) {
                    window.scrollTo({
                      top: bannerTextsRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                  } else if (faqRef.current) {
                    window.scrollTo({
                      top: faqRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                  } else {
                    const scrollElement = document.getElementById('detailText_0')
                    if (scrollElement) {
                      window.scrollTo({
                        top: scrollElement.offsetTop - 120,
                        behavior: 'smooth',
                      })
                    } else if (testimonialsRef.current !== null)
                      window.scrollTo({
                        top: testimonialsRef.current.offsetTop + 160,
                        behavior: 'smooth',
                      })
                  }
                },
              }}
              tiles={whyShouldYouDoItTiles as any}
            />
          </div>
        </div>
      ) : null}

      {bannerTexts ? (
        <div ref={bannerTextsRef} className={css.imgWrapper}>
          <div className="container">
            <ArticlesTiles
              disableScrollbarMt={!(whoIsItFor?.length || whyShouldYouDoIt?.length)}
              title={bannerTextsTitle || translate.i18n.SOLUTIONS}
              link={{
                children: translate.i18n.NAVBAR_TITLE_ICON,
                href: '#',
                onClick: () => {
                  scrollToFooter(true)
                },
              }}
              button={{
                className: cx(css.button, css.mt50, 'mt-0'),
                text: services?.length
                  ? translate.i18n.HOW_WE_DO_IT
                  : whoIsItFor
                  ? translate.i18n.WHO_IS_IT_FOR
                  : whyShouldYouDoIt
                  ? translate.i18n.WHY_SHOULD_YOU_DO_IT
                  : bannerButton || translate.i18n.OUR_PROCESS,
                onClick: () => {
                  if (servicesRef.current) {
                    window.scrollTo({
                      top: servicesRef.current.offsetTop + 160,
                      behavior: 'smooth',
                    })
                  } else {
                    const scrollElement = document.getElementById('detailText_0')
                    if (scrollElement) {
                      window.scrollTo({
                        top: scrollElement.offsetTop - 120,
                        behavior: 'smooth',
                      })
                    } else if (testimonialsRef.current !== null) {
                      window.scrollTo({
                        top: testimonialsRef.current.offsetTop + 160,
                        behavior: 'smooth',
                      })
                    }
                  }
                },
              }}
              tiles={tiles as any}
            />
          </div>
        </div>
      ) : null}

      {services?.length ? (
        <section ref={servicesRef}>
          <ScrollBar />

          {services?.map((service, serviceIndex) => {
            return (
              <CaseItem
                {...service}
                animateFadeIn
                key={`case_${serviceIndex}`}
                className="container"
                forceEnableRecalc={serviceIndex === services.length - 1}
                hideScrollbar={!serviceIndex}
                hrefId={service.title.toLowerCase().replaceAll(' ', '')}
                swap={!(serviceIndex % 2)}
              />
            )
          })}

          <Button
            className="mx-auto mt-5"
            text="FAQ"
            onClick={() => {
              if (faqRef.current) {
                window.scrollTo({
                  top: faqRef.current.offsetTop + 160,
                  behavior: 'smooth',
                })
              }
            }}
          />

          <div className="d-flex justify-content-center">
            <Link
              href="#"
              onClick={() => {
                scrollToFooter(true)
              }}
            >
              {translate.i18n.NAVBAR_TITLE_ICON}
            </Link>
          </div>
        </section>
      ) : null}

      {faq?.length ? (
        <section ref={faqRef} className="container">
          <ScrollBar />

          <h2 className={cx(css.sectionTitle, css.visible)}>FAQ</h2>

          {faq.map((faqSection) => (
            <div className={css.faqSection} key={faqSection.title}>
              <Faq data={faqSection} />
            </div>
          ))}

          <Button
            className="mx-auto mt-5"
            text={translate.i18n.TESTIMONIALS_TITLE}
            onClick={() => {
              if (testimonialsRef.current) {
                window.scrollTo({
                  top: testimonialsRef.current.offsetTop + 160,
                  behavior: 'smooth',
                })
              }
            }}
          />

          <div className="d-flex justify-content-center">
            <Link
              href="#"
              onClick={() => {
                scrollToFooter(true)
              }}
            >
              {translate.i18n.NAVBAR_TITLE_ICON}
            </Link>
          </div>
        </section>
      ) : null}

      {detailTexts?.length ? (
        <>
          <ScrollBar
            forceEnableRecalc
            className={!bannerTexts ? cx('mt-0', css.scrollBar) : undefined}
            onLoad={setIsVisible('detailText', true)}
            onUnload={setIsVisible('detailText', false)}
          />

          {!bannerTexts ? (
            <>
              <div
                style={{
                  visibility: 'hidden',
                  width: '0 !important',
                  maxHeight: '0 !important',
                  maxWidth: '0 !important',
                }}
              />
              <ScrollBar
                forceEnableRecalc
                className={css.detailSBar}
                onLoad={setIsVisible('detailText', true)}
                onUnload={setIsVisible('detailText', false)}
              />
            </>
          ) : null}
          {
            // @ts-ignore
            detailTexts.map((detailText, detailTextIndex: number) => {
              return detailText instanceof Object && detailText.component ? (
                <section
                  key={`detailText_${detailTextIndex}`}
                  className="container"
                  id={`detailText_${detailTextIndex}`}
                >
                  {detailText.title && (
                    <h2 className={cx(css.title, css.visible, 'text-center', css.mb50)}>
                      {detailText.title}
                    </h2>
                  )}
                  <detailText.component id={`detailText_${detailTextIndex}_inner`} />
                  <Button
                    className={cx(
                      css.button,
                      getAnimateClass(isVisible.detailText),
                      css.mt50,
                      'mx-auto'
                    )}
                    text={translate.i18n.TESTIMONIALS_TITLE}
                    onClick={() => {
                      if (testimonialsRef.current) {
                        window.scrollTo({
                          top: testimonialsRef.current.offsetTop + 160,
                          behavior: 'smooth',
                        })
                      }
                    }}
                  />
                </section>
              ) : (
                <div
                  key={`detailText_${detailTextIndex}`}
                  className="d-flex flex-column align-items-center justify-content-center container"
                  id={`detailText_${detailTextIndex}`}
                >
                  {typeof detailText === 'string' ? (
                    <p className={cx(css.detailText, getAnimateClass(isVisible.detailText))}>
                      {detailText}
                    </p>
                  ) : (
                    <React.Fragment key={`detailText_${detailTextIndex}`}>
                      {detailText.title ? (
                        <h3 className={cx(detailTextIndex > 0 && css.mt50, 'text-center')}>
                          {detailText.title}
                        </h3>
                      ) : null}
                      {detailTextIndex !== 0 &&
                      detailText.style === 'bold' &&
                      (detailTexts[detailTextIndex - 1] as any).style !== 'bold' ? (
                        <ScrollBar />
                      ) : null}
                      {detailText.picture ? (
                        <img
                          alt=""
                          className={cx(
                            'w-100 mt-4',
                            css.dOnly,
                            detailText.style === 'mb' && css.mb50
                          )}
                          src={detailText.picture}
                        />
                      ) : null}
                      {detailText.text && detailText.style !== 'significant' ? (
                        <p
                          className={cx(
                            css.detailText,
                            getAnimateClass(isVisible.detailText),
                            detailText.style === 'bold'
                              ? cx(css.bold, detailText.hasBtn ? 'mb-4' : css.mb50)
                              : undefined
                          )}
                          dangerouslySetInnerHTML={{ __html: detailText.text }}
                        ></p>
                      ) : detailText.text && detailText.style === 'significant' ? (
                        <Article animateIn hideScrollbar significant text={detailText.text} />
                      ) : null}
                      {detailText.hasBtn ? (
                        <>
                          <Button
                            className={cx(
                              css.button,
                              css.mt50,
                              getAnimateClass(isVisible.detailText)
                            )}
                            text={detailText.btnText || translate.i18n.NAVBAR_TITLE_ICON}
                            onClick={() => {
                              if (detailText.btnText) {
                                const scrollElement = document.getElementById(
                                  detailText.btnType === 'trustedBy'
                                    ? 'trustedBy'
                                    : `detailText_${detailTextIndex + 1}`
                                )

                                if (scrollElement)
                                  window.scrollTo({
                                    top: scrollElement.offsetTop - 80,
                                    behavior: 'smooth',
                                  })
                                else if (testimonialsRef.current)
                                  window.scrollTo({
                                    top: testimonialsRef.current.offsetTop + 160,
                                    behavior: 'smooth',
                                  })
                              } else {
                                scrollToFooter(true)
                              }
                            }}
                          />
                          {detailText.btnText ? (
                            <Link
                              href="#"
                              onClick={() => {
                                scrollToFooter(true)
                              }}
                            >
                              {translate.i18n.NAVBAR_TITLE_ICON}
                            </Link>
                          ) : null}
                        </>
                      ) : null}
                      {((detailTextIndex !== detailTexts.length - 1 &&
                        detailText.style === 'bold') ||
                        detailText.hasScrollbar) &&
                      detailText.style !== 'significant' ? (
                        <ScrollBar />
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              )
            })
          }
          {articles ? (
            <Button
              className={cx(css.button, getAnimateClass(isVisible.detailText))}
              text={translate.i18n.NAVBAR_TITLE_ICON}
              onClick={() => {
                scrollToFooter(true)
              }}
            />
          ) : null}
        </>
      ) : null}

      {articles ? (
        <>
          <div
            style={{
              visibility: 'hidden',
              width: '0 !important',
              maxHeight: '0 !important',
              maxWidth: '0 !important',
            }}
          />
          <ScrollBar
            forceEnableRecalc
            onLoad={setIsVisible('articles', true)}
            onUnload={setIsVisible('articles', false)}
          />

          <div className={cx('d-flex flex-wrap justify-content-center container', css.articles)}>
            {articles.map((article, articleIndex) => (
              <div
                key={cx(article.title, articleIndex)}
                className={cx(
                  'd-flex flex-column col-sm-12 col-md-10 col-lg-6 col-xl-6',
                  css.article,
                  getAnimateClass(isVisible.articles)
                )}
              >
                <h3 className={css.title}>{article.title}</h3>
                <p className={css.text}>{article.text}</p>
              </div>
            ))}
          </div>
        </>
      ) : null}

      {references?.length ? (
        <>
          <ScrollBar
            forceEnableRecalc
            className={!bannerTexts ? cx('mt-0', css.scrollBar) : undefined}
            onLoad={setIsVisible('logos', true)}
            onUnload={setIsVisible('logos', false)}
          />
          <div
            className={cx(
              css.trustedBy,
              'container',
              isVisible.logos ? css.animateIn : css.animateOut
            )}
            id="trustedBy"
          >
            <Article animateIn hideScrollbar significant title={translate.i18n.TRUSTED_BY_BRANDS} />
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              {references.map((ref) => (
                <img key={ref.key} className={css.partners} src={ref.logo} alt={ref.key} />
              ))}
            </div>
            <Button
              className={cx(css.button, css.animateIn)}
              text={translate.i18n.TESTIMONIALS_TITLE}
              onClick={() => {
                if (testimonialsRef.current) {
                  window.scrollTo({
                    top: testimonialsRef.current.offsetTop + 160,
                    behavior: 'smooth',
                  })
                }
              }}
            />
          </div>
        </>
      ) : null}

      {!hideTestimonials && (
        <section ref={testimonialsRef} className="container">
          <ScrollBar
            forceEnableRecalc
            onLoadThreshold={90}
            onUnloadThreshold={10}
            onLoad={setIsVisible('testimonials', true)}
            onUnload={setIsVisible('testimonials', false)}
          />
          {!hideTestimonials ? (
            <Articles
              hideScrollbar
              initRecalc
              swipeable
              animateIn={isVisible.testimonials}
              animateOut={isVisible.testimonials === false}
              articles={generateTestimonials()}
              button={{
                text: translate.i18n.NAVBAR_TITLE_ICON,
                onClick: () => scrollToFooter(true),
              }}
              title={translate.i18n.REFERENCES}
            />
          ) : null}
        </section>
      )}
    </div>
  )
}
