// .core
import React, { useEffect } from 'react'
// libraries
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// routing
import E404 from 'pages/E404/E404'
import { generateRoutes } from 'routes/Routes'
import { Route, Switch } from 'react-router-dom'
// styles
import 'styles/without_modules/index.scss'

const removeErrPage = () => {
  const errorPageElement = document.getElementById('browserDisclaimer') as HTMLDivElement

  if (errorPageElement) {
    errorPageElement.style.display = 'none'
    errorPageElement.remove()
  }

  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
}

export default function App() {
  useEffect(() => {
    removeErrPage()

    setTimeout(() => {
      document.documentElement.style.scrollBehavior = 'default'
      window.history.scrollRestoration = 'manual'
      document.documentElement.style.scrollBehavior = 'smooth'
    }, 0)
  }, [])

  return (
    <Switch>
      {generateRoutes()
        .filter((r) => r.component)
        .map((r) => (
          <Route
            exact
            component={!r.props ? r.component : undefined}
            key={`route_${r.path}`}
            path={r.path}
            render={r.props ? () => <r.component {...r.props} /> : undefined}
          />
        ))}
      <Route component={E404} />
    </Switch>
  )
}
