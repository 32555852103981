// .core
import React, { useEffect, useMemo, useState } from 'react'
// components
import { CASES } from 'content/Cases/Cases'
import { generateHerosection } from 'content/Herosection/Herosection'
import { Footer } from 'components/Footer/Footer'
import { Navbar } from 'components/Navbar/Navbar'
import { Meta } from 'components/Meta/Meta'
import { generateRoutes } from 'routes/Routes'
// libs
import cx from 'classnames'
import CookieConsent from 'components/CookieConsent/CookieConsent'
import { match, useLocation, useRouteMatch } from 'react-router'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './Layout.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { scrollToFooter } from 'utils/functions'

export interface IRouterMatch extends match {
  params: {
    caseStudyUrl?: string | null
    /**
     * Name of the locale.
     */
    localeName: string | null
    /**
     * Requested solution.
     */
    solutionUrl?: string | null
  }
}

export interface ILayoutProps {
  blockFooter?: boolean
  children: any
  hideBackdrop?: boolean
  footerAnimate?: boolean
  footerAnimateIn?: boolean
  footerAnimateOut?: boolean
}

export const Layout = ({
  blockFooter,
  children,
  footerAnimate,
  footerAnimateIn,
  footerAnimateOut,
  hideBackdrop,
}: ILayoutProps) => {
  const location = useLocation()
  const match = useRouteMatch()

  const [circlePosition, setCirclePosition] = useState<React.CSSProperties>({})
  const [pageIsScrolled, setPageIsScrolled] = useState<boolean>()
  const [navbarOpenStatus, setNavbarOpenStatus] = useState<number>(0)

  const shouldHideContent = navbarOpenStatus === 2

  const findPageSeoMeta = (matchedPath: string) => {
    const matchedRoute = generateRoutes().find((route) => route.path === matchedPath)

    const { caseStudyUrl, solutionUrl } = (match as IRouterMatch).params

    if (matchedRoute) {
      const matchingCaseStudy = generateHerosection().find(
        (item) => item.subUrl === solutionUrl && item.url === caseStudyUrl
      )

      if (caseStudyUrl && solutionUrl && matchingCaseStudy) {
        return {
          title: `${matchingCaseStudy.seoTitle || matchingCaseStudy.title} - ${
            translate.i18n.CASE_STUDY
          }`,
          description: matchingCaseStudy.text,
        }
      }

      const matchingSolution = CASES.find((solution) => solution.url === solutionUrl)

      if (solutionUrl && matchingSolution) {
        return {
          title: matchingSolution.metatitle || matchingSolution.title,
          description:
            matchingSolution.seoDescription || matchingSolution.shortText || matchingSolution.text,
        }
      }

      return { title: matchedRoute.pageTitle, description: matchedRoute.seoDescription }
    }

    return undefined
  }

  const pageSeoMeta: Record<string, string | undefined> =
    (match ? findPageSeoMeta(match.path) : {}) || {}

  const backdropStyles = useMemo<React.CSSProperties>(
    () => ({
      visibility: hideBackdrop
        ? navbarOpenStatus !== 0
          ? navbarOpenStatus === 1
            ? 'hidden'
            : 'visible'
          : 'hidden'
        : 'visible',
    }),
    [hideBackdrop, navbarOpenStatus]
  )

  useEffect(() => {
    document.body.style.overflowY = navbarOpenStatus === 2 ? 'hidden' : 'visible'
    setCirclePosition({})
  }, [navbarOpenStatus])

  useEffect(() => {
    if (location.hash.includes(translate.i18n.CONTACT.toLowerCase())) {
      scrollToFooter(true, false)
    } else {
      document.documentElement.style.scrollBehavior = 'default'
      window.history.scrollRestoration = 'manual'
      window.scrollTo(0, 0)
      document.documentElement.style.scrollBehavior = 'smooth'
    }

    const handleScroll = () => {
      setPageIsScrolled(
        !!Math.ceil(
          parseFloat(
            (window.scrollY / (document.body.scrollHeight - window.innerHeight)).toFixed(2)
          ) * 100
        )
      )
    }

    document.addEventListener('scroll', handleScroll)

    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <CookieConsent />
      {!!pageSeoMeta && !!(pageSeoMeta.title || pageSeoMeta.description) && (
        <Meta {...pageSeoMeta} />
      )}

      <div className={css.layoutWrapper}>
        <Navbar
          blockContact={blockFooter}
          pageIsScrolled={pageIsScrolled}
          onSetCirclePosition={setCirclePosition}
          onToggle={setNavbarOpenStatus}
        />
        <div className={css.backdrop} style={backdropStyles}>
          <div
            className={cx(
              css.logoCircle,
              pageIsScrolled === undefined
                ? undefined
                : shouldHideContent || !pageIsScrolled
                ? css.show
                : css.hide
            )}
            style={circlePosition}
          />
        </div>

        <div className={cx(css.layout, shouldHideContent ? css.hidden : css.visible)}>
          {children}
        </div>
        {!blockFooter ? (
          <Footer
            animateIn={footerAnimateIn || footerAnimate === true}
            animateOut={footerAnimateOut || footerAnimate === false}
            visible={!shouldHideContent}
          />
        ) : null}
      </div>
    </>
  )
}
