// .core
import React from 'react'
// config
import { IconMap } from 'utils/iconMap'

interface IIconProps {
  /**
   * ClassName for custom styling.
   */
  className?: string
  /**
   * The name of the icon.
   */
  name: keyof typeof IconMap | string
}

export const Icon = ({ className = '', name }: IIconProps) => {
  // @ts-ignore
  return <i className={`${IconMap[name]} ${className}`}></i>
}
