// core
import React from 'react'
// partials
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
import { Layout } from 'layouts/Layout'
// libraries
import cx from 'classnames'
// styles
import css from './LogoPage.module.scss'

export default function LogoPage() {
  return (
    <>
      <Layout hideBackdrop footerAnimateIn>
        <section className={cx('d-flex justify-content-between flex-column', css.content)}>
          <div className="container d-flex flex-grow-1 justify-content-center align-content-center align-items-center flex-column">
            <div className={css.logo} />
            <h1 className={css.heading}>ambitas logo</h1>

            <div className={css.links}>
              <a download href="/logo-svg.zip">
                svg
              </a>
              <a download href="/logo-ai.zip">
                ai
              </a>
              <a download href="/logo-pdf.zip">
                pdf
              </a>
              <a download href="/logo-png.zip">
                png
              </a>
            </div>

            <a download className={css.downloadAll} href="/logo-all.zip">
              or download all
            </a>
          </div>
        </section>

        <ScrollBar className="mt-auto" />
      </Layout>
    </>
  )
}
