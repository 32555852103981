export interface SocialMedia {
  name: string
  iconName: string
  link: string
}

export const SocialMedia: SocialMedia[] = [
  {
    name: 'LinkedIn',
    iconName: 'linkedin',
    link: 'https://www.linkedin.com/company/ambitas/',
  },
  {
    name: 'Twitter',
    iconName: 'twitter',
    link: 'https://twitter.com/ambitas',
  },
  {
    name: 'Instagram',
    iconName: 'instagram',
    link: 'https://www.instagram.com/ambitas.sk/',
  },
  {
    name: 'Facebook',
    iconName: 'facebook',
    link: 'https://sk-sk.facebook.com/ambitas/',
  },
  {
    name: 'YouTube',
    iconName: 'youtube',
    link: 'https://www.youtube.com/channel/UCiADJPR-PkLrcOIjt57WuZw',
  },
]
