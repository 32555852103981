// .core
import React, { useCallback, useEffect, useState } from 'react'
// components
import { CaseItem } from 'components/CaseItem/CaseItem'
import { Layout } from 'layouts/Layout'
// content
import { ICase } from 'content/Cases/Cases'
// libs
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './Solution.module.scss'

export interface ISolutionProps {
  solution?: ICase
}

export default function Solution({ solution }: ISolutionProps) {
  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const setAnimateFooterFalse = useCallback(() => {
    setShouldAnimateFooter(false)
  }, [])

  const setAnimateFooterTrue = useCallback(() => {
    setShouldAnimateFooter(true)
  }, [])

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'default'
    window.history.scrollRestoration = 'manual'
    window.scrollTo(0, 0)
    document.documentElement.style.scrollBehavior = 'smooth'
  }, [])

  return solution ? (
    <>
      <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
        <div className={css.solution}>
          <CaseItem
            {...solution}
            animateFadeIn
            showDetail
            onLoad={setAnimateFooterTrue}
            onUnload={setAnimateFooterFalse}
          />
        </div>
      </Layout>
    </>
  ) : null
}
