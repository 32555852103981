import React from 'react'
import ReactDOMClient from 'react-dom/client'
import App from './App'
import smoothscroll from 'smoothscroll-polyfill'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import reportWebVitals from 'reportWebVitals'

smoothscroll.polyfill()

const rootEl = document.getElementById('root')

if (!rootEl) throw new Error('div#root not found')

// @ts-ignore
if (module.hot) {
  if (rootEl) {
    const root = ReactDOMClient.createRoot(rootEl)
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    )
  }
} else {
  ReactDOMClient.hydrateRoot(
    rootEl,
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  )
}

reportWebVitals()
