// assets
import engineeringCover from 'assets/images/caseCovers/engineering.webp'
import vfCover from 'assets/images/caseCovers/vf.webp'

// assets - iot cases
import ambienceCover from 'assets/images/herosection/ambience-cover.webp'

// assets - animations
// @ts-ignore
import customSwAnimation from 'assets/animations/custom_software.mp4'

// assets - icons
import { App } from 'assets/images/icons/App'
import { Clients } from 'assets/images/icons/Clients'
import { CRM } from 'assets/images/icons/CRM'
import { CSD } from 'assets/images/icons/Custom software'
import { DataCollection } from 'assets/images/icons/DataCollection'
import { ECM } from 'assets/images/icons/E-commerce'
import { HCM } from 'assets/images/icons/Healthcare'
import { Integration } from 'assets/images/icons/Integration'
import { IoT } from 'assets/images/icons/IoT'
import { LS } from 'assets/images/icons/Logistic'
import { Process } from 'assets/images/icons/Process'
import { SmartApps } from 'assets/images/icons/Smart Apps'
import { HWDesign } from 'assets/images/icons/HWDesign'
import { HMI } from 'assets/images/icons/HMI'
import { VF } from 'assets/images/icons/VerticalFarming'
import { OptimizationsInovations } from 'assets/images/icons/OptimizationsInovations'
import { DesignPlanning } from 'assets/images/icons/DesignPlanning'
import { BuildingInstallation } from 'assets/images/icons/BuildingInstallation'
import { EnviromentControl } from 'assets/images/icons/EnviromentControl'
import { MonitoringAutomation } from 'assets/images/icons/MonitoringAutomation'
import { ContinuousSupport } from 'assets/images/icons/ContinuousSupport'
import { LargeFarmers } from 'assets/images/icons/LargeFarmers'
import { Pharmacy } from 'assets/images/icons/Pharmacy'
import { ResearchStartups } from 'assets/images/icons/ResearchStartups'
import { Sustainability } from 'assets/images/icons/Sustainability'
import { Consistency } from 'assets/images/icons/Consistency'
import { Predictability } from 'assets/images/icons/Predictability'
import { HighQuality } from 'assets/images/icons/HighQuality'
import { LocalSupply } from 'assets/images/icons/LocalSupply'
import { Effectivity } from 'assets/images/icons/Effectivity'

// assets - icons - IOT
import { CM } from 'assets/images/icons/IOT/CM'
import { CS } from 'assets/images/icons/IOT/CS'
import { HM } from 'assets/images/icons/IOT/HM'
import { MM } from 'assets/images/icons/IOT/MM'
import { SC } from 'assets/images/icons/IOT/SC'
import { SM } from 'assets/images/icons/IOT/SM'

// components
import { WorkflowChart } from 'components/WorkflowChart/WorkflowChart'

// content
import { IHerosectionItem, generateHerosection } from 'content/Herosection/Herosection'

// translate
import { translate } from '../../i18n'

// ref logos
import aaaLogo from 'assets/images/logos/aaa.svg'
import arboniaLogo from 'assets/images/logos/arbonia.svg'
import BBSKLogo from 'assets/images/logos/BBSK.svg'
import bekoLogo from 'assets/images/logos/beko.svg'
import bskLogo from 'assets/images/logos/bsk.svg'
import csobLogo from 'assets/images/logos/csob.svg'
import domsnovLogo from 'assets/images/logos/dom snov.svg'
import elicaLogo from 'assets/images/logos/elica.svg'
import fusionfnLogo from 'assets/images/logos/fusion-fn.svg'
import merchyouLogo from 'assets/images/logos/merchyou.svg'
import nudchLogo from 'assets/images/logos/nudch.svg'
import o2Logo from 'assets/images/logos/o2.svg'
import oktagonLogo from 'assets/images/logos/oktagon.svg'
import pfnLogo from 'assets/images/logos/pfn.svg'
import pharosLogo from 'assets/images/logos/pharos.svg'
import ravaltradeLogo from 'assets/images/logos/raval trade.svg'
import samsungLogo from 'assets/images/logos/samsung.svg'
import shanshiLogo from 'assets/images/logos/shanshi.svg'
import slovakibdLogo from 'assets/images/logos/slovak ibd.svg'
import slovaktualLogo from 'assets/images/logos/slovaktual.svg'
import starterLogo from 'assets/images/logos/starter.svg'
import tasr_webreportLogo from 'assets/images/logos/tasr_webreport.svg'
import telekomLogo from 'assets/images/logos/telekom.svg'
import velesfarmingLogo from 'assets/images/logos/velesfarming.svg'
import webswingLogo from 'assets/images/logos/webswing.svg'
import xfnLogo from 'assets/images/logos/xfn.svg'
import yitLogo from 'assets/images/logos/yit.svg'

import automation from 'assets/images/verticalFarming/automation.webp'
import building from 'assets/images/verticalFarming/building.webp'
import cont_support from 'assets/images/verticalFarming/cont_support.webp'
import design_planning from 'assets/images/verticalFarming/design_planning.webp'
import env_control from 'assets/images/verticalFarming/env_control.webp'
import optimizations from 'assets/images/verticalFarming/optimizations.webp'

export interface ICaseReference {
  key: string
  logo: string
}

export interface ICaseArticle {
  text: string
  title: string
}

interface IBannerText {
  href?: string
  title: string
  items: string[]
  url?: string
}

export interface ICase {
  animation?: string
  articles?: ICaseArticle[]
  bannerButton?: string
  bannerTexts?: IBannerText[]
  bannerTextsIcons?: ((props: { className: string }) => JSX.Element)[]
  bannerTextsTitle?: string
  btnPhrase?: string
  detailImg?: string
  detailTexts?:
    | string[]
    | {
        btnText?: string
        btnType?: string
        text?: string
        style?: string
        title?: string
        hasBtn?: boolean
        hasScrollbar?: boolean
        picture?: string
        logo?: string
        component?: (props: { id: string }) => JSX.Element
      }[]

  externalUrl?: string
  faq?: { title: string; rows: { title: string; content: string }[] }[]
  formTitle: string
  herosection?: IHerosectionItem[]
  hideTestimonials?: boolean
  icon?: any
  img?: string | ((props: { className: string }) => JSX.Element)
  key?: string
  logoTexts?: string[]
  metatitle?: string
  references?: ICaseReference[]
  seoDescription?: string
  seoKeywords?: string[]
  seoSiteName?: string
  services?: ICase[]
  shortText: string
  shortTitle?: string
  subtitle: string
  text: string
  title: string
  url?: string
  whoIsItFor?: IBannerText[]
  whoIsItForIcons?: ((props: { className: string }) => JSX.Element)[]
  whyShouldYouDoIt?: IBannerText[]
  whyShouldYouDoItIcons?: ((props: { className: string }) => JSX.Element)[]
}

export const CASES: ICase[] = [
  {
    ...translate.i18n.CASES.verticalFarming,
    bannerTextsIcons: [
      DesignPlanning,
      BuildingInstallation,
      EnviromentControl,
      MonitoringAutomation,
      OptimizationsInovations,
      ContinuousSupport,
    ],
    herosection: generateHerosection('verticalfarming'),
    icon: VF,
    img: vfCover,
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.CASES.verticalFarming,
    seoSiteName: translate.i18n.SEO_SITENAMES.CASES.verticalFarming,
    services: [
      // TODO:
      {
        ...translate.i18n.VERTICAL_FARMING_SERVICES.design,
        img: design_planning,
      },
      {
        ...translate.i18n.VERTICAL_FARMING_SERVICES.building,
        img: building,
      },
      {
        ...translate.i18n.VERTICAL_FARMING_SERVICES.environmentControl,
        img: env_control,
      },
      {
        ...translate.i18n.VERTICAL_FARMING_SERVICES.monitoring,
        img: automation,
      },
      {
        ...translate.i18n.VERTICAL_FARMING_SERVICES.innovations,
        img: optimizations,
      },
      {
        ...translate.i18n.VERTICAL_FARMING_SERVICES.support,
        img: cont_support,
      },
    ],
    whoIsItForIcons: [LargeFarmers, Pharmacy, ResearchStartups],
    whyShouldYouDoItIcons: [
      Sustainability,
      Consistency,
      Predictability,
      HighQuality,
      LocalSupply,
      Effectivity,
    ],
  },
  {
    ...translate.i18n.CASES.ambience,
    bannerTextsIcons: [CM, SC, MM, HM, CS, SM],
    externalUrl: 'https://ambience.ambitas.org',
    herosection: generateHerosection('ambience'),
    icon: SmartApps,
    img: ambienceCover,
    references: [],
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.CASES.ambience,
    seoSiteName: translate.i18n.SEO_SITENAMES.CASES.ambience,
  },
  {
    ...translate.i18n.CASES.engineering,
    bannerButton: translate.i18n.OUR_PROCESS,
    bannerTextsIcons: [HWDesign, Integration, Process, LS, HMI, App, HCM, Clients, CRM],
    detailTexts: [{ component: WorkflowChart, title: translate.i18n.OUR_PROCESS }],
    herosection: generateHerosection('engineering'),
    icon: IoT,
    img: engineeringCover,
    references: [],
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.CASES.engineering,
    seoKeywords: translate.i18n.SEO_KEYWORDS.CASES.engineering,
    seoSiteName: translate.i18n.SEO_SITENAMES.CASES.engineering,
  },
  // CSD
  {
    ...translate.i18n.CASES.customSoftwareSolutions,
    animation: customSwAnimation,
    bannerButton: translate.i18n.OUR_PROCESS,
    bannerTextsIcons: [Process, Integration, ECM, DataCollection, HCM, LS, Clients, CRM, App],
    herosection: generateHerosection('software'),
    icon: CSD,
    references: [
      { key: 'aaa', logo: aaaLogo },
      { key: 'arbonia', logo: arboniaLogo },
      { key: 'BBSK', logo: BBSKLogo },
      { key: 'beko', logo: bekoLogo },
      { key: 'bsk', logo: bskLogo },
      { key: 'csob', logo: csobLogo },
      { key: 'dom snov', logo: domsnovLogo },
      { key: 'elica', logo: elicaLogo },
      { key: 'fusion-fn', logo: fusionfnLogo },
      { key: 'merchyou', logo: merchyouLogo },
      { key: 'nudch', logo: nudchLogo },
      { key: 'o2', logo: o2Logo },
      { key: 'oktagon', logo: oktagonLogo },
      { key: 'pfn', logo: pfnLogo },
      { key: 'pharos', logo: pharosLogo },
      { key: 'raval trade', logo: ravaltradeLogo },
      { key: 'samsung', logo: samsungLogo },
      { key: 'shanshi', logo: shanshiLogo },
      { key: 'slovak ibd', logo: slovakibdLogo },
      { key: 'slovaktual', logo: slovaktualLogo },
      { key: 'starter', logo: starterLogo },
      { key: 'tasr_webreport', logo: tasr_webreportLogo },
      { key: 'telekom', logo: telekomLogo },
      { key: 'velesfarming', logo: velesfarmingLogo },
      { key: 'webswing', logo: webswingLogo },
      { key: 'xfn', logo: xfnLogo },
      { key: 'yit', logo: yitLogo },
    ],
    seoDescription: translate.i18n.SEO_DESCRIPTIONS.CASES.customSoftwareSolutions,
    seoKeywords: translate.i18n.SEO_KEYWORDS.CASES.customSoftwareSolutions,
    seoSiteName: translate.i18n.SEO_SITENAMES.CASES.customSoftwareSolutions,
  },
]
