// .core
import React, { useEffect, useRef, useState } from 'react'
// assets
import logo from 'assets/images/footer/Logo.svg'
// components
import Button from 'components/Button/Button'
import { generateRoutes } from 'routes/Routes'
// libraries
import cx from 'classnames'
import { Link, NavLink } from 'react-router-dom'
import { useBreakpoint } from 'use-breakpoint'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './Navbar.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { BREAKPOINTS } from 'utils/constants'
import { getPath, goTo, scrollToFooter } from 'utils/functions'

interface INavbarProps {
  blockContact?: boolean
  pageIsScrolled?: boolean
  className?: string
  onSetCirclePosition: (position: React.CSSProperties) => void
  /**
   * @param progress 0 -> not active; 1 -> closing; 2 -> active
   */
  onToggle(progress: number): void
}

const ScrollIndicator = ({ hidden }: { hidden?: boolean }) => {
  const [scrollPercentage, setScrollPercentage] = useState<number>(0)

  const scrollIndicatorStyles = {
    width: `${hidden ? 0 : scrollPercentage}%`,
  }

  useEffect(() => {
    const handleScroll = () =>
      setScrollPercentage(
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100
      )

    document.addEventListener('scroll', handleScroll)

    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <span className={css.scrollIndicator}>
      <hr style={scrollIndicatorStyles} />
      <hr />
    </span>
  )
}

export const Navbar = ({
  blockContact,
  className,
  pageIsScrolled,
  onSetCirclePosition,
  onToggle,
}: INavbarProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [shouldAddClosingClass, setShouldAddClosingClass] = useState<boolean>(false)
  const [loadedMenuItemsCount, setLoadedMenuItemsCount] = useState<number>(0)

  const toggleTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const onToggleIsActive = () => {
    toggleTimeoutRef.current = setTimeout(
      () => {
        const wasActive = isActive

        onToggle(wasActive ? 0 : 2)
        setIsActive(!wasActive)
        setShouldAddClosingClass(false)
      },
      isActive ? loadedMenuItemsCount * 75 : 0
    )

    if (shouldAddClosingClass) {
      setShouldAddClosingClass(false)
      if (toggleTimeoutRef.current) clearTimeout(toggleTimeoutRef.current)
    }

    if (isActive) {
      setShouldAddClosingClass(true)
    } else {
      setLoadedMenuItemsCount(0)
    }
  }

  const onGoToContactForm = () => {
    if (isActive) onToggleIsActive()

    setTimeout(() => {
      scrollToFooter(!isActive)
    }, loadedMenuItemsCount * (isActive ? 75 : 0) + (isActive ? 1 : 0))
  }

  const contactTranslation =
    window.innerWidth < 376 ? translate.i18n.CONTACT : translate.i18n.NAVBAR_TITLE_ICON

  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  useEffect(() => {
    if (isActive) onToggleIsActive()
  }, [breakpoint])

  return (
    <>
      <nav className={cx(css.navbar, className, pageIsScrolled && css.hasBg)}>
        <ScrollIndicator />
        <main className={css.main}>
          <div className="position-relative h-50">
            <img
              alt={`${translate.i18n.COMPANY_NAME}'s company logo`}
              className={css.logo}
              src={logo}
              onClick={() => {
                goTo(getPath(translate.i18n.ROUTES.home))
              }}
            />
            <a
              href={getPath(translate.i18n.ROUTES.home)}
              style={{
                position: 'absolute',
                opacity: 0,
                visibility: 'hidden',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            >
              {translate.i18n.COMPANY_NAME}
            </a>
          </div>
          <div className={cx(css.burgerWrapper, 'd-flex d-lg-none')}>
            {!blockContact && !pageIsScrolled ? (
              <span className={css.iconTitle} onClick={onGoToContactForm}>
                {contactTranslation}
              </span>
            ) : !blockContact ? (
              <Button
                text={contactTranslation}
                className={css.contactButton}
                onClick={onGoToContactForm}
              />
            ) : null}
            <div
              className={cx(css.burger, isActive && !shouldAddClosingClass && css.active)}
              onClick={onToggleIsActive}
            >
              <span className={css.bar} />
              <span className={css.bar} />
              <span className={css.bar} />
            </div>
          </div>
          <ul
            className={cx(
              css.links,
              shouldAddClosingClass && css.onBeforeClose,
              'd-none d-lg-flex'
            )}
          >
            {generateRoutes()
              .filter(
                (route) =>
                  route.name && !(route.contactRoute && blockContact) && !route.hideFromMenu
              )
              .map((route) => (
                <li key={route.name} className={cx(css.link)}>
                  {route.component && route.path ? (
                    <NavLink
                      exact
                      activeClassName={css.active}
                      target={route.external ? '_blank' : undefined}
                      to={route.path}
                    >
                      {route.name}
                    </NavLink>
                  ) : route.path ? (
                    <a
                      href={route.path}
                      rel={route.external ? 'noreferrer' : undefined}
                      target={route.external ? '_blank' : '_self'}
                    >
                      {route.name}
                    </a>
                  ) : route.onClick ? (
                    <button
                      onClick={() => {
                        route?.onClick?.()
                      }}
                    >
                      {route.name}
                    </button>
                  ) : null}
                </li>
              ))}
          </ul>
        </main>
      </nav>

      {breakpoint !== 'desktop' ? (
        <div className={cx(css.menu, (isActive || shouldAddClosingClass) && css.active)}>
          {!!(isActive || shouldAddClosingClass) ? (
            <>
              <ul className={cx(css.links, shouldAddClosingClass && css.onBeforeClose)}>
                {generateRoutes()
                  .filter(
                    (route) =>
                      route.name && !(route.contactRoute && blockContact) && !route.hideFromMenu
                  )
                  .map((route, routeIndex) => (
                    <li
                      key={route.name}
                      className={cx(css.link, loadedMenuItemsCount > routeIndex ? css.loaded : '')}
                      onAnimationEnd={(_) => setLoadedMenuItemsCount(routeIndex + 1)}
                      style={{
                        animationDelay: shouldAddClosingClass
                          ? `${(loadedMenuItemsCount - 1 - routeIndex) * 75}ms`
                          : `${(1 + routeIndex) * 75}ms`,
                      }}
                      onMouseEnter={(e) => {
                        // set the circle position
                        if (!shouldAddClosingClass) {
                          const TOP =
                            (e.currentTarget as HTMLLIElement).offsetTop +
                            ((e.currentTarget as HTMLLIElement).offsetParent as HTMLUListElement)
                              .offsetTop +
                            (window.innerWidth < 991.98 ? 30 : 60) +
                            (e.currentTarget as HTMLLIElement).clientHeight / 2

                          let left: number | string = 'unset'

                          if (window.innerWidth < 991.98) {
                            left = 'calc(50% - 55px)'
                          } else {
                            left =
                              (e.currentTarget as HTMLLIElement).offsetLeft *
                                +!!(
                                  (
                                    (
                                      (e.currentTarget as HTMLLIElement)
                                        .lastChild as HTMLAnchorElement
                                    ).offsetParent as HTMLElement
                                  ).tagName.toLowerCase() === 'li'
                                ) +
                              ((e.currentTarget as HTMLLIElement).lastChild as HTMLAnchorElement)
                                .offsetLeft -
                              50
                          }

                          onSetCirclePosition(
                            window.innerWidth >= 575.9
                              ? {
                                  top: TOP + (window.innerWidth < 991.98 ? -5 : -7.5),
                                  left,
                                }
                              : {}
                          )
                        }
                      }}
                      onMouseLeave={() => {
                        onSetCirclePosition({})
                      }}
                    >
                      {route.component && route.path ? (
                        <Link
                          target={route.external ? '_blank' : undefined}
                          to={route.path}
                          onClick={onToggleIsActive}
                        >
                          {route.name}
                        </Link>
                      ) : route.path ? (
                        <a
                          href={route.path}
                          rel={route.external ? 'noreferrer' : undefined}
                          target={route.external ? '_blank' : '_self'}
                        >
                          {route.name}
                        </a>
                      ) : route.onClick ? (
                        <button
                          onClick={() => {
                            route?.onClick?.(() => {
                              onToggleIsActive()
                            })
                          }}
                        >
                          {route.name}
                        </button>
                      ) : null}
                    </li>
                  ))}
              </ul>

              <span
                className={cx(
                  css.takeMeHome,
                  'd-flex flex-column justify-content-between align-center'
                )}
              >
                <Link to={translate.i18n.GDPR.URL}>{translate.i18n.GDPR.MENU_TITLE}</Link>
              </span>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  )
}
