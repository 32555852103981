import { useCallback, useState } from 'react'

export const useAnimateHandler = () => {
  const [shouldAnimate, setShouldAnimate] = useState<boolean>()

  const setShouldAnimateTrue = useCallback(() => {
    setShouldAnimate(true)
  }, [])

  const setShouldAnimateFalse = useCallback(() => {
    setShouldAnimate(false)
  }, [])

  return { shouldAnimate, setShouldAnimateTrue, setShouldAnimateFalse }
}
