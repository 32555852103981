// .core
import React, { useEffect, useState } from 'react'
// assets
import ErrorImg from '../../assets/images/error.svg'
import SuccessImg from '../../assets/images/success.svg'
// components
import { Link } from 'components/Link/Link'
import { Layout } from 'layouts/Layout'
import { BlogItem, BlogItemPlaceholder, IBlog, IResponse as IBlogResponse } from 'pages/Blog/Blog'
// libraries
import Axios, { AxiosResponse } from 'axios'
import cx from 'classnames'
// styles
import css from './FormSuccess.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { getPath, goTo } from 'utils/functions'

export default function FormSuccess() {
  const [response, setResponse] = useState<any>(true)
  const [blogs, setBlogs] = useState<IBlog[]>([])
  const [isLoading, setIsLoading] = useState<number>()

  useEffect(() => {
    const ls = localStorage.getItem('apiResponse')

    if (ls) {
      const RESPONSE = JSON.parse(ls)
      setResponse(RESPONSE as any)
    } else goTo(getPath(translate.i18n.ROUTES.home))

    Axios.get<any, AxiosResponse<IBlogResponse>>(`https://www.ambitas.sk/sk/api/feed/json/0`)
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          setBlogs(res.data.data.slice(0, 2))
        } else throw new Error()
      })
      .finally(() => setIsLoading(0))
  }, [])

  return (
    <Layout blockFooter hideBackdrop>
      <div className={cx(css.formSuccess, response === true ? 'd-none' : undefined)}>
        {response ? <img alt="" src={SuccessImg} /> : <img alt="" src={ErrorImg} />}
        <h1 className={css.title}>
          {response ? response.message : translate.i18n.CONTACT_FORM.TITLE}
        </h1>
        {response ? (
          <>
            <p className={css.text}>{translate.i18n.FORM_SUCCESS_MESSAGE}</p>

            <div className={cx(css.divider, 'mt-4')} />

            <main className="container d-flex flex-column flex-md-row mt-5">
              {isLoading ? (
                <>
                  <BlogItemPlaceholder />
                  <BlogItemPlaceholder />
                </>
              ) : (
                blogs.map((blog) => <BlogItem key={blog.url} blog={blog} />)
              )}
            </main>
            <Link className="my-4 cursor-pointer" onClick={() => goTo(translate.i18n.ROUTES.blog)}>
              {translate.i18n.SEE_MORE_BLOG_POSTS}
            </Link>
          </>
        ) : null}
      </div>
    </Layout>
  )
}
