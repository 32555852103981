import React from 'react'

export const LS = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g id="Icon-/-Outline-/-Logistic" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20.1965,6.0379257 L32.3965,13.0810387 L32.3965,27.1683308 L20.1965,34.2125047 L8.34648134,27.370407 L9.04651866,26.157993 L19.4964813,32.1909257 L19.4965,19.7212692 L30.2954813,13.4849257 L20.196,7.654 L9.04647912,14.0914298 L8.34648134,12.8789702 L20.1965,6.0379257 Z M30.9954813,14.6979257 L20.896,20.529 L20.8964813,32.1899257 L30.996,26.359 L30.9954813,14.6979257 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <path
        d="M15.0544,22.7278 L15.0544,24.1278 L3.8254,24.1278 L3.8254,22.7278 L15.0544,22.7278 Z M12.5771,19.4251 L12.5771,20.8251 L2.3391,20.8251 L2.3391,19.4251 L12.5771,19.4251 Z M11.5044,16.1223 L11.5044,17.5223 L3.8254,17.5223 L3.8254,16.1223 L11.5044,16.1223 Z"
        id="Combined-Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
