import React from 'react'

export const Integration = (props: { className: string }) => (
  <svg className={props.className || ''} width="40px" height="40px" viewBox="0 0 40 40">
    <g
      id="Icon-/-Outline-/-Integration"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M24.8298,7.4252 L7.4248,7.4252 L7.4248,24.8302 L15.419,24.83 L15.4198,32.8252 L32.8248,32.8252 L32.8248,15.4202 L24.829,15.42 L24.8298,7.4252 Z M23.429,8.825 L23.4298,16.8202 L31.424,16.82 L31.424,31.425 L16.819,31.425 L16.8198,23.4302 L8.824,23.43 L8.824,8.825 L23.429,8.825 Z"
        id="Shape"
        fill="#fff"
      ></path>
      <path
        d="M24.8298,23.13 L24.8298,24.83 L23.1298,24.83 L23.1298,23.43 L23.429,23.43 L23.4298,23.13 L24.8298,23.13 Z M22.2714,23.43 L22.2714,24.83 L20.5544,24.83 L20.5544,23.43 L22.2714,23.43 Z M19.5544,23.43 L19.5544,24.83 L17.8374,24.83 L17.8374,23.43 L19.5544,23.43 Z M16.82,23.13 L16.82,23.43 L17.12,23.43 L17.12,24.83 L15.42,24.83 L15.42,23.13 L16.82,23.13 Z M24.8298,20.6956 L24.8298,22.4126 L23.4298,22.4126 L23.4298,20.6956 L24.8298,20.6956 Z M16.82,20.5541 L16.82,22.2711 L15.42,22.2711 L15.42,20.5541 L16.82,20.5541 Z M24.8298,17.9786 L24.8298,19.6956 L23.4298,19.6956 L23.4298,17.9786 L24.8298,17.9786 Z M16.82,17.8371 L16.82,19.5541 L15.42,19.5541 L15.42,17.8371 L16.82,17.8371 Z M17.12,15.4202 L17.12,16.8202 L16.82,16.82 L16.82,17.1202 L15.42,17.1202 L15.42,15.4202 L17.12,15.4202 Z M24.8298,15.4202 L24.8298,17.1202 L23.4298,17.1202 L23.429,16.82 L23.1298,16.8202 L23.1298,15.4202 L24.8298,15.4202 Z M19.6954,15.4202 L19.6954,16.8202 L17.9784,16.8202 L17.9784,15.4202 L19.6954,15.4202 Z M22.4124,15.4202 L22.4124,16.8202 L20.6954,16.8202 L20.6954,15.4202 L22.4124,15.4202 Z"
        id="Combined-Shape"
        fill="#288BA0"
      ></path>
    </g>
  </svg>
)
