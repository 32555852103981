import React from 'react'

export const CSD = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Custom-software"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M11.7479253,15.1072253 L12.7378747,16.0971747 L8.614,20.22 L12.5468747,24.1522253 L11.5569253,25.1421747 L6.63495051,20.2202 L11.7479253,15.1072253 Z M28.5020747,15.1072253 L33.6150495,20.2202 L28.6930747,25.1421747 L27.7031253,24.1522253 L31.635,20.22 L27.5121253,16.0971747 L28.5020747,15.1072253 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
      <polygon
        id="Shape"
        fill="#288BA0"
        points="23.5223193 10.1289143 24.8150807 10.6662857 16.7280807 30.1212857 15.4353193 29.5839143"
      ></polygon>
    </g>
  </svg>
)
