// .core
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
// assets
import Drop from 'assets/images/footer/Drop.svg'
import Logo from 'assets/images/footer/Logo.svg'
import avfLogo from 'assets/images/logos/avf-logo.webp'
import certifications from 'assets/images/logos/certifications.webp'
import deloitte from 'assets/images/logos/deloitte.webp'
import iso9001 from 'assets/images/logos/iso 9001.svg'
import iso27001 from 'assets/images/logos/iso 27001.svg'
import iso200001 from 'assets/images/logos/iso 20000-1.svg'
// components
import { Icon } from 'components/Icon/Icon'
import { ContactForm } from 'components/ContactForm/ContactForm'
// content
import { SocialMedia } from 'content/SocialMedia/SocialMedia'
// libraries
import cx from 'classnames'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './Footer.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { FOOTER_ID } from 'utils/constants'
import { scrollToFooter } from 'utils/functions'

export interface IFooterProps {
  animateIn?: boolean
  animateOut?: boolean
  className?: string
  visible?: boolean
}

export const Footer = ({ className, animateIn, animateOut, visible }: IFooterProps) => {
  const loaderRef = useRef<HTMLDivElement>(null)
  const endRenderRef = useRef<HTMLDivElement>(null)

  const [loaderHeight, setLoaderHeight] = useState<string>('0%')

  const handleScrollToFooter = useCallback(() => scrollToFooter(true), [])

  const endRenderStyles = useMemo<React.CSSProperties>(
    () => ({
      opacity: +(loaderHeight.length >= `100%`.length),
      transform: `scale(${+(loaderHeight.length >= `100%`.length)})`,
    }),
    [loaderHeight]
  )

  const loaderStyles = useMemo<React.CSSProperties>(
    () => ({ height: loaderHeight }),
    [loaderHeight]
  )

  const rootStyles = useMemo<React.CSSProperties>(() => ({ opacity: visible ? 1 : 0 }), [visible])

  const updateLoaderHeight = () => {
    const GRAY_PREFIX_HEIGHT = 50

    if (
      loaderRef.current &&
      endRenderRef.current &&
      window.scrollY + window.innerHeight - loaderRef.current.offsetTop > 0
    ) {
      const SCROLL_HEIGHT =
        document.body.scrollHeight -
        loaderRef.current.offsetTop -
        GRAY_PREFIX_HEIGHT -
        (loaderRef.current.offsetParent
          ? (loaderRef.current.offsetParent as HTMLElement).offsetTop
          : 0)
      const SCROLL_POSITION =
        window.scrollY +
        window.innerHeight -
        loaderRef.current.offsetTop -
        GRAY_PREFIX_HEIGHT -
        (loaderRef.current.offsetParent
          ? (loaderRef.current.offsetParent as HTMLElement).offsetTop
          : 0)

      const NEW_LOADER_HEIGHT = `${Math.ceil((SCROLL_POSITION / SCROLL_HEIGHT) * 100)}%`

      if (loaderHeight !== NEW_LOADER_HEIGHT) setLoaderHeight(NEW_LOADER_HEIGHT)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', updateLoaderHeight)
    window.addEventListener('resize', updateLoaderHeight)

    return () => {
      document.removeEventListener('scroll', updateLoaderHeight)
      window.removeEventListener('resize', updateLoaderHeight)
    }
  }, [])

  return (
    <footer
      className={cx(
        css.footer,
        animateIn ? css.animateIn : animateOut ? css.animateOut : undefined,
        className
      )}
      id={FOOTER_ID}
      style={rootStyles}
    >
      <div></div>
      <h2 className={css.title}>{translate.i18n.CONTACT_US}</h2>
      <ContactForm />
      <section className={css.avf}>
        <a rel="noreferrer" target="_blank" href="https://vertical-farming.net/">
          <img alt="" src={avfLogo} />
        </a>
      </section>
      <section className={css.certificationSection}>
        <img alt="ISO 9001 Certification" src={iso9001} />
        <img alt="ISO 27001 Certification" src={iso27001} />
        <img alt="ISO 20000-1 Certification" src={iso200001} />
      </section>
      <section className={css.awards}>
        <img alt="" src={certifications} />
      </section>
      <section className={css.deloitte}>
        <img alt="" src={deloitte} />
      </section>
      <section className={css.socialMediaSection}>
        {/* social media icons */}
        {SocialMedia.map((sm) => (
          <a key={sm.name} aria-label={sm.link} href={sm.link} rel="noreferrer" target="_blank">
            <Icon className={css.icon} name={sm.iconName} />
          </a>
        ))}
      </section>
      <section className={css.loaderSection}>
        {/* "loader" bar */}
        <span className={css.title}>{translate.i18n.FINISH_SCROLL}</span>
        <div ref={loaderRef} className={css.loaderWrapper}>
          <div className={css.backDropWrapper}>
            <span className={css.loaderBar} />
            <img alt="" className={css.loaderImg} src={Drop} />
          </div>
          <div className={css.frontWrapper} style={loaderStyles}>
            <span className={css.loaderBar} />
            <img alt="" className={css.loaderImg} src={Drop} />
          </div>
        </div>
      </section>
      <section className={css.endRenderSection}>
        <div ref={endRenderRef} className={css.content} style={endRenderStyles}>
          <h2 className={css.title} onClick={handleScrollToFooter}>
            {translate.i18n.FOOTER_TITLE}
          </h2>
          <img src={Logo} alt={translate.i18n.COMPANY_NAME} className={css.logo} />
          <p className={css.copyright}>
            <a className={css.link} href={translate.i18n.GDPR.URL}>
              {translate.i18n.PRIVACY_POLICY}
            </a>
            <br />
            Bratislava, Slovakia, European union
            <br />
            {translate.i18n.COPYRIGHT}
          </p>
        </div>
      </section>
    </footer>
  )
}
