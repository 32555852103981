// .core
import React, { useCallback, useEffect, useRef, useState } from 'react'
// components
import Button from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { HerosectionImages } from './HerosectionImages'
import { NavigationArrows } from 'components/NavigationArrows/NavigationArrows'
// libraries
import cx from 'classnames'
import { Carousel } from 'react-responsive-carousel'
import { useLocation } from 'react-router-dom'
// @ts-ignore
import window from 'global/window'
// interfaces
import { generateHerosection, IHerosectionItem } from 'content/Herosection/Herosection'
// styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import css from './Herosection.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { getPath } from 'utils/functions'

export interface IHerosectionProps {
  animateIn?: boolean
  animateOut?: boolean
  items?: IHerosectionItem[]
}

export default function Herosection({
  animateIn,
  animateOut,
  items = generateHerosection(),
}: IHerosectionProps) {
  const location = useLocation<{ heroKey?: string }>()
  const herosectionItems = items || generateHerosection()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [currentSwipeIndex, setCurrentSwipeIndex] = useState<number>(0)

  const incIndex = useCallback(() => {
    setCurrentSwipeIndex((p) => (p < herosectionItems.length - 1 ? p + 1 : p))
  }, [herosectionItems.length])

  const decIndex = useCallback(() => {
    setCurrentSwipeIndex((p) => (p > 0 ? p - 1 : p))
  }, [])

  const scrollToHeroKey = (key: string) => {
    const allKeys: string[] = items
      .map((i) => (i.reference ? i.reference.key : undefined))
      .filter(Boolean) as string[]

    if (key && allKeys.includes(key) && wrapperRef.current) {
      window.scrollTo({
        top: wrapperRef.current.offsetTop - 150,
        behavior: 'smooth',
      })

      setTimeout(() => {
        setCurrentSwipeIndex(allKeys.indexOf(key))
      }, 150)
    }
  }

  useEffect(() => {
    if (location.state && location.state.heroKey) {
      const key: string = location.state.heroKey

      setTimeout(() => {
        history.replaceState({ ...location.state, heroKey: undefined }, '')

        setTimeout(() => {
          scrollToHeroKey(key)
        }, 0)
      }, 0)
    }
  }, [location.state])

  return (
    <>
      <div className={css.logos} id="heroSection" ref={wrapperRef}>
        <div className="d-flex w-100 justify-content-center position-relative mw-100 flex-column-reverse align-items">
          <Carousel
            emulateTouch
            preventMovementUntilSwipeScrollTolerance
            swipeable
            axis="horizontal"
            className={cx('w-100', css.swipeWrapper)}
            infiniteLoop={false}
            selectedItem={currentSwipeIndex}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeScrollTolerance={50}
            onChange={(index) => setCurrentSwipeIndex(index)}
          >
            {herosectionItems.map((project, index) => {
              const isThisItemVisible = index === currentSwipeIndex
              const animationScreen =
                animateIn && isThisItemVisible ? css.fadeIn : animateOut ? css.fadeOut : ''

              const projectUrl =
                !project.externalUrl && project.url && project.subUrl
                  ? `${getPath(translate.i18n.ROUTES.solutions)}/${project.subUrl}/${
                      translate.i18n.ROUTES.caseStudy
                    }/${project.url}`
                  : project.externalUrl || undefined

              return (
                <div key={projectUrl || project.title} className={cx(css.swipeCard, 'w-100')}>
                  <div className={cx('col-sm-12', css.logoWrapper)}>
                    <div className={cx(css.textSection, animationScreen)}>
                      <h2>
                        {projectUrl ? (
                          <a
                            href={projectUrl}
                            aria-hidden={!isThisItemVisible}
                            target={project.externalUrl && !project.url ? '_blank' : undefined}
                            style={{ visibility: isThisItemVisible ? 'visible' : 'hidden' }}
                          >
                            {project.title}
                          </a>
                        ) : (
                          project.title
                        )}
                      </h2>
                      {project.tags && project.tags.length > 0 && (
                        <div
                          className="d-flex align-items-center mb-3"
                          style={{ visibility: isThisItemVisible ? 'visible' : 'hidden' }}
                        >
                          <a
                            className={cx(css.tag, css.inverted, 'd-none d-lg-flex')}
                            aria-hidden={!isThisItemVisible}
                            href={projectUrl}
                          >
                            {translate.i18n.CASE_STUDY}
                          </a>

                          {
                            // @ts-ignore
                            project.tags.map((tag) =>
                              typeof tag === 'string' ? (
                                <div key={tag} className={css.tag}>
                                  {tag}
                                </div>
                              ) : (
                                <a
                                  key={tag.text}
                                  aria-hidden={!isThisItemVisible}
                                  className={css.tag}
                                  href={getPath(translate.i18n.ROUTES.solutions) + '/' + tag.link}
                                >
                                  {tag.text}
                                </a>
                              )
                            )
                          }
                        </div>
                      )}
                      <HerosectionImages
                        small
                        {...project}
                        index={index}
                        animateOut={animateOut}
                        animateIn={animateIn}
                        swipeIndex={currentSwipeIndex}
                      />
                      <p dangerouslySetInnerHTML={{ __html: project.text }} />
                      <div
                        className="d-flex align-items-center"
                        style={{ visibility: isThisItemVisible ? 'visible' : 'hidden' }}
                      >
                        {projectUrl && (
                          <Button
                            className="mx-0 my-2"
                            href={projectUrl}
                            to={
                              project.externalUrl
                                ? undefined
                                : {
                                    pathname: `${getPath(translate.i18n.ROUTES.solutions)}/${
                                      project.subUrl
                                    }/${translate.i18n.ROUTES.caseStudy}/${project.url}`,
                                    state: { pathName: location.pathname },
                                  }
                            }
                            target={projectUrl === project.externalUrl ? '_blank' : undefined}
                            text={translate.i18n.FIND_OUT_MORE}
                          />
                        )}

                        {project.logo ? (
                          <div
                            className={cx(css.companyLogo, 'd-none d-md-flex')}
                            style={{ width: 250 }}
                          >
                            <img alt="company logo" src={project.logo} />
                          </div>
                        ) : null}
                      </div>
                      {!!project.techDocsUrl && (
                        <div className="d-flex align-items-center mt-0 mb-3">
                          <a
                            className={cx(css.more, 'text-center')}
                            href={project.techDocsUrl}
                            style={{ width: 250 }}
                          >
                            {translate.i18n.TECHNICAL_DETAILS}{' '}
                            <Icon className="ml-1" name="magnifier" />
                          </a>
                        </div>
                      )}
                      {project.logo ? (
                        <div className={cx(css.companyLogo, 'd-flex d-md-none')}>
                          <img alt="company logo" src={project.logo} />
                        </div>
                      ) : null}
                    </div>

                    <HerosectionImages
                      {...project}
                      index={index}
                      animateOut={animateOut}
                      animateIn={animateIn}
                      swipeIndex={currentSwipeIndex}
                    />
                  </div>
                </div>
              )
            })}
          </Carousel>
          <div className={css.logoBackground}></div>
        </div>
      </div>

      {herosectionItems.length > 1 ? (
        <NavigationArrows
          currentPage={currentSwipeIndex}
          numberOfPages={herosectionItems.length}
          onNext={incIndex}
          onPrev={decIndex}
        />
      ) : null}
    </>
  )
}
