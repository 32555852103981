// .core
import React from 'react'

export const CS = (props: { className: string }) => (
  <svg
    className={props.className || ''}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <g
      id="Icon-/-Outline-/-Custom-services"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M28.4407495,6.4251 L25.483,9.382 L28.4642259,12.3641 L20.824,12.364 L20.8248,16.9091 L19.4248,16.9091 L19.4248,6.4251 L28.4407495,6.4251 Z M25.06,7.825 L20.824,7.825 L20.824,10.964 L25.086,10.964 L23.503959,9.38199154 L25.06,7.825 Z"
        id="Stroke-1"
        fill="#288BA0"
      ></path>
      <path
        d="M20.1248,15.7727057 L34.2407571,33.8251 L6.00884291,33.8251 L20.1248,15.7727057 Z M20.124,24.445 L17.2788981,27.2910514 L14.8178429,24.8307057 L8.88,32.425 L31.369,32.425 L25.5238429,24.9497057 L23.0778838,27.3970657 L20.124,24.445 Z M20.125,18.045 L15.6868429,23.7207057 L17.278,25.311 L20.1247162,22.4653343 L23.077,25.417 L24.6548429,23.8387057 L20.125,18.045 Z"
        id="Combined-Shape"
        fill="#fff"
      ></path>
    </g>
  </svg>
)
