// core
import React from 'react'
// libraries
import Helmet from 'react-helmet'
// translate
import { translate } from 'i18n'

interface IMetaProps {
  description?: string
  title?: string
}

export const Meta = ({ description = '', title: _title }: IMetaProps) => {
  const title = `${_title ? `${_title} - ` : ''}${translate.i18n.COMPANY_NAME}`

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="sitename" content={title} />
      <meta data-react-helmet="true" name="title" content={title} />
      <meta data-react-helmet="true" name="fb:title" content={title} />
      <meta data-react-helmet="true" property="og:title" content={title} />

      <meta data-react-helmet="true" name="description" content={description} />
      <meta data-react-helmet="true" name="og:description" content={description} />
      <meta data-react-helmet="true" name="fb:description" content={description} />
      <meta data-react-helmet="true" name="twitter:description" content={description} />

      <meta data-react-helmet="true" property="og:locale" content="en" />

      {/* TODO: */}
      {/* <meta data-react-helmet="true" property="og:url" content={window.location.href} />
      <link data-react-helmet="true" rel="canonical" href={window.location.href} /> */}
    </Helmet>
  )
}
