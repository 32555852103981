// .core
import React, { useState, useCallback } from 'react'
// components
import { Layout } from 'layouts/Layout'
import { AboutUsPageContent } from './AboutUsPage.content'

export default function AboutUsPage() {
  const [shouldAnimateFooter, setShouldAnimateFooter] = useState<boolean>(false)

  const setAnimateFooterFalse = useCallback(() => {
    setShouldAnimateFooter(false)
  }, [])

  const setAnimateFooterTrue = useCallback(() => {
    setShouldAnimateFooter(true)
  }, [])

  return (
    <Layout hideBackdrop footerAnimate={shouldAnimateFooter}>
      <AboutUsPageContent onLoadLast={setAnimateFooterTrue} onUnloadLast={setAnimateFooterFalse} />
    </Layout>
  )
}
