// .core
import React from 'react'
// assets
import background from '../../assets/images/aboutus-background.webp'
// styles
import css from './AboutUs.module.scss'
// translate
import { translate } from 'i18n'

export const Logo = () => {
  return (
    <>
      <div className={css.pageBackground}>
        <img alt="" src={background} />
        <div />
      </div>

      <div className={css.header}>
        <div className={css.logoCircle} />

        <h1 className={css.heading}>{translate.i18n.ABOUT_US_TITLE}</h1>
        <h2 className={css.subtitle}>{translate.i18n.ABOUT_US_SUBTITLE}</h2>
      </div>
    </>
  )
}
