// .core
import React, { useCallback, useEffect, useRef, useState } from 'react'
// components
import Button from 'components/Button/Button'
import { HerosectionImages } from 'components/Herosection/HerosectionImages'
import { Icon } from 'components/Icon/Icon'
import { Results } from './Partials/Results'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
// interfaces
import { IHerosectionItem } from 'content/Herosection/Herosection'
// libraries
import cx from 'classnames'
import { Link } from 'react-router-dom'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './CaseStudy.module.scss'
// translate
import { translate } from 'i18n'
// utils
import { useAnimateHandler } from 'utils/hooks'
import { getPath, scrollToFooter } from 'utils/functions'

export interface ICaseStudyDetailProps extends IHerosectionItem {
  onLoad: () => void
  onUnload: () => void
}

export const CaseStudyDetail = (props: ICaseStudyDetailProps) => {
  const { testimonial, title, subtitle, text, type, reference, video, onLoad, onUnload } = props
  const hasRefs = reference && reference.references && reference.references.length

  const resultsRef = useRef<HTMLElement>(null)
  const referencesRef = useRef<HTMLElement>(null)

  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [currentTimelineIndex, setCurrentTimelineIndex] = useState<number>(0)

  const {
    setShouldAnimateFalse: setShouldAnimateResultsFalse,
    setShouldAnimateTrue: setShouldAnimateResultsTrue,
    shouldAnimate: shouldAnimateResults,
  } = useAnimateHandler()

  const {
    setShouldAnimateFalse: setShouldAnimateTestimonialsFalse,
    setShouldAnimateTrue: setShouldAnimateTestimonialsTrue,
    shouldAnimate: shouldAnimateTestimonials,
  } = useAnimateHandler()

  const increaseTimelineIndex = useCallback(() => setCurrentTimelineIndex((p) => p + 1), [])
  const decreaseTimelineIndex = useCallback(() => setCurrentTimelineIndex((p) => p - 1), [])

  const handleScrollToFooter = useCallback(() => scrollToFooter(true), [])

  useEffect(() => {
    const updateIsScrolled = () => setIsScrolled(window.scrollY > 0)

    updateIsScrolled()
    document.addEventListener('scroll', updateIsScrolled)

    return () => document.removeEventListener('scroll', updateIsScrolled)
  }, [])

  return (
    <div className={css.root}>
      <section className="position-relative">
        <h2 className={css.title}>{title}</h2>
        <h4 className={css.subtitle}>{subtitle}</h4>
        <p className={cx(css.text, css.head)} dangerouslySetInnerHTML={{__html: text}} />

        <Link
          className={cx(css.backButton, isScrolled && css.useBackground)}
          to={(location) => {
            return {
              pathname:
                location.state && (location.state as any).pathName
                  ? (location.state as any).pathName
                  : getPath(translate.i18n.ROUTES.solutions),
              state: {
                heroKey:
                  location.state && (location.state as any).pathName && reference
                    ? reference.key
                    : undefined,
              },
            }
          }}
        >
          {translate.i18n.CLOSE}
          <Icon className={css.icon} name="close" />
        </Link>

        {video ? (
          <iframe
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="mw-100 mt-4 mb-4"
            frameBorder={0}
            height={315}
            width={560}
            src={video}
          ></iframe>
        ) : (
          <HerosectionImages {...props} detail />
        )}

        <div
          className={cx(
            'd-flex align-items-center justify-content-center',
            !video && type !== 'macbook' && css.discoverButton
          )}
        >
          <Button
            text={translate.i18n.DISCOVER_RESULTS}
            onClick={() => {
              if (resultsRef.current)
                window.scroll({ top: resultsRef.current.offsetTop - 200, behavior: 'smooth' })
            }}
          />
        </div>
        <ScrollBar onLoad={setShouldAnimateResultsTrue} onUnload={setShouldAnimateResultsFalse} />
      </section>
      <section ref={resultsRef}>
        <Results
          {...props}
          animateFadeIn={shouldAnimateResults === true}
          animateFadeOut={shouldAnimateResults === false}
          onClick={() => {
            if (referencesRef.current) referencesRef.current.scrollIntoView()
          }}
        />
      </section>
      <section ref={referencesRef}>
        {hasRefs ? <ScrollBar className={css.stepperScroll} /> : null}

        <div className={css.steps}>
          {reference &&
            reference.references &&
            reference.references.length &&
            reference.references.map((step, stepIndex) => {
              const IMGS = reference.imgs

              return (
                <div
                  key={`step_${stepIndex}`}
                  className={cx(css.step, stepIndex % 2 === 1 && css.reverse)}
                >
                  {/* DESKTOP IMGS */}
                  {IMGS ? (
                    <div className={cx(css.imgWrapper, 'position-static')}>
                      {IMGS[stepIndex] ? <img alt={step.title} src={IMGS[stepIndex]} /> : null}
                    </div>
                  ) : (
                    <div className={cx(css.imgWrapper, css.empty, 'position-static')} />
                  )}

                  {/* SCROLL INDICATOR */}
                  <ScrollBar
                    dotted
                    highFill
                    onLoadThreshold={15}
                    onUnloadThreshold={5}
                    onLoad={increaseTimelineIndex}
                    onUnload={decreaseTimelineIndex}
                    className={css.scrollBar}
                  />

                  {/* TEXT WRAPPER */}
                  <div
                    className={cx(
                      css.txtWrapper,
                      currentTimelineIndex > stepIndex ? css.animateIn : css.animateOut
                    )}
                  >
                    {/* TITLE */}
                    {step.title ? <h3 className={css.title}>{step.title}</h3> : null}

                    {/* SUBTITLE */}
                    {step.subtitle ? <h4 className={css.subtitle}>{step.subtitle}</h4> : null}

                    {/* TEXT PARAGRAPH(S) */}
                    {Array.isArray(step.text) ? (
                      step.text.map((paragraph: string, pIndex: number) => (
                        <p key={`stepPar_${pIndex}`} className={css.text} dangerouslySetInnerHTML={{__html: paragraph}} />
                      ))
                    ) : (
                      <p className={css.text} dangerouslySetInnerHTML={{__html: step.text || ''}} />
                    )}

                    {/* MOBILE IMGS */}
                    {IMGS && IMGS[stepIndex] ? (
                      <div className={cx(css.imgWrapper, 'position-static')}>
                        <img alt={step.title} src={IMGS[stepIndex]} />
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            })}
        </div>

        <ScrollBar
          highFill
          className={cx(hasRefs && css.postStepperScroll)}
          onLoadThreshold={hasRefs ? 25 : undefined}
          onUnloadThreshold={hasRefs ? 5 : undefined}
          onLoad={() => {
            if (testimonial) setShouldAnimateTestimonialsTrue()
            else if (onLoad) onLoad()
          }}
          onUnload={() => {
            if (testimonial) setShouldAnimateTestimonialsFalse()
            else if (onUnload) onUnload()
          }}
        />
      </section>

      {testimonial ? (
        <>
          <section
            className={cx(
              css.testimonialWrap,
              shouldAnimateTestimonials ? css.animateIn : css.animateOut
            )}
          >
            <h2 className={css.title}>{translate.i18n.TESTIMONIAL_TITLE}</h2>

            {testimonial.photo ? (
              <img
                alt={`${testimonial.title} - ${testimonial.subtitle}`}
                className={css.img}
                src={testimonial.photo}
              />
            ) : null}

            <p className={css.testimonialText}>{testimonial.text}</p>

            <div className={cx(css.testimonial, testimonial.photo && css.hasImg)}>
              <div className={css.testimonialContent}>
                {testimonial.title ? <h5 className={css.title}>{testimonial.title}</h5> : null}
                {testimonial.subtitle ? (
                  <h6 className={css.subtitle}>{testimonial.subtitle}</h6>
                ) : null}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-4">
              <Button text={translate.i18n.NAVBAR_TITLE_ICON} onClick={handleScrollToFooter} />
            </div>
          </section>

          <ScrollBar onLoad={onLoad} onUnload={onUnload} />
        </>
      ) : null}
    </div>
  )
}
